import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const SitemapPage = () => {
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            <Col lg={4} md={6}>
              <th className="fw-bold fs-18">
                Information
              </th>
              <ul className="list-unstyled">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/Grievances">Grievances</Link>
                </li>
                <li>
                  <Link to="/privacyandpolicy">Privacy and Policy</Link>
                </li>
                <li>
                  <Link to="/termsandconditions">Terms and Conditions</Link>
                </li>
                <li>
                  <Link to="/trustandsafety">Trust and Safety</Link>
                </li>
                <li>
                  <Link to="/faqs">FAQs</Link>
                </li>
                <li>
                  <Link to="/reportanissue">Report An Issue</Link>
                </li>
              </ul>
            </Col>
            <Col lg={4} md={6}>
              <th className="fw-bolder fs-18">
                Job Seekers
              </th>
              <ul className="list-unstyled">
                <li>
                  <Link to="/signup">SignUp/SignIn</Link>
                </li>
                <li>
                  <Link to="/joblist">Job List</Link>
                </li>
                <li>
                  <Link to="/jobgrid">Job Grid</Link>
                </li>
                <li>
                  <Link to="/jobscategories">Job Categories</Link>
                </li>
              </ul>
            </Col>
            <Col lg={4} md={6}>
            <th className="fw-bold fs-18">
                 Recruiters
              </th>
              <ul  className="list-unstyled">
                <li>
                  <Link to="/signup">SignUp/SignIn</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default SitemapPage;
