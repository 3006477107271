import React, { useState } from "react";
import Select from "react-select";

const JobType = ({ onJobTypeChange }) => {
  const options = [
    { label: "Internship", value: "Internship" },
    { label: "Part Time", value: "Part Time" },
    { label: "Full Time", value: "Full Time" },
    { label: "Freelancer", value: "Freelancer" }
  ];

  const [selectedJobType, setSelectedJobType] = useState(null);

  const handleJobTypeChange = (selectedOption) => {
    setSelectedJobType(selectedOption);
    if (onJobTypeChange) {
      onJobTypeChange(selectedOption ? selectedOption.value : '');
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 40px",
      margin: "-16px -6px 0 -52px",
      borderRadius: "0"
    })
  };

  return (
    <React.Fragment>
      <Select
        options={options}
        styles={colourStyles}
        className="selectForm__inner"
        defaultValue={[]}
        name="choices-single-categories"
        id="choices-single-categories"
        aria-label="Default select example"
        isClearable
        onChange={handleJobTypeChange}
      />
    </React.Fragment>
  );
};

export default JobType;