import React from "react";
import Section from "./Section"
import FraudAlertPage from "./FraudAlertPage";


const FraudAlert = () => {
  document.title = "Fraud Alert";
  return (
    <React.Fragment>
       <Section />
       <FraudAlertPage />
    </React.Fragment>
  );
};

export default FraudAlert;
