import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LockIcon from '@mui/icons-material/Lock';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import CloudDoneRoundedIcon from '@mui/icons-material/CloudDoneRounded';
import { Link, NavLink, useLocation } from 'react-router-dom';

const drawerWidth = 190;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 2,
    padding: theme.spacing(0),
    height: theme.spacing(10),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `0px`,
    marginLeft: `0px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('w')(({ theme }) => ({
  display: 'flex',
  padStart: 'alignItems',
  alignItems: 'center',
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  // justifyContent: 'flex-end',
}));

const SidebarLink = styled(NavLink)({
  textDecoration: 'none',
  color: 'inherit',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: 'green',
  },
  '&.active': {
    backgroundColor: '#c0c0c0',
    color: 'green',
  },
  '&:focus, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  fontSize: '16px',
});

const Sidebar = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const style = {
    height: 'fit-content',
    blockSize: '0px',
    marginRight: '-10px',
    display: 'flex',
    flexDirection: ' row',
    justifyContent: 'flex-start',
  }
  return (
    <>
      <Box sx={{ display: 'flex' }} style={style}>
        <AppBar position="relative" open={open}>
          <Toolbar>
            <IconButton
              // marginLeft='100px'
              color=""
              aria-label=""
              onClick={handleDrawerOpen}
              id="sidebar-toggle-icon"
              // edge="start"
              sx={{ mr: 0, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              top: '114px',
              width: drawerWidth,
              boxSizing: 'border-box',
              // height: 'fit-content'
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader >
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              {/* <h6 className='mt-2'>Back</h6> */}
              <Link>Back</Link>
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <SidebarLink to={'/candidatedashboard'}>
              {['Dashboard'].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <DashboardIcon /> : <AssessmentIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </SidebarLink>
          </List>
          <Divider />
          <List>
            <SidebarLink to={'/candidateprofile'}>
              {['Profile'].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <PersonIcon /> : <AccountCircleIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </SidebarLink>
          </List>
          <Divider />
          <List>
            <SidebarLink to={'/educationaldetails'}>
              {['Educational Details'].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <BusinessCenterIcon /> : <BusinessCenterIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </SidebarLink>
          </List>
          <Divider />
          <List>
            <SidebarLink to={'/appliedjobslist'}>
              {['Applied Jobs'].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <CloudDoneRoundedIcon /> : <CloudDoneRoundedIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </SidebarLink>
          </List>
          <Divider />
          <List>
            <SidebarLink to={'/signout'}>
              {['Logout'].map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {index % 2 === 0 ? <LockIcon /> : <LockIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </SidebarLink>
          </List>
          <Divider />

        </Drawer>
        <Main open={open}>
          <DrawerHeader />
        </Main>
      </Box>
    </>
  )
}
export default Sidebar;


