import React from 'react';
import { useLocation } from 'react-router-dom';
import UpdateEducationalDetails from './UpdateEducationalDetails';
import EducationalDetails from './EducationalDetails';

const Education = () => {
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const id = searchParams.get("id");

    if (id) {
        return <UpdateEducationalDetails id={id} />;
    } else {
        return <EducationalDetails />;
    }
};

export default Education;

