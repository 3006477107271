import React from "react";
import { Container } from "reactstrap";
import Selected from "../ManageJobs/Selected";
import BookmarkJobListing from "./BookmarkJobListing";
import Section from "./Section";
import Subscribe from "../../../Layout/CommonLayout/Subscribe";
import Footer from "../../../Layout/CommonLayout/Footer";

const BookMarkJobs = () => {
  document.title =
    "Bookmarks Jobs";
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Selected />
          <BookmarkJobListing />
        </Container>
      </section>
      {/* <Subscribe /> */}
      <Footer />
    </React.Fragment>
  );
};

export default BookMarkJobs;
