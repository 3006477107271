import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  Row,
  Spinner,
  Button,
} from "reactstrap";
import Pagination from "../../Jobs/JobList2/Pagination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-image-lightbox/style.css";
import { useSelector } from "react-redux";
import { useJob } from "../../../hooks/useJob";
import { baseURL } from "../../../repositories/Repository";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";
import { IoLocationOutline } from "react-icons/io5";
import { FaCalendar } from "react-icons/fa";
import { LuClock3 } from "react-icons/lu";
import { JobApply } from "../../../components/shared/JobApply";
import { toast } from "react-toastify";
import { useProfile } from "../../../hooks/useProfile";

const RightSideContent = () => {
  //Apply Now Model
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [profileStatus, setProfileStatus] = useState();
  const { showprofile, profile } = useProfile();
  const route = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 4;

  const { getalljobs, jobList } = useJob();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getalljobs().then(() => {
      setLoading(false); // Set loading to false when data is fetched
    });
    showprofile().then(() => {
      setLoading(false); // Set loading to false when data is fetched
    });
  }, []);

  useEffect(() => {
    if (profile && profile.role === 'student') {
      setProfileStatus(profile.role);
    } else if ((profile && profile.role === 'campany')) {
      setProfileStatus(profile.role);
    }
    else {
      setProfileStatus(profile);
    }
  }, [profile]);
  const location = useLocation();
  const Id = new URLSearchParams(location.search).get("companyId");

  // Calculate timestamp for one month ago
  const oneMonthAgoTimestamp = new Date();
  oneMonthAgoTimestamp.setMonth(oneMonthAgoTimestamp.getMonth() - 1);

  //reverse all joblist
  const reversedJobs = Array.isArray(jobList) ? jobList.slice().reverse() : [];
  const jobVacancyPostDetails = (reversedJobs || []).filter(job => job?.companyId?._id === Id && new Date(job.postedAt) > oneMonthAgoTimestamp);

  const companyDescriptions = jobVacancyPostDetails.length > 0 ? jobVacancyPostDetails[0].companyId?.basicInfo?.discription || 'N/A' : 'N/A';

  // Apply pagination
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobVacancyPostDetails.slice(indexOfFirstJob, indexOfLastJob);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // formating date
  const formatDate = (dateform) => {
    const date = new Date(dateform);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  //Format timestamp difference to human-readable time ago string
  const formatTimestampWithDifference = (timestamp) => {
    const date = new Date(timestamp);
    const currentTime = new Date();
    const timeDifference = currentTime - date;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // Return formatted time ago string based on the calculated difference
    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} min ago`;
    } else {
      return `${seconds} sec ago`;
    }
  };

  const openApplyModal = (jobId) => {
    if (isAuthenticated) {
      setSelectedJobId(jobId);
      setApplyModalOpen(true);
    } else {
      // Store the job details in session storage before redirecting
      sessionStorage.setItem('redirectToJobId', jobId);
      toast.warning('You need to login to apply for this job.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // Delayed redirection to the sign-in page after 2 seconds (adjust the time as needed)
      setTimeout(() => {
        route('/signin');
      }, 2000);
    }
  };

  const closeApplyModal = () => {
    setSelectedJobId(null); // Reset the selected job ID when the modal is closed
    setApplyModalOpen(false);
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="text-center mt-5">
          <Button
            color="primary"
            disabled
          >
            <Spinner size="sm">
              Loading...
            </Spinner>
            <span>
              {' '}Loading
            </span>
          </Button>
        </div>
      ) : (
        <Col lg={8}>
          <Card className="ms-lg-4 mt-4 mt-lg-0">
            <CardBody className="p-4">
              <div className="mb-5">
                <h6 className="fs-17 fw-semibold mb-4">About Company</h6>
                <p className="text-muted">
                  {companyDescriptions && (
                    <div dangerouslySetInnerHTML={{ __html: companyDescriptions }} />
                  )}
                </p>
              </div>
              {/* <div className="candidate-portfolio mb-5">
              <h6 className="fs-17 fw-semibold mb-4">Gallery</h6>
              <Row className="g-3">
                <Col lg={6}>
                  <Link to="#" className="image-popup">
                    <img
                      src={blogImage1}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(0);
                      }}
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </Col>
                <Col lg={6}>
                  <Link to="#" className="image-popup">
                    <img
                      src={blogImage3}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(1);
                      }}
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </Col>
                <Col lg={12}>
                  <Link to="#" className="image-popup">
                    <img
                      src={blogImage12}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(2);
                      }}
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </Col>
              </Row>
            </div> */}

              <div>
                <h6 className="fs-17 fw-semibold mb-4">Current Opening</h6>
                {currentJobs.length === 0 ? (
                  <div className="text-gray">
                    <p>No jobs available at the moment. Check back later!</p>
                  </div>
                ) : (
                  currentJobs.map((job) => (
                    <div
                      key={job._id}
                      className={
                        job.addclassNameBookmark === true
                          ? "candidate-list-box bookmark-post card mt-4 "
                          : "candidate-list-box card mt-4"
                      }
                    >
                      {/* <div className="bookmark-label text-center">
                        <Link to="#" className="align-middle text-white">
                          <i className="mdi mdi-star"></i>
                        </Link>
                      </div> */}
                      <div className="p-2 ">
                        <Row className="align-items-center">
                          <Col md={2}>
                            <div
                              id="jobVacancyCompanyLogo"
                              className="text-center mb-4 mb-md-0"
                            >
                              <Link to={`/companydetails?id=${job?._id}&companyId=${job?.companyId?._id}`}>
                                {job?.companyId?.logo ? (
                                  <div className="avatar-container">
                                    <img
                                      className="avatar-logo rounded-3"
                                      src={`${baseURL}/${job?.companyId?.logo?.file}`}
                                      alt="Company Logo"
                                    />
                                  </div>
                                ) : (
                                  <div className="avatar-container">
                                    <FontAwesomeIcon
                                      icon={faLandmark}
                                      className="avatar-logo"
                                      color='#373840'
                                      style={{ width: '80px', height: '80px' }}
                                    />
                                  </div>
                                )}
                              </Link>
                            </div>
                          </Col>

                          <Col md={3}>
                            <div className="mb-2 mb-md-0 text-center">
                              <h5 id="jobVacancyJob" className="fs-18 mb-0">
                                <Link
                                  to={`/jobdetails?id=${job?._id}`}
                                  className="text-dark"
                                >
                                  {job?.jobTitle &&
                                    job.jobTitle.split(' ')
                                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                      .join(' ')
                                  }
                                </Link>
                              </h5>
                              <p
                                id="jobVacancyCompanyName"
                                className="text-muted fs-14 mb-0"
                              >
                                {job?.companyId
                                  ? job.companyId.basicInfo?.companyName
                                  : "N/A"}
                              </p>
                            </div>
                          </Col>

                          <Col md={3}>
                            <div className="ml-2 md:ml-12 text-center">
                              <div className="">
                                {/* <i className="mdi mdi-map-marker text-primary inline-block align-middle"></i> */}
                              </div>
                              <p className="inline-block align-middle text-center">
                                <IoLocationOutline className="text-primary inline-block align-middle me-1 mb-1" />
                                {job?.jobLocations
                                  .map((location) => location?.location)
                                  .join(", ")}
                              </p>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="flex flex-col md:flex-row md:items-center md:space-x-2 mb-0 mt-2">
                              <div className="flex items-center text-muted">
                                {/* <i className="uil uil-clock-three text-primary me-1"></i> */}
                                <p className="text-nowrap inline-block align-middle text-center">
                                  <FaCalendar className="text-success inline-block align-middle me-1" />
                                  {formatDate(job?.postedAt)}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-col md:flex-row md:items-center md:space-x-2 mb-0 mt-2">
                              <div className="flex items-center text-muted">
                                {/* <i className="uil uil-clock-three text-primary me-1"></i> */}
                                <p className="text-nowrap inline-block align-middle text-center">
                                  <LuClock3 className="text-danger inline-block align-middle me-1" />
                                  {formatTimestampWithDifference(job?.postedAt)}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="text-center mt-3">
                              <span
                                className={`badge fs-13 mt-1 mx-1 ${job?.FullTime === true
                                  ? "bg-success-subtle text-success"
                                  : job.PartTime === true
                                    ? "bg-danger-subtle text-danger"
                                    : job.Freelance === true
                                      ? "bg-primary-subtle text-light"
                                      : "bg-primary text-light" // Default style
                                  }`}
                              >
                                {job?.jobType}
                              </span>
                              {(job.badges || []).map((badgeInner, key) => (
                                <span
                                  className={
                                    "badge " + badgeInner.badgeclassName + " fs-13 mt-1"
                                  }
                                  key={key}
                                >
                                  {badgeInner.badgeName}
                                </span>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {profileStatus == 'student' && (
                        <div className="p-2 bg-light">
                          <Row className="text-center">
                            <Col lg={3} md={3}>
                              <div>
                                {/* Apply Now button */}
                                <Link
                                  to={`/companydetails?id=${job?._id}&companyId=${job?.companyId?._id}`}
                                  onClick={() => openApplyModal(job._id)}
                                  className="primary-link"
                                >
                                  Apply Now <i className="mdi mdi-chevron-double-right"></i>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                      {profileStatus == null && (
                        <div className="p-2 bg-light">
                          <Row className="text-center">
                            <Col lg={3} md={3}>
                              <div>
                                {/* Apply Now button */}
                                <Link
                                  to={`/companydetails?id=${job?._id}&companyId=${job?.companyId?._id}`}
                                  onClick={() => openApplyModal(job._id)}
                                  className="primary-link"
                                >
                                  Apply Now <i className="mdi mdi-chevron-double-right"></i>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}

                    </div>
                  ))
                )}
                {/* Pagination Component */}
                <Pagination
                  jobsPerPage={jobsPerPage}
                  totalJobs={jobVacancyPostDetails.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
                <div
                  className="modal fade"
                  id="applyNow"
                  tabIndex="-1"
                  aria-labelledby="applyNow"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    {/* Pass the selectedJobId to the JobApply component */}
                    <JobApply
                      isOpen={applyModalOpen}
                      toggleModal={closeApplyModal}
                      jobId={selectedJobId}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </React.Fragment>
  );
};

export default RightSideContent;
