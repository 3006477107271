import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

export const Timer = ({ setIsOtpExp }) => {
    const [sec, setSec] = useState(20);
    const [min, setMin] = useState(5);
    useEffect(() => {
        const interval = setInterval(() => {
            if (sec > 0 && min >= 0) {
                setSec(sec - 1);
            } else {
                if (min !== 0) {
                    setSec(59);
                    setMin(min - 1);
                }
            }
            if (min === 0 && sec === 0) {
                setIsOtpExp(true)
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [sec, min]);
    return (
        <span>0{min}:{sec < 10 ? '0' + sec : sec}</span>
    )
}
