import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Card, Col, Row, CardBody } from "reactstrap";
import StudentRegistration from "./Modules/StudentRegistration";
import CompanyRegistration from "./Modules/CompanyRegistration";
import signUpImage from "../../assets/images/auth/sign-up.png";

const SignUp = () => {
  document.title = "Sign Up | Fresherszone";

  const [role, setRole] = useState('student')

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth ">
              <Container>
                <Row className="justify-content-center ">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box ">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <h4>Freshers Zone</h4>
                            </Link>
                            <div className="mt-5">
                              <img
                                src={signUpImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 h-100 text-white">
                            <div className="text-white mb-2">
                              <h3>SignUp</h3>
                            </div>
                             <div className="select_role" >
                              <button
                                onClick={() => setRole("student")}
                                style={{
                                  width:'100px',
                                  padding: '2px',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  backgroundColor: role === 'student' ? 'white' : 'transparent',
                                  color: role === 'student' ? 'black' : 'white'
                                }}
                                type="button"
                              >
                                Candidate
                              </button>
                              <button
                                onClick={() => setRole("company")} 
                                style={{
                                  width:'100px',
                                  padding: '2px',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  backgroundColor: role === 'company' ? 'white' : 'transparent',
                                  color: role === 'company' ? 'black' : 'white'
                                }}
                                type="button"
                              >
                                Company
                              </button>
                            </div>
                            <div className="w-100">
                              {role == 'student'
                                ? <StudentRegistration />
                                : <CompanyRegistration />}
                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  Already a member ?{" "}
                                  <Link
                                    to="/signin"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    Sign In{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUp;
