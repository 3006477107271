import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseURL } from "../../../repositories/Repository";
import { useJob } from "../../../hooks/useJob";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { JobApply } from "../../../components/shared/JobApply";
import { toast } from "react-toastify";
import { useProfile } from "../../../hooks/useProfile";

const RightSideContent = () => {
  //Apply Now Model
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const route = useNavigate();
  const { getalljobs, jobList } = useJob();
  const { showprofile, profile } = useProfile();
  const [profileStatus, setProfileStatus] = useState();

  useEffect(() => {
    getalljobs();
    showprofile();
  }, [])

  //to cheak login for job applay
  useEffect(() => {
    if (profile && profile.role === 'student') {
      setProfileStatus(profile.role);
    } else if ((profile && profile.role === 'campany')) {
      setProfileStatus(profile.role);
    }
    else {
      setProfileStatus(profile);
    }
  }, [profile]);

  const formatDate = (dateform) => {
    const date = new Date(dateform);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const formatYear = (dateform) => {
    const date = new Date(dateform);
    return date.toLocaleDateString([], { year: 'numeric' });
  };

  const location = useLocation();
  const Id = new URLSearchParams(location.search).get("id")
  let jobDescriptionView

  const openApplyModal = (jobId) => {
    if (isAuthenticated) {
      setSelectedJobId(jobId);
      setApplyModalOpen(true);
    } else {
      toast.warning('You need to login to apply for this job.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // Delayed redirection to the sign-in page after 2 seconds (adjust the time as needed)
      setTimeout(() => {
        route('/signin');
        // 2000 milliseconds = 2 seconds
      }, 2000);
    }
  };

  const closeApplyModal = () => {
    setSelectedJobId(null); // Reset the selected job ID when the modal is closed
    setApplyModalOpen(false);
  };

  jobDescriptionView = jobList?.map((data) => {
    if (data?._id == Id) {
      // const address =data.companyId ? data.companyId.address.address1 : '';
      // const encodedAddress = encodeURIComponent(address);
      // const mapUrl = `https://www.google.com/maps/embed/v1/place?q=${encodedAddress}&key=YOUR_GOOGLE_MAPS_API_KEY`;
      return (
        <React.Fragment>
          <div className="side-bar ms-lg-4">
            <Card className="job-overview">
              <CardBody className="p-4">
                <h6 className="fs-17">Job Overview</h6>
                <ul className="list-unstyled mt-4 mb-0">
                  {data?.jobTitle && (
                    <li className="conditional-item">
                      <div className="d-flex mt-4">
                        <i className="uil uil-user icon bg-primary-subtle text-primary"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Job Title</h6>
                          <p id="overviewJob" className="text-muted mb-0">
                            {data.jobTitle.split(' ')
                              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                              .join(' ')}
                          </p>
                        </div>
                      </div>
                    </li>
                  )}
                  {data?.jobLocations && (
                    <li className="conditional-item">
                      <div className="d-flex mt-4">
                        <i className="uil uil-location-point icon bg-primary-subtle text-primary"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Location</h6>
                          <p className="text-muted mb-0">{data.jobLocations.map(location => location.location).join(', ')}</p>
                        </div>
                      </div>
                    </li>
                  )}
                  {data?.salary && (
                    <li className="conditional-item">
                      <div className="d-flex mt-4">
                        <i className="uil uil-usd-circle icon bg-primary-subtle text-primary"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Offered Salary</h6>
                          <p className="text-muted mb-0">{data.salary}/-</p>
                        </div>
                      </div>
                    </li>
                  )}
                  {data?.qualification && (
                    <li>
                      <div className="d-flex mt-4">
                        <i className="uil uil-graduation-cap icon bg-primary-subtle text-primary"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Qualification</h6>
                          <p className="text-muted mb-0"> {data.qualification}</p>
                        </div>
                      </div>
                    </li>
                  )}
                  {data?.postedAt && (
                    <li>
                      <div className="d-flex mt-4">
                        <i className="uil uil-calendar-alt icon bg-primary-subtle text-primary"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Date Posted</h6>
                          <p className="text-muted mb-0"> {formatDate(data.postedAt)}</p>
                        </div>
                      </div>
                    </li>
                  )}
                  {data?.postedAt && (
                    <li>
                      <div className="d-flex mt-4">
                        <i className="uil uil-calendar-slash icon bg-primary-subtle text-primary"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Application Deadline Date</h6>
                          <p className="text-muted mb-0">{formatDate(data.applicationDeadlineDate)}</p>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
                <div className="mt-3">
                  {/* Apply Now button */}
                  {profileStatus === 'student' && (
                    <Link
                      to={`/jobdetails?id=${data._id}`}
                      onClick={() => openApplyModal(data?._id)}
                      className="btn btn-primary btn-hover w-100 mt-2"
                    >
                      Apply Now <i className="uil uil-arrow-right"></i>
                    </Link>
                  )}
                  {profileStatus === null && (
                    <Link
                      to={`/jobdetails?id=${data._id}`}
                      onClick={() => openApplyModal(data?._id)}
                      className="btn btn-primary btn-hover w-100 mt-2"
                    >
                      Apply Now <i className="uil uil-arrow-right"></i>
                    </Link>
                  )}
                </div>
              </CardBody>
            </Card>

            <Card className="company-profile mt-4">
              <CardBody className="p-4">
                <div className="text-center">
                  {data && data?.companyId?.logo ? (
                    <div className='avatar-container '>
                      <img className='avatar-logo rounded-3' src={`${baseURL}/${data?.companyId?.logo?.file}`} alt="Company Logo" />
                    </div>
                  ) : (
                    <div className='avatar-container'>
                      <FontAwesomeIcon
                        icon={faLandmark}
                        className="avatar-logo"
                        color='#373840'
                        style={{ width: '80px', height: '80px' }}
                      />
                    </div>
                  )}
                  <div className="mt-4">
                    <h4 className="fs-17 mb-1">
                      {data.companyId ? data.companyId.basicInfo.companyName : 'N/A'}
                    </h4>
                    <p className="text-muted">Since {formatYear(data.companyId ? data.companyId.basicInfo.dateOfRegistration : 'N/A')}</p>
                  </div>
                </div>
                <ul className="list-unstyled mt-4">
                  <li className="mt-3">
                    <div className="d-flex">
                      <i className="uil uil-envelope text-primary fs-4"></i>
                      <div className="ms-3">
                        <h6 className="fs-14 mb-2">Email</h6>
                        <p className="text-muted fs-14 mb-0">
                          {data.companyId ? data.companyId.basicInfo.email : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className="d-flex">
                      <i className="uil uil-globe text-primary fs-4">
                      </i>
                      <div className="ms-3">
                        <h6 className="fs-14 mb-2">Website</h6>
                        <p className="text-muted fs-14 text-break mb-0">
                          {data.companyId ? (
                            <a href={data.companyId?.basicInfo?.links?.website} target="_blank" rel="noopener noreferrer">
                              {data.companyId?.basicInfo?.links?.website}
                            </a>
                          ) : (
                            <h6>Website not found.....</h6>
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-3">
                    <div className="d-flex">
                      <i className="uil uil-map-marker text-primary fs-4"></i>
                      <div className="ms-3">
                        <h6 className="fs-14 mb-2">Location</h6>
                        <p className="text-muted fs-14 mb-0">
                          {data.companyId ? data.companyId.address.location : ''} ({data.companyId ? data.companyId.address.postalCode : ''})
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="mt-4">
                  <Link
                    to={`/companydetails?id=${data._id}&companyId=${data?.companyId?._id}`}
                    className="btn btn-primary btn-hover w-100 rounded"
                  >
                    <i className="mdi mdi-eye"></i> View Profile
                  </Link>
                </div>
              </CardBody>
            </Card>

            {/* <div className="mt-4">
              <h6 className="fs-16 mb-3">Job location</h6>
              <iframe
                title="maps"
                src={mapUrl}
                style={{ width: `100%`, height: `250` }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div> */}
            <div
              className="modal fade"
              id="applyNow"
              tabIndex="-1"
              aria-labelledby="applyNow"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                {/* Pass the selectedJobId to the JobApply component */}
                <JobApply
                  isOpen={applyModalOpen}
                  toggleModal={closeApplyModal}
                  jobId={selectedJobId}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <></>
      )
    }
  })

  return (
    <React.Fragment>
      {jobDescriptionView}
    </React.Fragment>
  );
};

export default RightSideContent;
