import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useProfile } from '../../../hooks/useProfile';
import { useNavigate } from 'react-router-dom';

const CandidateSkills = () => {

    const { reqCandidateInfo, profileInfo } = useProfile();
    const [skillsData, setSkillsData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);
    const [showSkills, setShowSkills] = useState(true);
    const route = useNavigate();

    useEffect(() => {
        reqCandidateInfo();
    }, []);

    useEffect(() => {
        if (profileInfo) {
            const { skills, projectInfo } = profileInfo;
            if (Array.isArray(skills))
                setSkillsData(skills);

            setProjectsData(projectInfo);
        }
    }, [profileInfo]);

    // Add functionality for editing skills or projects
    const handleEditClick = () => {
        if (profileInfo) {
            route('/candidateprofile');
        } else {
            console.log("Candidate details are not available...");
        }
    };

    return (
        <>
            <section className="section">
                <div className="mb-2">
                    <div className="card">
                        <div className="card-header text-dark d-flex justify-content-between align-items-center">
                            <h4 className="text-2xl font-bold text-nowrap">
                                Skills & Projects
                            </h4>
                            <FaEdit
                                className="text-lg mb-2"
                                onClick={handleEditClick}
                                style={{ cursor: "pointer", color: "black" }}
                            />
                        </div>
                        <div className="card-body text-center">
                            <div className="row">
                                {/* Skills Section */}
                                <div className="col-lg-12">
                                    <ul
                                        className="nav nav-pills nav-justified justify-content-center text-nowrap"
                                        id="skillsTabs"
                                        role="tablist"
                                    >
                                        <li className="mr-1 text-yellow-400" role="presentation">
                                            <a
                                                href="#skills"
                                                className={`btn btn-outline-primary btn-hover ${showSkills ? 'active' : ''}`}
                                                id="skills-tab"
                                                data-bs-toggle="tab"
                                                role="tab"
                                                aria-controls="skills"
                                                aria-selected={showSkills ? "true" : "false"}
                                                onClick={() => setShowSkills(true)}
                                            >
                                                Skills
                                            </a>
                                        </li>
                                        <li className="ml-1" role="presentation">
                                            <a
                                                href="#project"
                                                className={`btn btn-outline-primary btn-hover ${showSkills ? '' : 'active'}`}
                                                id="project-tab"
                                                data-bs-toggle="tab"
                                                role="tab"
                                                aria-controls="project"
                                                aria-selected={showSkills ? "false" : "true"}
                                                onClick={() => setShowSkills(false)}
                                            >
                                                Project
                                            </a>
                                        </li>
                                    </ul>
                                    {/* Display each skill as a button block */}
                                    <div className={`skill-blocks d-flex flex-wrap justify-content-center text-center tab-content mt-3${showSkills ? '' : ' d-none'}`}>
                                        {skillsData[0]?.split(',').map((skill, index) => (
                                            <button
                                                type="button"
                                                key={index}
                                                className="btn btn-outline job-box card mb-2 mr-1 skill-button"
                                            >
                                                {skill.trim()}
                                            </button>
                                        ))}
                                    </div>
                                    {/* Display projects data */}
                                    <div className={`${showSkills ? 'd-none' : 'col-md-12'}`}>
                                        <div className="tab-content mt-3">
                                            {projectsData.map((project, index) => (
                                                // Skip rendering if projectName is empty
                                                project?.projectName && (
                                                    <div key={index} className="text-center mb-2">
                                                        <h5>{project?.projectName}</h5><hr />
                                                        <p className='text-left'>{project?.projectDescription}</p>
                                                        <hr />
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CandidateSkills;
