import React from "react";
import Select from "react-select";
import location from "../../Admin/pages/locations.json";

const CountryOptions = ({ onCountryChange }) => {
  const data = location.map((loc) => ({ label: loc.name, value: loc.name }));

  const handleCountryChange = (selectedOption) => {
    if (onCountryChange) {
      onCountryChange(selectedOption ? selectedOption.value : '');
    }
  };

  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      padding: '12px 0 12px 35px',
      margin: '-16px 0 0 -45px',
      borderRadius: '0',
      outline: 'none',
    }),
  };

  return (
    <React.Fragment>
      <Select
        options={data}
        className="choices selectForm__inner "
        defaultValue={{ label: 'Select city...', value: null }}
        styles={colourStyles}
        isClearable
        onChange={handleCountryChange}
      />
    </React.Fragment>
  );
};

export default CountryOptions;
