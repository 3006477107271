import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { useJob } from "../../../hooks/useJob";
import { baseURL } from "../../../repositories/Repository";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";

const LeftSideContent = () => {

  const location = useLocation();
  const Id = new URLSearchParams(location.search).get("id")
  let companyDescriptionView
  const route = useNavigate();

  const { getalljobs, jobList } = useJob();
  useEffect(() => {
    getalljobs();
  }, [])

  const formatYear = (dateform) => {
    const date = new Date(dateform);
    const options = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString([], options);
  };

  const handleSocialLinkClick = (url) => {
    window.open(url, '_blank');
  };

  companyDescriptionView = jobList?.map((data) => {
    // Check if Id is present in the URL
    if (!Id) {
      // Redirect to home page
      route('/');
      return null;
    }
    if (data?._id === Id) {
      return (
        <React.Fragment>
          <Col lg={4}>
            <Card className="side-bar">
              <CardBody className="p-4">
                <div className="candidate-profile text-center">
                  {data && data?.companyId?.logo ? (
                    <div className='avatar-container '>
                      <img className='avatar-logo rounded-3' src={`${baseURL}/${data?.companyId?.logo?.file}`} alt="Company Logo" />
                    </div>
                  ) : (
                    <div className='avatar-container'>
                      <FontAwesomeIcon icon={faLandmark}
                        className='avatar-logo rounded-3'
                        color='#373840'
                        style={{ width: '80px', height: '80px' }}
                      />
                    </div>
                  )}
                  {data.companyId && (
                    <h6 className="fs-18 mb-1 mt-4">
                      {data.companyId.basicInfo?.companyName}
                    </h6>
                  )}
                  {data.companyId && data.companyId.basicInfo?.dateOfRegistration && (
                    <p className="text-muted mb-4">
                      Since {formatYear(data.companyId.basicInfo.dateOfRegistration)}
                    </p>
                  )}
                  <ul className="candidate-detail-social-menu list-inline mb-0">
                    {data.companyId && data.companyId.basicInfo?.links && (
                      <>
                        {data.companyId.basicInfo.links?.twitterLink && (
                          <li className="list-inline-item">
                            <a href={data.companyId.basicInfo.links?.twitterLink} className="social-link" target="_blank" rel="noopener noreferrer">
                              <i className="uil uil-twitter-alt"></i>
                            </a>
                          </li>
                        )}
                        {data.companyId.basicInfo.links?.facebookLink && (
                          <li className="list-inline-item">
                            <a href={data.companyId.basicInfo.links?.facebookLink} className="social-link" target="_blank" rel="noopener noreferrer">
                              <i className="uil uil-facebook"></i>
                            </a>
                          </li>
                        )}
                        {data.companyId.basicInfo.links?.linkedinLink && (
                          <li className="list-inline-item">
                            <a href={data.companyId.basicInfo.links?.linkedinLink} className="social-link" target="_blank" rel="noopener noreferrer">
                              <i className="uil uil-linkedin-alt"></i>
                            </a>
                          </li>
                        )}
                        {data.companyId.basicInfo.links?.instagramLink && (
                          <li className="list-inline-item">
                            <a href={data.companyId.basicInfo.links?.instagramLink} className="social-link" target="_blank" rel="noopener noreferrer">
                              <i className="uil uil-instagram"></i>
                            </a>
                          </li>
                        )}
                      </>
                    )}
                  </ul>
                </div>
              </CardBody>

              <CardBody className="candidate-profile-overview border-top p-4">
                <h6 className="fs-17 fw-semibold mb-3">Profile Overview</h6>
                <ul className="list-unstyled mb-0">
                  {data.companyId && data.companyId.basicInfo?.companySize && (
                    <li>
                      <div className="d-flex">
                        <label className="text-dark">Employees</label>
                        <div>
                          <p className="text-muted mb-0">{data.companyId.basicInfo.companySize}</p>
                        </div>
                      </div>
                    </li>
                  )}
                  {data.companyId && data.companyId.address?.address1 && (
                    <li>
                      <div className="d-flex">
                        <label className="text-dark">Location</label>
                        <div>
                          <p className="text-muted mb-0">{data.companyId.address.address1}</p>
                        </div>
                      </div>
                    </li>
                  )}
                  {data.companyId && data.companyId.basicInfo.links?.website && (
                    <li>
                      <div className="d-flex">
                        <label className="text-dark">Website</label>
                        <div className="fade-in">
                          <a
                            href={data.companyId.basicInfo.links.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-break mb-0"
                          >
                            {data.companyId.basicInfo.links.website}
                          </a>
                        </div>
                      </div>
                    </li>
                  )}
                  {data.companyId && data.companyId.basicInfo?.dateOfRegistration && (
                    <li>
                      <div className="d-flex">
                        <label className="text-dark">Established</label>
                        <div>
                          <p className="text-muted mb-0">{formatYear(data.companyId.basicInfo.dateOfRegistration)}</p>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
                <div className="mt-3">
                  <Link
                    to={`mailto:${data.companyId ? data.companyId.basicInfo?.email : ''}`}
                    className="btn btn-outline-danger btn-hover w-100"
                  >
                    <i className="uil uil-envelope"></i> E-Mail
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        </React.Fragment>
      );
    } else {
      return (
        <></>
      )
    }
  })
  return (
    <React.Fragment>
      {companyDescriptionView}
    </React.Fragment>
  );
};

export default LeftSideContent;
