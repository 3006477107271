
import React from 'react'
import UpdateJob from './updateJob';
import JobPostForm from './postNewJob';
import { useLocation } from 'react-router-dom';

const Jobpost = () => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id")
  
  if (id) {
    return <UpdateJob id={id} />
  } else {
    return <JobPostForm />
  }
}
export default Jobpost