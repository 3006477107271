import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Collapse, Input, Label } from "reactstrap";

const Sidebar = ({ setSearchLocation, setSelectedJobType, setSelectDateType, selectDateType, setSelectedJobCategory }) => {
  const [toggleFirst, setToggleFirst] = useState(true);
  const [toggleThird, setToggleThird] = useState(true);
  const [toggleFourth, setToggleFourth] = useState(true);
  const [toggleFifth, setToggleFifth] = useState(true);

  const [activeLink, setActiveLink] = useState(null);

  //for jobtype
  const jobTypes1 = ["Freelancer"];
  const jobTypes2 = ["Full Time"];
  const jobTypes3 = ["Internship"];
  const jobTypes4 = ["Part Time"];


  //job categories
  const jobCategories1 = ["Accounting & Finance"];
  const jobCategories2 = ["Admin Support"];
  const jobCategories3 = ["Administrative Officer"]
  const jobCategories4 = ["Animal Care Worker"];
  const jobCategories5 = ["Bank Jobs"];
  const jobCategories6 = ["Catering & Tourism"];
  const jobCategories7 = ["Customer Service"];
  const jobCategories8 = ["Data Entry Job"];
  const jobCategories9 = ["Defence Jobs"];
  const jobCategories10 = ["Digital Marketing"];
  const jobCategories11 = ["Diploma Jobs"];
  const jobCategories12 = ["Forest Worker"];
  const jobCategories13 = ["Government Jobs"];
  const jobCategories14 = ["Health Care Jobs"];
  const jobCategories15 = ["It / Software Jobs"];
  const jobCategories16 = ["Logistics / Transportation"];
  const jobCategories17 = ["Manufacturing & Production"];
  const jobCategories18 = ["Marketing & Advertising"];
  const jobCategories19 = ["Performing Arts & Media"];
  const jobCategories20 = ["Project Manager"];
  const jobCategories21 = ["Purchasing Manager"];
  const jobCategories22 = ["Retail & Customer Services"];
  const jobCategories23 = ["Sports Jobs"];
  const jobCategories24 = ["Teaching Jobs"];


  //CheckBox
  const [isChecked, setIsChecked] = useState(true);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const [isDateChecked, setIsDateChecked] = useState(true);
  const handleDateOnChange = () => {
    setIsDateChecked(!isDateChecked);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };


  const handleJobTypeChange = (jobType) => {
    setSelectedJobType(jobType);
  };

  const handleDateposted = (value, isSingleValue = false) => {
    setSelectDateType((prevSelected) => {
      if (value === 'checkAll') {
        return prevSelected.includes('checkAll') ? [] : ['checkAll'];
      } else {
        return isSingleValue
          ? prevSelected.includes(value)
            ? []
            : [value]
          : prevSelected.includes(value)
          ? prevSelected.filter((selected) => selected !== value)
          : [...prevSelected, value];
      }
    });
  };
  
  const handleJobCategoryClick = (category) => {
    if (activeLink === category) {
      // active link deselect 
      setSelectedJobCategory(null);
      setActiveLink(null);               // Reset active link
    } else {
      // If it is not active, select it
      setSelectedJobCategory(category);
      setActiveLink(category);
    }
  };
  return (
    <React.Fragment>
      <Col lg={3}>
        <div className="side-bar mt-5 mt-lg-0">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="locationOne">
                <Button
                  className="accordion-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleFirst(!toggleFirst);
                  }}
                  role="button"
                  id="collapseExample"
                >
                  Location
                </Button>
              </h2>
              <Collapse isOpen={toggleFirst}>
                <div className="accordion-body">
                  <div className="side-title">
                    <div className="search-wrapper">
                      <form
                        className="position-relative"
                        onSubmit={handleSearchSubmit}
                      >
                        <Input
                          className="form-control filter-input-box-search"
                          type="search"
                          placeholder="Search location"
                          onChange={(e) => setSearchLocation(e.target.value)}
                          style={{ marginLeft: "-10px", border: "1px solid #ced4da", width: "105%" }}
                        />
                        {/* <button
                          className="bg-transparent border-0 position-absolute top-50 end-0 translate-middle-y me-2"
                          type="submit"
                        >
                          <span className="mdi mdi-magnify text-muted"></span>
                        </button> */}
                      </form>
                    </div>
                    {/* <div className="area-range slidecontainer">
                      <div className="form-label mb-4">
                        Area Range: {value}.00 miles
                        <span
                          className="example-val mt-2"
                          id="slider1-span"
                        ></span>
                      </div>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={value}
                        onChange={({ target: { value } }) => setValue(value)}
                        className={`slider ${
                          value > 50 ? "slider-50" : "slider-0"
                        }`}
                      />
                    </div> */}
                  </div>
                </div>
              </Collapse>
            </div>

            {/* <div className="accordion-item">
              <h2 className="accordion-header" id="experienceOne">
                <Button
                  className="accordion-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleSecond(!toggleSecond);
                  }}
                  role="button"
                  id="collapseExample"
                >
                  Work experience
                </Button>
              </h2>
              <Collapse isOpen={toggleSecond}>
                <div className="accordion-body">
                  <div className="side-title">
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked1"
                      />
                      <label
                        className="form-check-label ms-2 text-muted"
                        htmlFor="flexCheckChecked1"
                      >
                        No experience
                      </label>
                    </div>
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked2"
                        checked={isChecked}
                        onChange={handleOnChange}
                      />
                      <label
                        className="form-check-label ms-2 text-muted"
                        htmlFor="flexCheckChecked2"
                      >
                        0-3 years
                      </label>
                    </div>
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked3"
                      />
                      <label
                        className="form-check-label ms-2 text-muted"
                        htmlFor="flexCheckChecked3"
                      >
                        3-6 years
                      </label>
                    </div>
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked4"
                      />
                      <label
                        className="form-check-label ms-2 text-muted"
                        htmlFor="flexCheckChecked4"
                      >
                        More than 6 years
                      </label>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div> */}

            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="jobType">
                <Button
                  className="accordion-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleThird(!toggleThird);
                  }}
                  role="button"
                  id="collapseExample"
                >
                  Type of Employment
                </Button>
              </h2>
              <Collapse isOpen={toggleThird}>
                <div className="accordion-body">
                  <div className="side-title">

                    {jobTypes1.map((freelancer) => (
                      <div className="form-check mt-2" key={freelancer}>
                        <Input
                          className="form-check-input border-primary"
                          type="radio"
                          name="jobTypeRadio"
                          id={`jobTypeRadio-${freelancer}`}
                          onChange={() => handleJobTypeChange(freelancer)}
                        />
                        <label
                          className="form-check-label ms-2 text-muted"
                          htmlFor={`jobTypeRadio-${freelancer}`}
                        >
                          {freelancer}
                        </label>
                      </div>
                    ))}
                    {jobTypes2.map((fullTime) => (
                      <div className="form-check mt-2" key={fullTime}>
                        <Input
                          className="form-check-input border-primary"
                          type="radio"
                          // name="fullTime"
                          name="jobTypeRadio"
                          id={`jobTypeRadio-${fullTime}`}
                          onChange={() => handleJobTypeChange(fullTime)}
                        />
                        <label
                          className="form-check-label ms-2 text-muted"
                          htmlFor={`jobTypeRadio-${fullTime}`}
                        >
                          {fullTime}
                        </label>
                      </div>
                    ))}
                    {jobTypes3.map((internship) => (
                      <div className="form-check mt-2" key={internship}>
                        <Input
                          className="form-check-input border-primary"
                          type="radio"
                          name="jobTypeRadio"
                          //name="flexRadioDefault"
                          id={`jobTypeRadio-${internship}`}
                          onChange={() => handleJobTypeChange(internship)}
                        />
                        <label
                          className="form-check-label ms-2 text-muted"
                          htmlFor={`jobTypeRadio-${internship}`}
                        >
                          {internship}
                        </label>
                      </div>
                    ))}
                    {jobTypes4.map((partTime) => (
                      <div className="form-check mt-2" key={partTime}>
                        <Input
                          className="form-check-input border-primary"
                          type="radio"
                          //name="partTime"
                          name="jobTypeRadio"
                          id={`jobTypeRadio-${'partTime'}`}
                          onChange={() => handleJobTypeChange(partTime)}
                        />
                        <label
                          className="form-check-label ms-2 text-muted"
                          htmlFor={`jobTypeRadio-${'partTime'}`}
                        >
                          {partTime}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="datePosted">
                <Button
                  className="accordion-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleFourth(!toggleFourth);
                  }}
                  role="button"
                  id="collapseExample"
                >
                  Date Posted
                </Button>
              </h2>
              <Collapse isOpen={toggleFourth}>
                <div className="accordion-body">
                  <div className="side-title form-check-all">
                    <div className="form-check">
                      <Input
                        className="form-check-input border-primary"
                        type="checkbox"
                        id="flexCheckChecked3"
                        value="checkAll"
                        onClick={() => handleDateposted("checkAll", true)}
                        checked={selectDateType.includes("checkAll")}
                        // checked={isChecked}
                        selected
                      />
                      <Label
                        className="form-check-label ms-2 text-muted"
                        htmlFor="checkAll"
                        onClick={() => handleDateposted("checkAll", true)}
                      >
                        All
                      </Label>
                    </div>

                    <div className="form-check mt-2">
                      <Input
                        className="form-check-input border-primary"
                        type="checkbox"
                        name="datePosted"
                        value="lastHour"

                        onClick={() => handleDateposted("lastHour", true)}
                        checked={selectDateType.includes("lastHour")}

                      />
                      <Label
                        onClick={() => handleDateposted("lastHour", true)}
                        className="form-check-label ms-2 text-muted"
                        htmlFor="flexCheckChecked6"
                      >
                        Last hours
                      </Label>
                    </div>
                    <div className="form-check mt-2">
                      <Input
                        className="form-check-input border-primary"
                        type="checkbox"
                        name="datePosted"
                        value="24hours "
                        id="flexCheckChecked7"
                        onClick={() => handleDateposted("24hours", true)}
                        checked={selectDateType.includes("24hours")}
                      />
                      <Label
                        className="form-check-label ms-2 text-muted"
                        htmlFor="flexCheckChecked6"
                        onClick={() => handleDateposted("24hours", true)}
                      >
                        Last 24 hours
                      </Label>
                    </div>
                    <div className="form-check mt-2">
                      <Input
                        className="form-check-input border-primary"
                        type="checkbox"
                        name="datePosted"
                        value="last_7_days"
                        id="flexCheckChecked7"
                        onClick={() => handleDateposted("last_7_days", true)}
                        checked={selectDateType.includes("last_7_days")}
                      />
                      <Label
                        className="form-check-label ms-2 text-muted"
                        htmlFor="flexCheckChecked6"
                        onClick={() => handleDateposted("last_7_days", true)}
                      >
                        Last 7 days
                      </Label>
                    </div>

                    <div className="form-check mt-2 ">
                      <Input
                        className="form-check-input border-primary"
                        type="checkbox"
                        name="datePosted"
                        value="last_14_days"
                        id="flexCheckChecked8"
                        onClick={() => handleDateposted("last_14_days", true)}
                        checked={selectDateType.includes("last_14_days")}
                      />
                      <Label
                        className="form-check-label ms-2 text-muted"
                        htmlFor="flexCheckChecked6"
                        onClick={() => handleDateposted("last_14_days", true)}
                      >
                        Last 14 days
                      </Label>
                    </div>
                    <div className="form-check mt-2">
                      <Input
                        className="form-check-input border-primary"
                        type="checkbox"
                        name="datePosted"
                        value="last_30_days"
                        id="flexCheckChecked9"
                        onClick={() => handleDateposted("last_30_days", true)}
                        checked={selectDateType.includes("last_30_days")}
                      />
                      <Label
                        className="form-check-label ms-2 text-muted"
                        htmlFor="flexCheckChecked6"
                        onClick={() => handleDateposted("last_30_days", true)}
                      >
                        Last 30 days
                      </Label>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="tagCloud">
                <Button
                  className="accordion-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleFifth(!toggleFifth);
                  }}
                  role="button"
                  id="collapseExample"
                >
                  Job Categories
                </Button>
              </h2>
              <Collapse isOpen={toggleFifth}>
                <div className="accordion-body">
                  <div className="side-title">
                    {jobCategories1.map((AccountingOrFinance) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === AccountingOrFinance ? 'active' : ''}`}
                        key={AccountingOrFinance}
                        onClick={() => handleJobCategoryClick(AccountingOrFinance)}
                      >
                        {AccountingOrFinance}
                      </Link>
                    ))}
                    {jobCategories2.map((AdminSupport) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === AdminSupport ? 'active' : ''}`}
                        key={AdminSupport}
                        onClick={() => handleJobCategoryClick(AdminSupport)}
                      >
                        {AdminSupport}
                      </Link>
                    ))}
                    {jobCategories3.map((AdministrativeOfficer) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === AdministrativeOfficer ? 'active' : ''}`}
                        key={AdministrativeOfficer}
                        onClick={() => handleJobCategoryClick(AdministrativeOfficer)}
                      >
                        {AdministrativeOfficer}
                      </Link>
                    ))}
                    {jobCategories4.map((AnimalCareWorker) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === AnimalCareWorker ? 'active' : ''}`}
                        key={AnimalCareWorker}
                        onClick={() => handleJobCategoryClick(AnimalCareWorker)}
                      >
                        {AnimalCareWorker}
                      </Link>
                    ))}
                    {jobCategories5.map((BankJobs) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === BankJobs ? 'active' : ''}`}
                        key={BankJobs}
                        onClick={() => handleJobCategoryClick(BankJobs)}
                      >
                        {BankJobs}
                      </Link>
                    ))}
                    {jobCategories6.map((CateringAndTourism) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === CateringAndTourism ? 'active' : ''}`}
                        key={CateringAndTourism}
                        onClick={() => handleJobCategoryClick(CateringAndTourism)}
                      >
                        {CateringAndTourism}
                      </Link>
                    ))}
                    {jobCategories7.map((CustomerService) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === CustomerService ? 'active' : ''}`}
                        key={CustomerService}
                        onClick={() => handleJobCategoryClick(CustomerService)}
                      >
                        {CustomerService}
                      </Link>
                    ))}
                    {jobCategories8.map((DataEntryJob) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === DataEntryJob ? 'active' : ''}`}
                        key={DataEntryJob}
                        onClick={() => handleJobCategoryClick(DataEntryJob)}
                      >
                        {DataEntryJob}
                      </Link>
                    ))}
                    {jobCategories9.map((DefenceJobs) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === DefenceJobs ? 'active' : ''}`}
                        key={DefenceJobs}
                        onClick={() => handleJobCategoryClick(DefenceJobs)}
                      >
                        {DefenceJobs}
                      </Link>
                    ))}

                    {jobCategories10.map((DigitalMarketing) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === DigitalMarketing ? 'active' : ''}`}
                        key={DigitalMarketing}
                        onClick={() => handleJobCategoryClick(DigitalMarketing)}
                      >
                        {DigitalMarketing}
                      </Link>
                    ))}

                    {jobCategories11.map((DiplomaJobsJobs) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === DiplomaJobsJobs ? 'active' : ''}`}
                        key={DiplomaJobsJobs}
                        onClick={() => handleJobCategoryClick(DiplomaJobsJobs)}
                      >
                        {DiplomaJobsJobs}
                      </Link>
                    ))}
                    {jobCategories12.map((ForestWorker) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === ForestWorker ? 'active' : ''}`}
                        key={ForestWorker}
                        onClick={() => handleJobCategoryClick(ForestWorker)}
                      >
                        {ForestWorker}
                      </Link>
                    ))}
                    {jobCategories13.map((GovernmentJobs) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === GovernmentJobs ? 'active' : ''}`}
                        key={GovernmentJobs}
                        onClick={() => handleJobCategoryClick(GovernmentJobs)}
                      >
                        {GovernmentJobs}
                      </Link>
                    ))}
                    {jobCategories14.map((HealthCareJobs) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === HealthCareJobs ? 'active' : ''}`}
                        key={HealthCareJobs}
                        onClick={() => handleJobCategoryClick(HealthCareJobs)}
                      >
                        {HealthCareJobs}
                      </Link>
                    ))}
                    {jobCategories15.map((Itorsoftware) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === Itorsoftware ? 'active' : ''}`}
                        key={Itorsoftware}
                        onClick={() => handleJobCategoryClick(Itorsoftware)}
                      >
                        {Itorsoftware}
                      </Link>
                    ))}
                    {jobCategories16.map((LogisticsOrTransportation) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === LogisticsOrTransportation ? 'active' : ''}`}
                        key={LogisticsOrTransportation}
                        onClick={() => handleJobCategoryClick(LogisticsOrTransportation)}
                      >
                        {LogisticsOrTransportation}
                      </Link>
                    ))}
                    {jobCategories17.map((ManufacturingAndProduction) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === ManufacturingAndProduction ? 'active' : ''}`}
                        key={ManufacturingAndProduction}
                        onClick={() => handleJobCategoryClick(ManufacturingAndProduction)}
                      >
                        {ManufacturingAndProduction}
                      </Link>
                    ))}
                    {jobCategories18.map((MarketingAndAdvertising) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === MarketingAndAdvertising ? 'active' : ''}`}
                        key={MarketingAndAdvertising}
                        onClick={() => handleJobCategoryClick(MarketingAndAdvertising)}
                      >
                        {MarketingAndAdvertising}
                      </Link>
                    ))}
                    {jobCategories19.map((PerformingArtsAndMedia) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === PerformingArtsAndMedia ? 'active' : ''}`}
                        key={PerformingArtsAndMedia}
                        onClick={() => handleJobCategoryClick(PerformingArtsAndMedia)}
                      >
                        {PerformingArtsAndMedia}
                      </Link>
                    ))}
                    {jobCategories20.map((ProjectManager) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === ProjectManager ? 'active' : ''}`}
                        key={ProjectManager}
                        onClick={() => handleJobCategoryClick(ProjectManager)}
                      >
                        {ProjectManager}
                      </Link>
                    ))}

                    {jobCategories21.map((PurchasingManager) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === PurchasingManager ? 'active' : ''}`}
                        key={PurchasingManager}
                        onClick={() => handleJobCategoryClick(PurchasingManager)}
                      >
                        {PurchasingManager}
                      </Link>
                    ))}
                    {jobCategories22.map((RetailAndCustomerServices) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === RetailAndCustomerServices ? 'active' : ''}`}
                        key={RetailAndCustomerServices}
                        onClick={() => handleJobCategoryClick(RetailAndCustomerServices)}
                      >
                        {RetailAndCustomerServices}
                      </Link>
                    ))}
                    {jobCategories23.map((SportsJobs) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === SportsJobs ? 'active' : ''}`}
                        key={SportsJobs}
                        onClick={() => handleJobCategoryClick(SportsJobs)}
                      >
                        {SportsJobs}
                      </Link>
                    ))}
                    {jobCategories24.map((TeachingJobs) => (
                      <Link
                        to="#"
                        className={`badge tag-cloud fs-13 mt-2 ${activeLink === TeachingJobs ? 'active' : ''}`}
                        key={TeachingJobs}
                        onClick={() => handleJobCategoryClick(TeachingJobs)}
                      >
                        {TeachingJobs}
                      </Link>
                    ))}
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </Col >
    </React.Fragment >
  );
};

export default Sidebar;
