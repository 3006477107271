import React from "react";
import Section from "./Section"
import TermsandConditionsPage from "./TermsandConditionsPage";

const TermsandConditions = () => {
  document.title = "Terms & Conditions";
  return (
    <React.Fragment>
       <Section />
       <TermsandConditionsPage />
    </React.Fragment>
  );
};

export default TermsandConditions;
