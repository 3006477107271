import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'reactstrap';
import { Card, CardHeader, Row, Col, FormGroup, Input } from "reactstrap";
import { useRef } from 'react';

const Myresume = () => {
    const inputRef = useRef(null);          //browse

    //for browse file
    const handleClick = () => {
        //open file input box on click of another element
        inputRef.current.click();
    };
    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        //reset file input
        event.target.value = null;
    };

    //for award form submit
    const [isFormShown, setIsFormShown] = useState(false);
    const handleFormClicked = event => {
        //  toggle shown state
        setIsFormShown(current => !current);

        // or simply set it to true
        // setIsShown(true);
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { Title, value } = e.target;
        const list = [...inputList];
        list[index][Title] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { Title: "", Academy: "", Year: "", Discription: "" }]);
    };
    const [inputList, setInputList] = useState([{ Title: "", Academy: "", Year: "", Discription: "" }]);

    // handle click event of the Remove button
    const handleRemoveClick2 = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList2(list);
    };

    // handle click event of the Add button
    const handleAddClick2 = () => {
        setInputList2([...inputList, { Academy: "", Year: "", Discription: "" }]);
    };
    const [inputList2, setInputList2] = useState([{ Academy: "", Year: "", Discription: "" }]);

    return (
        <>
            <div className='bg-gradient-light'>
                <Container className='bg-gradient-light mt-6'>
                    <Card>
                        <CardHeader className="bg-gradient-light border-0 ">
                            <Row className="align-items-center mb-3 mt-2">
                                <Col xs="8">
                                    <h3 className="mb-0">Edit Resume</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <form className='bg-gradient-light'>
                            <div className='bg-light  py-4 px-4'>
                                <h4 className="heading-small mb-4">
                                    My Resume
                                    <h6 className='heading-small mt-3'>
                                        CV Attachment
                                    </h6>
                                </h4>
                                <div>
                                    <input
                                        style={{ display: 'none' }}
                                        ref={inputRef}
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                    <button onClick={handleClick} type="button" className="btn btn-primary">Browse</button>
                                    <div className='text-muted'>upload file .pdf,doc,docx</div>
                                </div>
                            </div>
                        </form>

                        <form>
                            <div className='mt-5 bg-light  py-4 px-4'>
                                <h4>Education</h4>
                                <FormGroup>
                                    {/* <ArrowRight /> */}
                                    <Input
                                        className="form-control-alternative"
                                        id="Education"
                                        value="Education"
                                        type="text"
                                        // onClick={handleClicked}
                                        readOnly
                                    />
                                </FormGroup>
                                {inputList.map((x, i) => {
                                    return (
                                        <div className="box">

                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="title"
                                                >
                                                    Title
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="title"
                                                    placeholder="title"
                                                    type="text"

                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="academy"
                                                >
                                                    academy
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="academy"
                                                    placeholder="academy"
                                                    type="text"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="year"
                                                >
                                                    Year
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="year"
                                                    placeholder="year"
                                                    type="date"

                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="Discription"
                                                >
                                                    Discription
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="Discription"
                                                    placeholder="Discription"
                                                    type='text'
                                                />
                                            </FormGroup>
                                            <div className="btn-box float-right">
                                                {inputList.length !== 1 && <button
                                                    className="mr10 border-0 rounded border-secondary"
                                                    onClick={() => handleRemoveClick(i)}>Remove</button>}
                                            </div>
                                            <div className='mt-2'>
                                                {inputList.length - 1 === i && <button onClick={handleAddClick} className='border-0 rounded '>Add Another education</button>}
                                            </div>
                                        </div>
                                    );
                                })}
                                <h4 className='mt-4'>portfoliio</h4>
                                <input type="url" name='url' placeholder='URL' className='input-group rounded-2 border-0 border-secondary mb-3' />
                            </div>


                            <div className='bg-light mt-5 py-4 px-4'>
                                <h4>
                                    Award 1
                                </h4>
                                <FormGroup>

                                    <Input
                                        className="form-control-alternative"
                                        id="Award1"
                                        value="Award1"
                                        type="text"
                                        // onClick={handleFormClicked}
                                        readOnly
                                    />

                                </FormGroup>
                                {inputList2.map((x, i) => {
                                    return (
                                        <div className="box">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="academy"
                                                >
                                                    academy
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="academy"
                                                    placeholder="academy"
                                                    type="text"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="year"
                                                >
                                                    Year
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="year"
                                                    placeholder="year"
                                                    type="date"

                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="Discription"
                                                >
                                                    Discription
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="Discription"
                                                    placeholder="Discription"
                                                    type='text'
                                                />
                                            </FormGroup>
                                            <div className="btn-box float-right">
                                                {inputList2.length !== 1 && <button
                                                    className="mr10 border-0 rounded border-secondary"
                                                    onClick={() => handleRemoveClick2(i)}>Remove</button>}
                                            </div>
                                            <div className='mt-2'>
                                                {inputList2.length - 1 === i && <button onClick={handleAddClick2} className='border-0 rounded '>Add Another Award</button>}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </form>
                    </Card>
                </Container>

            </div>
        </>
    )
}

export default Myresume