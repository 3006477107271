import axios from "axios";

export const baseURL = "https://server.fresherszone.in"

const axiosInstance = axios.create({
    baseURL,
})

axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

export default axiosInstance
