import React, { useRef, useState } from 'react'
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";
import { Validation } from '../../ExtraPages/Components/Validation';
import '../assets/css/Dashboard.css'
import Select from 'react-dropdown-select';
import JoditEditor from 'jodit-react';
import location from "./locations.json"
import { useJob } from '../../../hooks/useJob';
import { Sidebar } from '../layout/AdminLayout/Sidebar';

const JobPostForm = () => {

    const editor = useRef(null);
    const { postJobInfo } = useJob();

    const [jobInfo, setJobInfo] = useState({
        jobTitle: '',
        jobType: '',
        salaryType: '',
        gender: '',
        salary: '',
        qualification: '',
        applicationDeadlineDate: '',
        jobLocations: [],
        description: '',
        jobCategory: '',
        skills: '',
    });
    
    const data = location.map((loc) => {
        return { location: loc.name }
    })

    const [errorMessage, setErrorMessage] = useState('');
    const handleOnChange = (e) => {
        let { name, value } = e.target;
        setJobInfo((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!jobInfo.description.trim()) {
            setErrorMessage('Description is required...');
            setTimeout(() => {
                setErrorMessage('');
            }, 1000);
        } else {
            setErrorMessage('');
            postJobInfo(jobInfo)
        }
    };
  
    const handleCancelClick = () => {
        window.location.href = '/companydashboard';
    };

    const { FirstnameValidation,
    } = Validation();

    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const options = [
        { category: 'Accounting & Finance' },
        { category: 'Admin Support' },
        { category: 'Administrative Officer' },
        { category: 'Animal Care Worker' },
        { category: 'Bank Jobs' },
        { category: 'Catering & Tourism' },
        { category: 'Customer Service' },
        { category: 'Data Entry Job' },
        { category: 'Defence Jobs' },
        { category: 'Digital Marketing' },
        { category: 'Diploma Jobs' },
        { category: 'Forest Worker' },
        { category: 'Government Jobs' },
        { category: 'Health Care Jobs' },
        { category: 'It / Software Jobs' },
        { category: 'Logistics / Transportation' },
        { category: 'Manufacturing & Production' },
        { category: 'Marketing & Advertising' },
        { category: 'Performing Arts & Media' },
        { category: 'Project Manager' },
        { category: 'Purchasing Manager' },
        { category: 'Retail & Customer Services' },
        { category: 'Sports Jobs' },
        { category: 'Teaching Jobs' }
    ]

    return (
        <>
            <div className='mr-4'>
                <Sidebar />
            </div>
            <div >
                <Container className=' mb-3 my-auto mt-5'>
                    <Card className="side-border ">
                        <CardBody className='side-border '>
                            <Form onSubmit={handleSubmit}>
                                <h3 className="heading-small text-dark mb-4 ">
                                    Job Post
                                    <hr className="hr hr-blurry" />
                                </h3>
                                <div className="pl-lg-4 pt-2">
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-username"
                                                >
                                                    Job Title
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="deadline"
                                                    placeholder="Job Title"
                                                    size="lg"
                                                    type="text"
                                                    name="jobTitle"
                                                    value={jobInfo.jobTitle}
                                                    onChange={handleOnChange}
                                                    onKeyUp={FirstnameValidation}
                                                    required
                                                /><span id='first' />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-category"
                                                >
                                                    Job Category
                                                </label>
                                                <br />
                                                <Select
                                                    className='form-control-alternative'
                                                    multi
                                                    placeholder="Select Category..."
                                                    style={{ borderRadius: '6px', fontWeight: '500', fontSize: '14px', borderColor: '#eff0f2', padding: '8px' }}
                                                    options={options}
                                                    searchable='true'
                                                    color='#8d8989'
                                                    labelField='category'
                                                    valueField='category'
                                                    name='jobCategory'
                                                    value={jobInfo.jobCategory}
                                                    onChange={value => setJobInfo(prevState => ({ ...prevState, jobCategory: value }))}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                    <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-skills"
                                                >
                                                    Skills
                                                </label>
                                                <br />
                                                <Input
                                                     className="form-control-alternative"
                                                     id="deadline"
                                                     placeholder="Job skills required"
                                                     size="lg"
                                                     type="text"
                                                    name='skills'
                                                    value={jobInfo.skills}
                                                    onChange={handleOnChange}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-type"
                                                >
                                                    Job Type
                                                </label>
                                                <br />
                                                <select
                                                    name="jobType"
                                                    value={jobInfo.jobType}
                                                    onChange={handleOnChange}
                                                    class="form-select bg-white" required>
                                                    <option value="">Select Job Type</option>
                                                    <option value="Internship">Internship</option>
                                                    <option value="Part Time">Part Time</option>
                                                    <option value="Full Time">Full Time</option>
                                                    <option value="Freelancer">Freelancer</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-salaryType"
                                                >
                                                    Salary Type
                                                </label>
                                                <br />
                                                <select
                                                    name="salaryType"
                                                    value={jobInfo.salaryType}
                                                    onChange={handleOnChange}
                                                    class="form-select bg-white" required>
                                                    <option value="">Select Salary Type</option>
                                                    <option value="Hourly">Hourly</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="Yearly">Yearly</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-gender"
                                                >
                                                    Gender
                                                </label>
                                                <br />
                                                <select
                                                    name="gender"
                                                    value={jobInfo.gender}
                                                    onChange={handleOnChange}
                                                    class="form-select bg-white" >
                                                    <option value="">Select Gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Both">Both</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-salary"
                                                >
                                                    Salary
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    size="lg"
                                                    id="salary"
                                                    placeholder="Salary /-"
                                                    type="number"
                                                    name="salary"
                                                    value={jobInfo.salary}
                                                    onChange={handleOnChange}
                                                /><span id='first' />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-qualification"
                                                >
                                                    Qualification
                                                </label>
                                                <br />
                                                <select
                                                    name="qualification"
                                                    value={jobInfo.qualification}
                                                    onChange={handleOnChange}
                                                    class="form-select  bg-white" required>
                                                    <option value="">Select Qualification</option>
                                                    <option value="Masters Degree">Masters Degree</option>
                                                    <option value="Graduate Degree">Graduate Degree</option>
                                                    <option value="Doctorate Degree">Doctorate Degree</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-deadline"
                                                >
                                                    Application Deadline Date
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="deadline"
                                                    size="lg"
                                                    type="date"
                                                    name="applicationDeadlineDate"
                                                    value={jobInfo.applicationDeadlineDate}
                                                    onChange={handleOnChange}
                                                    min={getCurrentDate()}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-locations"
                                                >
                                                    Job Locations
                                                </label>
                                                <Select
                                                    className='form-control-alternative'
                                                    name='locations'
                                                    multi
                                                    placeholder="Select Locations..."
                                                    style={{ borderRadius: '6px', fontWeight: '500', fontSize: '14px', borderColor: '#eff0f2', padding: '8px' }}
                                                    options={data}
                                                    searchable='true'
                                                    color='#8d8989'
                                                    labelField='location'
                                                    valueField='location'
                                                    value={jobInfo.jobLocations}
                                                    onChange={selectedLocations => setJobInfo(prevState => ({ ...prevState, jobLocations: selectedLocations }))}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label labeltype"
                                                    htmlFor="input-username"
                                                >
                                                    Description
                                                </label>
                                                <JoditEditor
                                                    ref={editor}
                                                    tabIndex={1} // tabIndex of textarea
                                                    value={jobInfo.description}
                                                    onChange={value => setJobInfo(prevState => ({ ...prevState, description: value }))}
                                                    required
                                                />
                                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <div className=' mb-1 mt-1'>
                                    <Button className='btn btn-success mb-3 ml-3' id='post'> Post </Button>
                                    <button className='btn btn-success mb-3 ml-3' id='cancel' onClick={handleCancelClick}>Cancel</button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div >
        </>
    )
}

export default JobPostForm