import Repository from "./Repository"

class ProfileRepository {

    // Candidate profile info request
    async getCandidateProfileInfo(params) {
        const response = await Repository.get('/student/profile', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async updateCandidateProfileInfo(params) {
        const response = await Repository.patch('/student/update-profile', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async getCompanyProfileInfo(params) {
        const response = await Repository.get('/company/profile', params)

            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async updateCompanyProfileInfo(params) {
        const response = await Repository.patch('/company/update-profile', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }
}

export default new ProfileRepository()