import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useAuth } from "../../../hooks/useAuth";
import { AuthFields } from "./AuthFields";
import { BasicFieldsOfCompany } from "./RegistrationModule/BasicFieldsOfCompany";
import { useEffect } from "react";

const CompanyRegistration = () => {
  document.title = "Sign Up | Fresherszone";
  const {
    loading,
    isOtpSent,
    isOtpReSent,
    isOtpVerified,
    sendUserOtp,
    reSendUserOtp,
    verifyUserOtp,
    companyRegistration,
  } = useAuth();

  const [isOtpExp, setIsOtpExp] = useState(true);

  const [address, setAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const [documents, setDocument] = useState({
    name: "",
    file: "",
  });

  const [formData, setFormData] = useState({
    email: "",
    otp: "",
    companyName: "",
    mobileNumber: "",
    dateOfRegistration: "",
    gstNumber: "",
    panNumber: "",
    role: "company",
    agree: false,
  });


  const handleOnChange = (e) => {
    let { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };
  const handleOnAddressChange = (e) => {
    let { name, value } = e.target;
    setAddress((pre) => ({ ...pre, [name]: value }));
  };
  const handleOnDocumentChange = (e) => {
    let { name, value, files } = e.target;
    if (files?.length > 0) {
      setDocument((pre) => ({ ...pre, [name]: files[0] }));
    } else {
      setDocument((pre) => ({ ...pre, [name]: value }));
    }
  };

  const handleOnSendOtpReq = (e) => {
    e.preventDefault();
    sendUserOtp({
      email: formData.email,
      role: formData.role,
    });
  };

  const handleOnReSendOtpReq = (e) => {
    reSendUserOtp({
      email: formData.email,
      role: formData.role,
    });
  };

  const handleOnVerifyOtpReq = (e) => {
    verifyUserOtp({
      email: formData.email,
      role: formData.role,
      otp: formData.otp,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const finalData = new FormData();
    finalData.append("basicInfo", JSON.stringify(formData));
    finalData.append("address", JSON.stringify(address));
    finalData.append("documentName", documents.name);
    finalData.append("document", documents.file);
    // finalData.forEach((v, k) => console.log(JSON.parse(v)))
    companyRegistration(finalData);
  };

  const basicProps = {
    loading,
    formData,
    address,
    documents,
    handleOnChange,
    handleOnAddressChange,
    handleOnDocumentChange,
  };

  const authProps = {
    loading,
    formData,
    isOtpSent,
    isOtpExp,
    handleOnChange,
    setIsOtpExp,
    handleOnSendOtpReq,
    handleOnReSendOtpReq,
    handleOnVerifyOtpReq,
  };


  useEffect(() => {
    setIsOtpExp(false);
  }, [isOtpReSent]);

  return (
    <>
      <Form onSubmit={handleOnSubmit} className="auth-form">
        {isOtpVerified ? (
          <BasicFieldsOfCompany {...basicProps} />
        ) : (
          <AuthFields {...authProps} />
        )}
      </Form>
    </>
  );
};

export default CompanyRegistration;
