export const Validation = () => {
    return {

        //Student Registration Validation

        // Validation for FirstName
        FirstnameValidation: (e) => {

            const Name = e.target.value;
            var condition =/^[a-zA-Z\s]+$/
            var result = Name.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("first");
                messege.innerHTML = "Enter only Alphabet";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("first");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("first");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        }

        ,
        // Validation for LastName
        LastnameValidation: (e) => {
            const Name = e.target.value;
            var condition = /^[a-zA-Z\s]+$/;
            var result = Name.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("last");
                messege.innerHTML = "Enter only Alphabet";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("last");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("last");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        },
        FullnameValidation: (e) => {
            const fullName = e.target.value;
            const condition = /^[a-zA-Z\s]+$/;
            const result = fullName.match(condition);

            if (result === null) {
                var message = document.getElementById("fullnamevalidation");
                message.innerHTML = "Enter only alphabets and spaces";
                message.style.color = "red";
            } else {
                var message = document.getElementById("fullnamevalidation");
                message.innerHTML = "";
                message.style.color = "";
            }

            if (e.target.value === "") {
                var message = document.getElementById("fullnamevalidation");
                message.innerHTML = "";
                message.style.color = "";
            }
        },

        // Validation for Mobile Number
        NumberValidation: (e) => {
            const Number = e.target.value;
            // Check  the all conditon 
            const regex = /^\d{10}$/;

            if (!regex.test(Number)){
                var messege = document.getElementById("Number");
                messege.innerHTML = "Invalid Number:Only 10 Digit";
                messege.style.color = "red";

            }
            else {
                var messege = document.getElementById("Number");
                messege.innerHTML = "";
                messege.style.color = "";
            }
            if (e.target.value === "") {
                var messege = document.getElementById("Number");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        }
        // Validation for Aadharnumber
        ,
        AadharValidation: (e) => {
            const Number = e.target.value;
            // Check  the all conditon 
            const regex = /^\d{12}$/;

            if (!regex.test(Number)) {
                var messege = document.getElementById("AadharNumber");
                messege.innerHTML = "Invalid  Aadhar Number";
                messege.style.color = "red";

            }
            else  {
                var messege = document.getElementById("AadharNumber");
                messege.innerHTML = "";
                messege.style.color = "";
            }
            if (e.target.value === "") {
                var messege = document.getElementById("AadharNumber");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        }
        ,
        // Validation for Email
        // EmailValidation: (e) => {
        //     const data = e.target.value;
        //     const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]/;
        //     const value = emailRegex.test(data);
        //     // Check  the all conditon 
        //     if (value === false) {
        //         var messege = document.getElementById("Email");
        //         messege.innerHTML = "Invalid Email";
        //         messege.style.color = "red";
        //     }
        //     else if (value === true) {
        //         var messege = document.getElementById("Email");
        //         messege.innerHTML = "";
        //         messege.style.color = "";
        //     }
        //     if (e.target.value === "") {
        //         var messege = document.getElementById("Email");
        //         messege.innerHTML = "";
        //         messege.style.color = "";
        //     }
        // }

        EmailValidation: (e) => {
            const data = e?.target?.value;
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]/;
            const isValidEmail = emailRegex.test(data);
            var message = document.getElementById("Email");

            if (!message) {
                console.error("Element with ID 'Email' not found");
                return;
            }

            // Check the conditions
            if (!data) {
                message.innerHTML = "Please enter an email";
                message.style.color = "red";
                } else if (isValidEmail) {
                message.innerHTML = "";
                message.style.color = "";
                            } else {
                message.innerHTML = "Invalid Email";
                message.style.color = "red";
                            }
        }

        // Validation for password

        , validatePassword: (e) => {

            const minLength = 8;
            var data = e.target.value;

            const uppercaseRegex = /[A-Z]/;
            const lowercaseRegex = /[a-z]/;
            const digitRegex = /\d/;
            const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
            // Check  the all conditon 
            if (uppercaseRegex.test(data) === true && lowercaseRegex.test(data) === true && digitRegex.test(data) === true && specialCharRegex.test(data) === true && data.length >= minLength) {
                var messege = document.getElementById("Password");
                messege.innerHTML = "";
                messege.style.color = "red";
            }
            else {
                var messege = document.getElementById("Password");
                messege.innerHTML = "Requires at least 8 characters, including  letters numbers and special character.";
                messege.style.color = "red";
            }

            if (e.target.value === "") {
                var messege = document.getElementById("Password");
                messege.innerHTML = "";
                messege.style.color = "";
            }



        }

        ,


        // Company Registration Validation

        // Validation for CompanyName
        CompanynameValidation: (e) => {

            const Name = e.target.value;
            var condition = /^[a-zA-Z\s]+$/;
            var result = Name.match(condition);
            // Check  the all conditon 
            if (result === null ) {

                var messege = document.getElementById("first");
                messege.innerHTML = "Enter only Alphabet";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("first");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("first");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        },
        // Validation for City
        CitynameValidation: (e) => {

            const Name = e.target.value;
            var condition = /^[a-zA-Z\s]+$/
            var result = Name.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("city");
                messege.innerHTML = "Enter only Alphabet";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("city");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("city");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        }
        ,
        // Validation for StateName
        StatenameValidation: (e) => {

            const Name = e.target.value;
            var condition = /^[a-zA-Z\s]+$/
            var result = Name.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("state");
                messege.innerHTML = "Enter only Alphabet";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("state");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("state");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        }
        ,
        // Validation for ContryName
        ContrynameValidation: (e) => {

            const Name = e.target.value;
            var condition = /^[a-zA-Z\s]+$/
            var result = Name.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("contry");
                messege.innerHTML = "Enter only Alphabet";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("contry");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("contry");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        },

        // Validation for Pancard Number

        PanCardValidation: (e) => {
            const Number = e.target.value;
            // Check  the all conditon 
            const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

            // Check if the PAN number matches the pattern
            if (panPattern.test(Number) === true) {
                var messege = document.getElementById("Pancard");
                messege.innerHTML = "";
                messege.style.color = "";
            }
            else {
                var messege = document.getElementById("Pancard");
                messege.innerHTML = "Invalid PAN number. Please check the format.";
                messege.style.color = "red";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("Pancard");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        }
        ,
        // Validation for GST Number

        GSTnumberValidation: (e) => {
            const Number = e.target.value;
            // Check  the all conditon 
            const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

            // Check if the PAN number matches the pattern
            if (gstPattern.test(Number) === true) {
                var messege = document.getElementById("gstnumber");
                messege.innerHTML = "";
                messege.style.color = "";
            }
            else {
                var messege = document.getElementById("gstnumber");
                messege.innerHTML = "Invalid GST number. Please check the format.";
                messege.style.color = "red";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("gstnumber");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        }
        ,
        PostalcodeValidation: (e) => {
            const postalCode = e.target.value;
            const postalCodePattern = /^\d{6}$/;

            // Check if the postal code matches the pattern
            if (postalCodePattern.test(postalCode)) {
                var messege = document.getElementById("postalcode");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            else {
                var messege = document.getElementById("postalcode");
                messege.innerHTML = "Invalid .Please check the format.";
                messege.style.color = "red";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("postalcode");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        },


        AddressValidation: (e) => {

            const Name = e.target.value;
            var condition = /[a-zA-Z0-9]+$/;
            var result = Name.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("address");
                messege.innerHTML = "Enter only Alphabet and number";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("address");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("address");
                messege.innerHTML = "";
                messege.style.color = "";
            }
        },

        WebValidation: (e) => {

            const link = e.target.value;
            var condition = /^(ftp|http|https):\/\/[^ "]+$/;
            var result = link.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("url");
                messege.innerHTML = "Invalid URL. Please enter a valid URL.";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("url");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("url");
                messege.innerHTML = "";
                messege.style.color = "";

            }
        },

        InstaValidation: (e) => {

            const link = e.target.value;
            var condition = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_]+\/?$/;
            var result = link.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("instaurl");
                messege.innerHTML = "Invalid Instagram URL. Please enter a valid Instagram URL.";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("instaurl");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("instaurl");
                messege.innerHTML = "";
                messege.style.color = "";

            }
        },

        FacebookValidation: (e) => {

            const link = e.target.value;
            var condition = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_]+\/?$/;
            var result = link.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("faceurl");
                messege.innerHTML = "Invalid Facebook URL. Please enter a valid Facebook URL.";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("faceurl");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("faceurl");
                messege.innerHTML = "";
                messege.style.color = "";

            }
        },

        TwitterValidation: (e) => {

            const link = e.target.value;
            var condition = /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/;
            var result = link.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("twitterurl");
                messege.innerHTML = "Invalid Twitter URL. Please enter a valid Twitter URL.";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("twitterurl");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("twitterurl");
                messege.innerHTML = "";
                messege.style.color = "";

            }
        },

        LinkdinValidation: (e) => {

            const link = e.target.value;
            var condition = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
            var result = link.match(condition);
            // Check  the all conditon 
            if (result === null) {

                var messege = document.getElementById("linkdinurl");
                messege.innerHTML = "Invalid LinkedIn URL. Please enter a valid LinkedIn URL.";
                messege.style.color = "red";
            }
            else if (result != null) {
                var messege = document.getElementById("linkdinurl");
                messege.innerHTML = "";
                messege.style.color = "";

            }
            if (e.target.value === "") {
                var messege = document.getElementById("linkdinurl");
                messege.innerHTML = "";
                messege.style.color = "";

            }
        },


        ROCValidation:(e)=>{
            const roc=e.target.value;

            var urlRegex =/^\d{8}$/;

            if (urlRegex.test(roc)) {
                var messege = document.getElementById("rocNumber");
                messege.innerHTML = "";
                messege.style.color = "";


            }
            else{
                var messege = document.getElementById("rocNumber");
                messege.innerHTML = "Invalid ROC number: only 8 digit";
                messege.style.color = "red";
            }
            if (e.target.value === "") {
                var messege = document.getElementById("rocNumber");
                messege.innerHTML = "";
                messege.style.color = "";
            }

        }

    }
}