import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";

//Import Images
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useJob } from "../../../hooks/useJob";
import { baseURL } from "../../../repositories/Repository";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const JobDetailsDescription = () => {
  const { getalljobs, jobList } = useJob();
  const [loading, setLoading] = useState(true);
  const route = useNavigate();

  useEffect(() => {
    getalljobs().then(() => {
      setLoading(false); // Set loading to false when data is fetched
    });
  }, []);

  const location = useLocation();
  const Id = new URLSearchParams(location.search).get("id");

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Button color="primary" disabled>
          <Spinner size="sm" /> Loading...
        </Button>
      </div>
    );
  }

  // Distribute equally if fields present, otherwise take up full width
  const calculateColSize = (data) => {
    const availableFields = ['jobType', 'gender', 'salaryType', 'salary'];
    const presentFieldsCount = availableFields.reduce((count, field) => (data[field] ? count + 1 : count), 0);
    return presentFieldsCount ? 12 / presentFieldsCount : 12;
  };

  const jobDescriptionView = jobList?.map((data) => {
    if (!Id) {
      // Redirect to home page
      route('/');
      return null;
    }
    if (data._id === Id) {
      return (
        <Card className="job-detail overflow-hidden">
          <div className="p-4 ">
            <Row className="align-items-center">
              <Col md={2}>
                <div
                  id="jobDetailsCompanyLogo"
                  className="text-center mb-4 mb-md-0"
                >
                  {data && data?.companyId?.logo ? (
                    <div className="avatar-container">
                      <img
                        className="avatar-logo rounded-3"
                        src={`${baseURL}/${data?.companyId?.logo?.file}`}
                        alt="Company Logo"
                      />
                    </div>
                  ) : (
                    <div className="avatar-container">
                      <FontAwesomeIcon
                        icon={faLandmark}
                        className="avatar-logo"
                        color='#373840'
                        style={{ width: '80px', height: '80px' }}
                      />
                    </div>
                  )}
                </div>
              </Col>

              <Col md={10} className="text-center">
                <div className="mb-2 mb-md-0">
                  <h3 id="jobDetailsJob" className="mb-1">
                    {data?.jobTitle &&
                      data.jobTitle.split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                    }
                  </h3>
                  <ul className="list-inline text-muted mb-0">
                    <li id="jobDetailsCompanyName" className="list-inline-item">
                      {data.companyId
                        ? data.companyId.basicInfo?.companyName
                        : "N/A"}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>

          <CardBody className="p-4 pt-0">
            <div className="mt-4 text-center">
              <Row className="g-2">
                {data.jobType && (
                  <Col lg={calculateColSize(data)}>
                    <div className="p-3 job-box card">
                      <p className="text-muted fs-13 mb-0">Job Type</p>
                      <p className="fw-medium mb-0">{data.jobType}</p>
                    </div>
                  </Col>
                )}
                {data.gender && (
                  <Col lg={calculateColSize(data)}>
                    <div className="p-3 job-box card">
                      <p className="text-muted fs-13 mb-0">Gender</p>
                      <p className="fw-medium mb-0">{data.gender}</p>
                    </div>
                  </Col>
                )}
                {data.salaryType && (
                  <Col lg={calculateColSize(data)}>
                    <div className="p-3 job-box card">
                      <p className="text-muted fs-13 mb-0">Salary Type</p>
                      <p className="fw-medium mb-0">{data.salaryType}</p>
                    </div>
                  </Col>
                )}
                {data.salary && (
                  <Col lg={calculateColSize(data)}>
                    <div className="p-3 job-box card">
                      <p className="text-muted fs-13 mb-0">Offer Salary</p>
                      <p className="fw-medium mb-0">{data.salary}</p>
                    </div>
                  </Col>
                )}
              </Row>
            </div>

            {data?.description && (
              <div className="mt-4">
                <h5 className="mb-3">Job Description</h5>
                <div className="job-detail-desc">
                  <div dangerouslySetInnerHTML={{ __html: data?.description }} />
                </div>
              </div>
            )}

            {data?.qualification && (
              <div className="mt-4">
                <h5 className="mb-3">Qualification</h5>
                <div className="job-detail-desc mt-2">
                  <ul className="job-detail-list list-unstyled mb-0 text-muted">
                    <li>
                      <i className="uil uil-circle"></i>
                      {data?.qualification}
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {data?.jobCategory && data.jobCategory.length > 0 && (
              <div className="mt-4">
                <h5 className="mb-3">Job Categories</h5>
                <div className="job-detail-desc mt-2">
                  <ul className="job-detail-list list-unstyled mb-0 text-muted">
                    {data.jobCategory.map((category, index) => (
                      <li key={index}>
                        <i className="uil uil-circle"></i>
                        {category?.category}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {data?.skills && (
              <div className="mt-4">
                <h5 className="mb-3">Required Skills</h5>
                <div className="job-detail-desc mt-2">
                  <ul className="job-detail-list list-unstyled mb-0 text-muted">
                    <li>
                      <i className="uil uil-circle"></i>
                      {data?.skills}
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {data?.jobLocations && data.jobLocations.length > 0 && (
              <div className="mt-4">
                <h5 className="mb-3">Location</h5>
                <div className="job-detail-desc mt-2">
                  <ul className="job-detail-list list-unstyled mb-0 text-muted">
                    {data.jobLocations.map((location, index) => (
                      <li key={index}>
                        <i className="uil uil-circle"></i>
                        {location?.location}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            <div className="mt-4 pt-3 mx-2">
              <ul className="list-inline mb-0">
                <h5 className="list-inline-item mt-1">Share this job:</h5>
                <div>
                  <li className="list-inline-item mt-1">
                    <Link
                      to=
                      {data.companyId?.basicInfo?.links?.website || ""}
                      className="btn btn-outline-danger btn-hover"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FcGoogle className="mb-1 mx-1" />
                      Website
                    </Link>
                  </li>
                  <li className="list-inline-item mt-1">
                    <Link
                      to={
                        data.companyId
                          ? data.companyId?.basicInfo?.links?.facebookLink
                          : ""
                      }
                      className="btn btn-outline-primary btn-hover"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="mb-1 mx-1" />
                      Facebook
                    </Link>
                  </li>
                  <li className="list-inline-item mt-1">
                    <Link
                      to={
                        data.companyId
                          ? data.companyId?.basicInfo?.links?.linkedinLink
                          : ""
                      }
                      className="btn btn-outline-info btn-hover"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin className="mb-1 mx-1" />
                      LinkedIn
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
          </CardBody>
        </Card>
      );
    } else {
      return <></>;
    }
  });

  return <React.Fragment>{jobDescriptionView}</React.Fragment>;
};

export default JobDetailsDescription;
