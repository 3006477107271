import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useJob } from "../../../hooks/useJob";
import { baseURL } from "../../../repositories/Repository";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLandmark } from '@fortawesome/free-solid-svg-icons';
import Pagination from "../JobList2/Pagination";
import { useSelector } from "react-redux";
import { JobApply } from "../../../components/shared/JobApply";
import { toast } from "react-toastify";
import { IoLocationOutline } from "react-icons/io5";
import { MdAccountBalanceWallet } from "react-icons/md";
import { useProfile } from "../../../hooks/useProfile";

const JobVacancy = ({
  selectedOptions,
  selectedTitles,
  selectedCountry,
  selectedJobType }) => {

  //Apply Now Model
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const route = useNavigate();
  const { showprofile, profile } = useProfile();
  const [profileStatus, setProfileStatus] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 10;
  const { getalljobs, jobList } = useJob();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getalljobs().then(() => {
      setLoading(false); // Set loading to false when data is fetched
    });
    showprofile().then(() => {
      setLoading(false); // Set loading to false when data is fetched
    });
  }, [])

  //to cheak login for job applay
  useEffect(() => {
    if (profile && profile.role === 'student') {
      setProfileStatus(profile.role);
    } else if ((profile && profile.role === 'campany')) {
      setProfileStatus(profile.role);
    }
    else {
      setProfileStatus(profile);
    }
  }, [profile]);

  const filteredJobs = (
    (selectedOptions && selectedOptions.length > 0) ||
    (selectedTitles && selectedTitles.length > 0) ||
    (selectedCountry && selectedCountry.length > 0) ||
    (selectedJobType && selectedJobType.length > 0)
  )
    ? jobList.filter((job) => {
      const categoryFilter =
        selectedOptions && selectedOptions.length > 0
          ? selectedOptions.some((options) =>
            job.jobCategory.some(
              (categoryObj) => categoryObj.category === options.value
            )
          )
          : true;
      // Title filter logic
      const titleFilter = job.jobTitle?.toLowerCase().includes(selectedTitles?.toLowerCase()) ||
        (job.companyId && job.companyId.basicInfo.companyName?.toLowerCase().includes(selectedTitles?.toLowerCase()));
      // Country filter logic
      const countryFilter =
        selectedCountry
          ? job.jobLocations.some(
            (location) => location.location === selectedCountry
          )
          : true;
      // jobType filter logic
      const jobTypeFilter =
        selectedJobType
          ? job.jobType === selectedJobType
          : true;
      // Return true if both category and title filters pass
      return categoryFilter && titleFilter && countryFilter && jobTypeFilter;
    })
    : jobList;

  // Convert jobList object into an array
  const jobArray = Array.isArray(filteredJobs) ? Object.values(filteredJobs) : [];
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobArray.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openApplyModal = (jobId) => {
    if (isAuthenticated) {
      setSelectedJobId(jobId);
      setApplyModalOpen(true);
    } else {
      // Store the job details in session storage before redirecting
      sessionStorage.setItem('redirectToJobId', jobId);
      toast.warning('You need to login to apply for this job.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // Delayed redirection to the sign-in page after 2 seconds (adjust the time as needed)
      setTimeout(() => {
        route('/signin');
      }, 2000);
    }
  };

  const closeApplyModal = () => {
    setSelectedJobId(null); // Reset the selected job ID when the modal is closed
    setApplyModalOpen(false);
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="text-center mt-5">
          <Button
            color="primary"
            disabled
          >
            <Spinner size="sm">
              Loading...
            </Spinner>
            <span>
              {' '}Loading...
            </span>
          </Button>
        </div>
      ) : (
        <Row className="mt-4">
          {currentJobs.length === 0 ? (
            <h5 className="text-center font-light">No jobs found</h5>
          ) : (
            currentJobs.map((jobVacancyDetails, index) => (
              <Col key={index} lg={6}>
                <div
                  className={
                    jobVacancyDetails.addclassNameBookmark === true
                      ? "job-box bookmark-post card mt-4"
                      : "job-box card mt-4"
                  }
                >
                  <div className="p-4">
                    <Row>
                      <Col lg={2} id="jobGridCompanyLogo" className="text-center">
                        <Link to={`/companydetails?id=${jobVacancyDetails._id}&companyId=${jobVacancyDetails?.companyId?._id}`}>
                          {jobVacancyDetails?.companyId?.logo ? (
                            <div className='avatar-container'>
                              <img className='avatar-logo rounded-3' src={`${baseURL}/${jobVacancyDetails?.companyId?.logo?.file}`} alt="Company Logo" />
                            </div>
                          ) : (
                            <div className='avatar-container'>
                              <FontAwesomeIcon icon={faLandmark}
                                className='avatar-logo'
                                color='#373840'
                                style={{ width: '80px', height: '80px' }} />
                            </div>
                          )}
                        </Link>
                      </Col>
                      <Col lg={10}>
                        <div className="mt-3 mt-lg-0">
                          <h5 id="jobGridJobs" className="fs-17 mb-1 text-center">
                            <Link to={`/jobdetails?id=${jobVacancyDetails._id}`} className="text-dark">
                              {jobVacancyDetails.jobTitle && jobVacancyDetails.jobTitle.split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')}
                            </Link>{" "}
                          </h5>
                          <ul className="list-inline mb-0 text-center">
                            <li className="list-inline-item">
                              <p id="jobGridCompanyName" className="text-muted fs-14 mb-0">
                                {jobVacancyDetails.companyId ? jobVacancyDetails.companyId.basicInfo.companyName : 'N/A'}
                              </p>
                            </li>
                            <li className="list-inline-item">
                              <p className="text-muted fs-14 mb-0">
                                {/* <i className="mdi mdi-map-marker"></i>{" "} */}
                                <IoLocationOutline className="mb-1 text-primary" />
                                {jobVacancyDetails.jobLocations.map(location => location.location).join(', ')}
                              </p>
                            </li>
                            <li className="list-inline-item">
                              <p className="text-muted fs-14 mb-0">
                                {/* <i className="uil uil-wallet"></i>{" "} */}
                                <MdAccountBalanceWallet className="mb-1 text-success" />
                                {jobVacancyDetails.salary}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                    {/* <div className="favorite-icon">
                    <Link to="#">
                      <i className="uil uil-heart-alt fs-18"></i>
                    </Link>
                  </div> */}
                  </div>
                  <div className="p-3 bg-light">
                    <Row className="justify-content-between">
                      <Col md={8}>
                        <div>
                          <ul className="list-inline mb-0 text-center">
                            <li className="list-inline-item">
                              <i className="uil uil-tag"></i> Job Type :
                            </li>
                            <li className="list-inline-item">
                              <Link to="#" className="primary-link text-muted">
                                {jobVacancyDetails.jobType}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Col>
                      {profileStatus === 'student' && (
                        <Col md={4}>
                          <div className="text-md-end text-center">
                            <Link to="#"
                              onClick={() => openApplyModal(jobVacancyDetails._id)}
                              className="primary-link"
                            >
                              Apply Now <i className="mdi mdi-chevron-double-right"></i>
                            </Link>
                          </div>
                        </Col>
                      )}  {
                        profileStatus === null && (
                          <Col md={4}>
                            <div className="text-md-end text-center">
                              <Link to="#"
                                onClick={() => openApplyModal(jobVacancyDetails._id)}
                                className="primary-link"
                              >
                                Apply Now <i className="mdi mdi-chevron-double-right"></i>
                              </Link>
                            </div>
                          </Col>
                        )
                      }
                    </Row>
                  </div>
                </div>
              </Col>
            )
            ))}
        </Row>
      )}
      <div className="justify-content-center">
        <Pagination
          jobsPerPage={jobsPerPage}
          totalJobs={jobArray.length}
          currentPage={currentPage}
          paginate={paginate}
        />
      </div>

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >

        <div className="modal-dialog modal-dialog-centered">
          {/* Pass the selectedJobId to the JobApply component */}
          <JobApply
            isOpen={applyModalOpen}
            toggleModal={closeApplyModal}
            jobId={selectedJobId}
          />
        </div>

      </div>
    </React.Fragment>
  );
};

export default JobVacancy;
