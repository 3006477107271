import { useState } from "react"
import { toast } from 'react-toastify'
import ProfileRepository from "../repositories/ProfileRepository"
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';

export const useProfile = () => {
    const [profileInfo, setProfileInfo] = useState(null)
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState(null)
    const route = useNavigate();

    const customToastStyle = {
        background: '#333',
        color: '#fff',
        borderRadius: '4px',
    };

    return {
        loading,
        profileInfo,
        customToastStyle,
        profile,
        // Candidate info
        reqCandidateInfo: async (payload) => {
            setLoading(true)
            const response = await ProfileRepository.getCandidateProfileInfo(payload)
            if (response) {
                if (response.status == 200) {
                    setProfileInfo(response.data)
                    setTimeout(
                        function () {
                            setLoading(false);
                        }.bind(this),
                        250
                    );
                }
            }
        },
        updateCandidateInfo: async (payload) => {
            setLoading(true)
            const response = await ProfileRepository.updateCandidateProfileInfo(payload)
            if (response) {
                if (response.status == 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    route('/candidatedashboard')
                }
                setLoading(false)
            }
        },
        updateCompanyInfo: async (payload) => {
            setLoading(true)
            const response = await ProfileRepository.updateCompanyProfileInfo(payload)

            if (response) {
                if (response.status == 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },
        reqCompanyInfo: async (payload) => {
            setLoading(true)
            const response = await ProfileRepository.getCompanyProfileInfo(payload)

            if (response) {
                if (response.status == 200) {
                    setProfileInfo(response.data)
                    setTimeout(
                        function () {
                            setLoading(false);
                        }.bind(this),
                        250
                    );
                }
            }
        },
        //for home page profile information 
        showprofile: async (payload) => {
            setLoading(true);
            try {
                const responseCandidate = await ProfileRepository.getCandidateProfileInfo(payload);
                const responseCompany = await ProfileRepository.getCompanyProfileInfo(payload);

                if (responseCandidate && responseCandidate.status === 200 && responseCandidate.data !== null) {
                    setProfile(responseCandidate.data);
                } else if (responseCompany && responseCompany.status === 200 && responseCompany.data !== null) {
                    setProfile(responseCompany.data);
                }

                setTimeout(() => {
                    setLoading(false);
                }, 250);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        },
    }
}
