import React, { useEffect, useState } from 'react'
import { Sidebar } from '../layout/AdminLayout/Sidebar'
import {
  Container,
  Card,
  Row,
  Col,
  CardBody,
  Table,
} from "reactstrap"; 
import { useJob } from '../../../hooks/useJob';
import Pagination from '../../Jobs/JobList2/Pagination';
import { addHours, addDays, addWeeks, addMonths, addYears, isWithinInterval } from 'date-fns';

const ApplicationReceivedList = () => {

  const [applicationPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const { getjobApplication, applyjob } = useJob();
  const [selectedDateFilter, setSelectedDateFilter] = useState("all");
  const [jobsToDisplay, setJobsToDisplay] = useState([]);
  const [searchTerm, setSearchTerm] = useState("all");

  useEffect(() => {
    getjobApplication();
    setJobsToDisplay(Array.isArray(applyjob) ? applyjob : []);
  }, [applyjob]);

  const indexOfLastJob = currentPage * applicationPerPage;
  const indexOfFirstJob = indexOfLastJob - applicationPerPage;
  const currentCandidate = jobsToDisplay
    .filter((job) => {
      if (searchTerm === "all") {
        return true; // Display all data when "All" is selected
      }

      const searchTermsArray = searchTerm.toLowerCase().split(' ');

      return searchTermsArray.every((term) => {
        return job.jobStatus.toLowerCase().includes(term);
      }) && job.jobStatus.toLowerCase() === searchTerm.toLowerCase();
    })
    .filter((job) => {
      const currentTime = new Date();
      const postedTime = new Date(job.appliedAt);

      switch (selectedDateFilter) {
        case "lastHour":
          const oneHourAgo = addHours(currentTime, -1);
          return isWithinInterval(postedTime, { start: oneHourAgo, end: currentTime });

        case "1day":
          const oneDayAgo = addDays(currentTime, -1);
          return isWithinInterval(postedTime, { start: oneDayAgo, end: currentTime });

        case "1week":
          const oneWeekAgo = addWeeks(currentTime, -1);
          return isWithinInterval(postedTime, { start: oneWeekAgo, end: currentTime });

        case "1month":
          const oneMonthAgo = addMonths(currentTime, -1);
          return isWithinInterval(postedTime, { start: oneMonthAgo, end: currentTime });

        case "1year":
          const oneYearAgo = addYears(currentTime, -1);
          return isWithinInterval(postedTime, { start: oneYearAgo, end: currentTime });

        default:
          return true;
      }
    });
  const candidatedata = currentCandidate.slice(indexOfFirstJob, indexOfLastJob);

  const capitalizeWords = (str) => {
    return str.split(/\s+/).map(word => {
      if (word.includes('-')) {
        return word.split('-').map(subWord => subWord.charAt(0).toUpperCase() + subWord.slice(1)).join('-');
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    }).join(' ');
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const formatDate = (dateform) => {
    const date = new Date(dateform);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  return (<>
    <div >
      <Sidebar />
    </div>
    <Container className='mt-5 mb-3 my-auto'>
      <Card className="side-border">
        <CardBody className='side-border'>
          <Row>

            <Col lg={6}>
              <h3 className="heading-small text-dark mb-4">
                Application List
              </h3>
            </Col>
            <Col lg={2} className='ml-1'>
              <div className="selection-widget mb-3">
                <label>Sort by Status</label>
                <select
                  name="choices-company-page"
                  id="choices-company-page"
                  aria-label="Default select example"
                  className="form-select"
                  data-trigger
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  style={{ height: '40px' }}
                >
                  <option value="all">All</option>
                  <option value="shortlisted">shortlisted</option>
                  <option value="in review">In Review</option>
                  <option value="not-shortlisted">Not-Shortlisted</option>

                </select>
              </div>
            </Col>
            <Col lg={2} className='ml-1'>
              <div className="selection-widget mb-3">
                <label>Sort by Days</label>
                <select
                  name="choices-company-page"
                  id="choices-company-page"
                  aria-label="Default select example"
                  className="form-select"
                  data-trigger
                  onChange={(e) => setSelectedDateFilter(e.target.value)}
                  value={selectedDateFilter}
                  style={{ height: '40px' }}
                >
                  <option value="all">All</option>
                  <option value="lastHour">Last 1 hour</option>
                  <option value="1day">Last 1 day</option>
                  <option value="1week">Last 1 week</option>
                  <option value="1month">Last 1 month</option>
                  <option value="1year">Last 1 year</option>
                </select>
              </div>
            </Col>
          </Row>
          {candidatedata?.length > 0 ? (
            <Table responsive className='table custom-table table-bordered table-hover'>
              <thead className="thead-dark">
                <tr className='bordered text-nowrap'>
                  <th>Sr No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>Cover Letter</th>
                  <th>Apply Date</th>
                  <th>Job Status</th>
                </tr>
              </thead>
              <tbody className='text-nowrap'>
                {candidatedata.map((data, index) => {
                  const getStatusColor = (status) => {
                    switch (status) {
                      //  statuses with their corresponding colors
                      case 'shortlisted':
                        return 'green';
                      case 'not-shortlisted':
                        return 'red';

                      case 'in review':
                        return 'blue';
                      default:
                        return 'black'; // Default color
                    }
                  };

                  return (
                    <tr key={data._id}>
                      <td>{(currentPage - 1) * applicationPerPage + index + 1}</td>
                      <td>{data.candidateId?.firstName} {data.candidateId?.lastName}</td>
                      <td>{data.candidateId?.email}</td>
                      <td>{data.phoneNumber}</td>
                      <td>{data.coveringLatter}</td>
                      <td>{formatDate(data.appliedAt)}</td>
                      <td style={{ color: getStatusColor(data.jobStatus), fontWeight: 'bold' }}>  {capitalizeWords(data.jobStatus)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div className="text-center mt-4 pt-2">
              <h5>No jobs available</h5>
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
    <Pagination
      jobsPerPage={applicationPerPage}
      totalJobs={currentCandidate.length}
      currentPage={currentPage}
      paginate={paginate}
    />
  </>
  );
};

export default ApplicationReceivedList;