import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useAuth } from "../../../hooks/useAuth";
import { BasicFieldsofStudent } from "./RegistrationModule/BasicFieldsofStudent";
import { AuthFields } from "./AuthFields";
import { useEffect } from "react";

const StudentRegistration = () => {
  document.title = "Sign Up | Fresherszone";

  const [isOtpExp, setIsOtpExp] = useState(true);

  const {
    loading,
    isOtpSent,
    isOtpReSent,
    isOtpVerified,
    sendUserOtp,
    reSendUserOtp,
    verifyUserOtp,
    studentRegistration,
  } = useAuth();

  const [formData, setFormData] = useState({
    email: "",
    otp: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    aadharNumber: "",
    role: "student",
    agree: false,
  });

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    studentRegistration(formData);
  };

  const handleOnSendOtpReq = (e) => {
    sendUserOtp({
      email: formData.email,
      role: formData.role,
    });
  };

  const handleOnReSendOtpReq = (e) => {
    reSendUserOtp({
      email: formData.email,
      role: formData.role,
    });
    setFormData((prev) => ({ ...prev, otp: "" }))
  };

  const handleOnVerifyOtpReq = (e) => {
    verifyUserOtp({
      email: formData.email,
      otp: formData.otp,
      role: formData.role,
    });
  };

  const basicProps = {
    loading,
    formData,
    handleOnChange,
  };

  const authProps = {
    loading,
    formData,
    isOtpSent,
    isOtpExp,
    handleOnChange,
    handleOnReSendOtpReq,
    handleOnSendOtpReq,
    handleOnVerifyOtpReq,
  };

  useEffect(() => {
    setIsOtpExp(false);
  }, [isOtpReSent]);

  return (
    <>
      <Form onSubmit={handleOnSubmit} className="auth-form">
        {isOtpVerified ? (
          <BasicFieldsofStudent {...basicProps} />
        ) : (
          <AuthFields {...authProps} />
        )}
      </Form>
    </>
  );
};

export default StudentRegistration;
