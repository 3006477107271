import React, { useEffect, useState } from "react";
import '../assets/css/Dashboard.css';
import { Sidebar } from "../layout/AdminLayout/Sidebar";
import { useJob } from "../../../hooks/useJob";
import { Link } from "react-router-dom";
import LineChart from "./LineChart";
import { Col, Row } from "reactstrap";

export const Dashboard = () => {

  const { getjoblist, getjobApplication, applyjob, companyjobList } = useJob();
  const [count, setCount] = useState(0);
  const [companyId, setCompanyId] = useState();
  const [shortlistcandidatecount, setShortlistcandidatecount] = useState([]);
  const [onboardcandidatecount, setOnboardCandidatecount] = useState([]);
  const [interviewcandidatecount, setInterviewCandidatecount] = useState([]);
  const [jobsToDisplay, setJobsToDisplay] = useState([]);

  useEffect(() => {
    getjobApplication();
    getjoblist();
  }, []);

  useEffect(() => {
    if (Array.isArray(applyjob)) {
      const shortlistcandidate = applyjob.filter(application => application?.jobStatus === "shortlisted" && application?.jobId !== null);
      const onboardcandidate = applyjob.filter(application => application?.jobStatus === "onboard" && application?.jobId !== null);
      const interviewcandidate = applyjob
        .filter(application => application?.jobStatus === "interview" && application?.jobId !== null)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).slice(0, 5);
      setShortlistcandidatecount(shortlistcandidate.length);
      setOnboardCandidatecount(onboardcandidate.length);
      setInterviewCandidatecount(interviewcandidate);
    }
    if (Array.isArray(companyjobList)) {
      setCount(companyjobList.length);
      setCompanyId(companyjobList[0].companyId);
    }
  }, [applyjob, companyjobList]);

  useEffect(() => {
    setJobsToDisplay(Array.isArray(companyjobList) ? companyjobList : []);
  }, [companyjobList,]);
  const reversedJobs = [...jobsToDisplay].reverse();
  const countJobApplications = (jobId) => {
    return applyjob
      ? applyjob?.filter((application) => application?.jobId?._id === jobId && application?.candidateId).length || 0
      : 0;
  };

  return (
    <>
      <div className='mt-3'>
        <Sidebar />
      </div>
      {/* <!-- Begin Page Content --> */}
      <div className="container-fluid mt-3">
        {/*  <!-- Page Heading --> */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800 ml-5">Dashboard</h1>
          {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
            className="fas fa-download fa-sm text-white-50"></i> Generate Report</a> */}
        </div>

        {/*  <!-- Content Row --> */}
        <div className="row">

          {/*  <!-- Earnings (Monthly) Card Example --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <Link to={`/myjobs`}>
                      <div className="text-xs fs-6 font-weight-bold text-primary text-uppercase mb-1">
                        Posted Jobs</div>
                    </Link>
                    <div className="h5 mb-0 font-weight-bold text-gray-800"> {count !== null ? count : 0}</div>
                  </div>
                  <div className="col-auto">
                    <div class="icon icon-shape bg-gradient-primary shadow text-white p-2 rounded-circle">
                      <i className="fas fa-calendar fa-2x text-lg"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  <!-- Earnings (Monthly) Card Example --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <Link to={'/receivedapplication'}>
                      <div className="text-xs fs-6 font-weight-bold text-success text-uppercase mb-1">
                        Application Received</div>
                    </Link>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {/* {applyjob?.filter(application =>
                        application?.candidateId &&
                        !application.candidateId.deleted &&
                        application?.jobId &&
                        !application.jobId.removed
                      ).length} */}
                      {applyjob?.length}
                    </div>
                    {/* <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboardData.review}</div> */}
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-gradient-success shadow text-white p-2 rounded-circle">
                      <i class="fas fa-comments fa-2x text-lg"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  <!-- Earnings (Monthly) Card Example --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-info shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <Link to={`/shortlisted`}>
                      <div className="text-xs fs-6 font-weight-bold text-info text-uppercase mb-1">
                        Shortlisted Candidate
                      </div>
                    </Link>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{shortlistcandidatecount !== null ? shortlistcandidatecount : 0}</div>
                  </div>
                  <div className="col-auto">
                    <div class="icon icon-shape bg-gradient-info shadow text-white p-2 rounded-circle">
                      <i className="fas  fa-check-double fa-2x  text-lg "></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  <!-- Pending Requests Card Example --> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold fs-6 text-warning text-uppercase mb-1">
                      Onboard</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{onboardcandidatecount !== null ? onboardcandidatecount : 0}</div>
                  </div>
                  <div className="col-auto">
                    <div class="icon icon-shape bg-gradient-warning  shadow text-white p-2 rounded-circle">
                      <i className="fas fa-user-friends fa-2x  text-lg"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  <!-- Content Row --> */}
        <div className="row justify-content-left mb-2 mr-1">
          <div className="col-xl-6 col-md-6 mb-4">
            <div className="card shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <LineChart companyId={companyId} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="">
                    <Row className="align-items-center">
                      <div className="text-center mb-2 mb-md-0">
                        <h5 className="text-center">
                          Jobs & Application Count
                        </h5>
                        <hr></hr>
                      </div>
                    </Row>
                  </div>
                  {reversedJobs && reversedJobs.length > 0 ? (
                    reversedJobs.slice(0, 5).map((jobslist) => (
                      <div
                        key={jobslist._id}
                        className={
                          jobslist.addclassNameBookmark === true
                            ? "job-box bookmark-post card mt-3"
                            : "job-box card mt-3"
                        }
                      >
                        <div className="mt-1 p-3 ml-2 mr-2">
                          <Row className="align-items-center">
                            <Col md={6}>
                              <div className="text-center mb-2 mb-md-0">
                                <h5 className="fs-16 mb-1">
                                  <Link
                                    id="jobslist"
                                    to={`/jobdetails?id=${jobslist._id}`}
                                    className="text-dark"
                                  >
                                    {jobslist.jobTitle &&
                                      jobslist.jobTitle
                                        .split(' ')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ')
                                    }
                                  </Link>
                                </h5>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="text-center mb-2 mb-md-0">
                                <h5 className="fs-16 mb-1">
                                  {countJobApplications(jobslist._id)}
                                </h5>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center mt-4 pt-2 ">
                      <h5>No Jobs Available</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <Row className="align-items-center">
                    <div className="text-center mb-2 mb-md-0">
                      <h5 className="text-center">
                        Interview Candidate List
                      </h5>
                      <hr></hr>
                    </div>
                  </Row>
                  {interviewcandidatecount.map((data, index) => (
                    <div
                      key={data._id}
                      className={
                        data.addclassNameBookmark === true
                          ? "job-box bookmark-post card mt-2"
                          : "job-box card mt-3"
                      }
                    >
                      <div className="p-3">
                        <Row className="align-items-center">
                          <Col md={6}>
                            <div className="text-center mb-2 mb-md-0">
                              <h5 className="mb-1 fs-16">
                                <Link
                                  id="data"
                                  to={`/jobdetails?id=${data._id}`}
                                  className="text-dark"
                                >
                                  {`${data.candidateId?.firstName} ${data.candidateId?.lastName}`}
                                </Link>
                              </h5>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className=" mb-2 mb-md-0 fs-13">
                              {data.candidateId?.email}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}