
import React, { useEffect, useState } from "react";
import Chart from 'chart.js/auto';
import { useJob } from "../../../hooks/useJob";

const LineChart = ({ companyId }) => {
  const { getmonthwisejobcount, monthwisecount } = useJob();
  const [monthsData, setMonthsData] = useState([]);

  useEffect(() => {
    getmonthwisejobcount();
  }, []);

  //condition for companyid or months
  useEffect(() => {
    if (Array.isArray(monthwisecount) && monthwisecount.length > 0) {
      const companyData = monthwisecount.find(data => data._id === companyId);
      if (companyData && companyData?.months) {
        setMonthsData(companyData.months);
      }
    }
  }, [monthwisecount, companyId]);

  useEffect(() => {
    if (monthsData.length > 0 && document.getElementById("myChart")) {
      const allMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      const applicationCounts = allMonths.map(month => {
        const record = monthsData.find(data => data.month === month);
        return record ? record.appliedCount : 0;
      });

      const shortlistedCounts = allMonths.map(month => {
        const record = monthsData.find(data => data.month === month);
        return record ? record.shortlistedCount : 0;
      });
      const screenWidth = window.innerWidth;

      let fontSize;

      if (screenWidth > 1200) {
        fontSize = 16;
      } else if (screenWidth > 992) {
        fontSize = 14;
      } else if (screenWidth > 768) {
        fontSize = 12;
      } else if (screenWidth >= 600) {
        fontSize = 10;
      } else {
        fontSize = 8;
      }

      const headingElement = document.getElementById('applicationChartHeading');
      if (headingElement) {
        headingElement.style.fontSize = "${fontSize}px";
      }

      const chartHeight = screenWidth > 991 && screenWidth <= 1550 ? 450 : 450;

      let ctx = document.getElementById("myChart").getContext("2d");
      let myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: allMonths,
          datasets: [
            {
              label: "Application",
              data: applicationCounts,
              borderColor: "#0ecf14",
              backgroundColor: "rgba(14, 207, 20, 0.2)",
              tension: 0.4,
              pointRadius: 5,
            },
            {
              label: "Shortlisted",
              data: shortlistedCounts,
              borderColor: "#00d5f9",
              backgroundColor: "rgba(0, 213, 249, 0.2)",
              tension: 0.4,
              pointRadius: 5,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },

          plugins: {
            legend: {
              labels: {
                font: {
                  size: fontSize,
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
       // Set canvas height using CSS
    const canvas = document.getElementById("myChart");
    if (chartHeight && canvas) {
      canvas.style.height = chartHeight + "px";
    }

      return () => {
        myChart.destroy();
        if (canvas) {
          canvas.style.height = "auto";
        }
      };
    }
  }, [monthsData]);

  return (
    <div className="container">
     <h5 >Application Chart</h5>
      <div>
        <canvas id="myChart"></canvas>
      </div>
    </div>
  );
};

export default LineChart;