import React, { useEffect, useState } from "react";
import { useJob } from "../../../hooks/useJob";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function EducationalDetailsShow() {
  const { educationaInfo, reqEducationalInfo } = useJob();
  const route = useNavigate();
  const [educationdata, seteducationdata] = useState({
    secondaryEducation: [
      {
        schoolName: "",
        boardUniversity: "",
        coursename: "",
        startMonthYear: "",
        endMonthYear: "",
        gradeType: "",
        obtainedMarks: "",
        totalMarks: "",
        percentage: "",
        cgpa: "",
      },
    ],
    higherEducation: [
      {
        collegeName: "",
        university: "",
        startDate: "",
        endDate: "",
        gradeTypes: "",
        obtainedMarks: "",
        totalMarks: "",
        percentage: "",
        cgpa: "",
      },
    ],
  });

  useEffect(() => {
    reqEducationalInfo();
  }, []);

  useEffect(() => {
    if (educationaInfo) {
      seteducationdata({
        secondaryEducation:
          educationaInfo?.[0]?.secondaryEducation.map((education) => ({
            schoolName: education?.schoolName || "",
            boardUniversity: education?.boardUniversity || "",
            startMonthYear: education?.startMonthYear || "",
            endMonthYear: education?.endMonthYear || "",
            gradeType: education?.gradeType || "",
            obtainedMarks: education?.obtainedMarks || "",
            totalMarks: education?.totalMarks || "",
            percentage: education?.percentage || "",
            cgpa: education?.cgpa || "",
          })) || [],
        higherEducation:
          educationaInfo?.[0]?.higherEducation.map((education) => ({
            collegeName: education?.collegeName || "",
            university: education?.university || "",
            coursename: education?.coursename || "",
            startDate: education?.startDate || "",
            endDate: education?.endDate || "",
            gradeTypes: education?.gradeTypes || "",
            obtainedMarks: education?.obtainedMarks || "",
            totalMarks: education?.totalMarks || "",
            percentage: education?.percentage || "",
            cgpa: education?.cgpa || "",
          })) || [],
      });
    }
  }, [educationaInfo]);

  const handleEditClick = () => {
    if (educationaInfo && educationaInfo.length > 0) {
      const studentId = educationaInfo?.[0]?.studentId;
      route(`/Education?id=${studentId}`);
    } else {
      console.log("Educational details are not available...");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <div className="">
        <div className="card">
          <div className="card-header text-dark">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="text-2xl font-bold text-nowrap">
                Educational Details
              </h3>
              <FaEdit
                className="text-lg mb-2"
                onClick={handleEditClick}
                style={{ cursor: "pointer", color: "black" }}
              />
            </div>
          </div>
          <div className="card-body">
            <ul
              className="nav nav-pills nav-justified justify-content-center text-nowrap btn-sm"
              id="educationTabs"
              role="tablist"
            >
              <li className="mr-1 text-yellow-400" role="presentation">
                <a
                  href="#secondary-education"
                  className="btn btn-outline-primary btn-hover active"
                  id="secondary-tab"
                  data-bs-toggle="tab"
                  role="tab"
                  aria-controls="secondary-education"
                  aria-selected="true"
                >
                  Secondary Education
                </a>
              </li>
              <li className="ml-1" role="presentation">
                <a
                  href="#higher-education"
                  className="btn btn-outline-primary btn-hover"
                  id="higher-tab"
                  data-bs-toggle="tab"
                  role="tab"
                  aria-controls="higher-education"
                  aria-selected="false"
                >
                  Higher Education
                </a>
              </li>
            </ul>
            <div className="tab-content mt-3" id="educationTabsContent">
              {/* Secondary Education Tab */}
              <div
                className="tab-pane fade show active"
                id="secondary-education"
                role="tabpanel"
                aria-labelledby="secondary-tab"
              >
                {educationdata.secondaryEducation.map((education, index) => (
                  <div key={index} className="card mb-3">
                    <div className="card-body text-center">
                      <h5 className="card-title font-weight-bold">
                        {education.schoolName}
                      </h5>
                      {/* <p className="card-text">
                        Board :- {education.boardUniversity}
                      </p>
                      <p className="card-text">
                        Start Date :- {formatDate(education.startMonthYear)}
                      </p>
                      <p className="card-text">
                        End Date :- {formatDate(education.endMonthYear)}
                      </p> */}
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <strong>Board</strong>
                            </th>
                            <th>{education.boardUniversity}</th>
                          </tr>
                          <tr>
                            <th scope="col">
                              <strong>Start Date</strong>
                            </th>
                            <th>{formatDate(education.startMonthYear)}</th>
                          </tr>
                          <tr>
                            <th scope="col">
                              <strong>End Date</strong>
                            </th>
                            <th>{formatDate(education.endMonthYear)}</th>
                          </tr>
                        </thead>
                      </table>
                      <strong>
                        <p className="card-text">
                          {education.gradeType} :-{" "}
                          {education.gradeType === "CGPA" && education.cgpa && (
                            <>{education.cgpa}</>
                          )}
                          {education.gradeType === "Percentage" &&
                            education.percentage && (
                              <>{education.percentage}%</>
                            )}
                        </p>
                      </strong>
                    </div>
                  </div>
                ))}
              </div>

              {/* Higher Education Tab */}
              <div
                className="tab-pane fade"
                id="higher-education"
                role="tabpanel"
                aria-labelledby="higher-tab"
              >
                {educationdata.higherEducation.map((education, index) => (
                  <div key={index} className="card mb-3">
                    <div className="card-body text-center">
                      <h5 className="card-title font-weight-bold">
                        {education.collegeName}
                      </h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <strong>Board</strong>
                            </th>
                            <th>{education.university}</th>
                          </tr>
                          <tr>
                            <th scope="col">
                              <strong>Course</strong>
                            </th>
                            <th>{education.coursename}</th>
                          </tr>
                          <tr>
                            <th scope="col">
                              <strong>Start Date</strong>
                            </th>
                            <th>{formatDate(education.startDate)}</th>
                          </tr>
                          <tr>
                            <th scope="col">
                              <strong>End Date</strong>
                            </th>
                            <th>{formatDate(education.endDate)}</th>
                          </tr>
                        </thead>
                      </table>
                      <strong>
                        <p className="card-text">
                          {education.gradeTypes} :-{" "}
                          {education.gradeTypes === "CGPA" &&
                            education.cgpa && <>{education.cgpa}</>}
                          {education.gradeTypes === "Percentage" &&
                            education.percentage && (
                              <>{education.percentage}%</>
                            )}
                        </p>
                      </strong>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EducationalDetailsShow;
