import React from 'react';
import { useLocation } from 'react-router-dom';
import Myjobs from './Myjobs';
import ApplicationViews from './ApplicationViews';

const View = () => {
  const location = useLocation();
  const jobId = new URLSearchParams(location.search).get("id");
  // console.log(jobId);
  if (jobId) {
    return <ApplicationViews jobId={jobId} />;
  } else {
    return <Myjobs />;
  }
};
export default View;