import React from "react";
import { Container } from "reactstrap";
import JobListing from "./JobListing";
import Section from "./Section";
import Selected from "./Selected";
import Subscribe from "../../../Layout/CommonLayout/Subscribe";
import Footer from "../../../Layout/CommonLayout/Footer";

const ManageJobs = () => {
  document.title = "Manage Jobs";
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Selected />
          <JobListing />
        </Container>
      </section>
      {/* <Subscribe /> */}
      <Footer />
    </React.Fragment>
  );
};

export default ManageJobs;
