import React, { useEffect, useState } from "react";
import { useProfile } from "../../../hooks/useProfile";
import Sidebar from "../layout/AdminLayout/Sidebar";
import { useJob } from "../../../hooks/useJob";
import EducationalDetailsShow from "./EducationalDetailsShow";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaInstagram, FaLinkedin, FaEdit } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import '../assets/css/Candidate.css';
import { baseURL } from "../../../repositories/Repository";
import { FaCheckCircle, FaTimesCircle, FaHourglass, FaPaperPlane } from 'react-icons/fa';
import CandidateDefaultIcon from '../../../assets/images/candidatedefaulticon.png'
import '../../../App.css'
import CandidateSkills from "./Candidateskills";
import { Col, Row } from "reactstrap";
export const Candidatedashboard = () => {

  const { reqCandidateInfo, profileInfo } = useProfile();
  const { reqEducationalInfo, educationaInfo } = useJob();
  const { appliedjobs, appliedjobslist } = useJob();
  const [showRecentlyAppliedJobs, setShowRecentlyAppliedJobs] = useState(false);
  const [showViewMoreButton, setShowViewMoreButton] = useState(false);
  const route = useNavigate();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    aadharnumber: "",
    address: "",
    mobileNumber: "",
    dateOfBirth: "",
    gender: "",
    website: "",
    instagram: "",
    facebook: "",
    linkedin: "",
    twitter: "",
  });

  useEffect(() => {
    reqCandidateInfo();
    reqEducationalInfo();
  }, []);

  useEffect(() => {
    setFormData({
      firstname: profileInfo?.firstName,
      lastname: profileInfo?.lastName,
      email: profileInfo?.email,
      aadharnumber: profileInfo?.aadharNumber,
      address: profileInfo?.address,
      mobileNumber: profileInfo?.mobileNumber,
      dateOfBirth: profileInfo?.dateOfBirth,
      gender: profileInfo?.gender,
      website: profileInfo?.links?.website,
      instagram: profileInfo?.links?.instagram,
      facebook: profileInfo?.links?.facebook,
      linkedin: profileInfo?.links?.linkedin,
      twitter: profileInfo?.links?.twitter,
    });
  }, [profileInfo]);

  //applied job list data
  useEffect(() => {
    appliedjobslist();
  }, []);
  const reversedJobs = Array.isArray(appliedjobs) ? appliedjobs.filter(job => job.jobId).reverse() : [];

  const handleSocialLinkClick = (url) => {
    window.open(url, '_blank');
  };
  const capitalizeWords = (str) => {
    return str.split(/\s+/).map(word => {
      if (word.includes('-')) {
        return word.split('-').map(subWord => subWord.charAt(0).toUpperCase() + subWord.slice(1)).join('-');
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    }).join(' ');
  };

  const candidatefrofile = profileInfo?.profile ? `${baseURL}/${profileInfo?.profile}` : null;
  const calculateProfileCompletion = () => {
    // Replace this with your actual logic
    const totalFields = 15 /* Total number of fields you are considering */;

    let filledFields = 1;
    // Check each field and increment the counter if it's filled or added
    if (formData.firstname) filledFields++;
    if (formData.lastname) filledFields++;
    if (formData.email) filledFields++;
    if (formData.aadharNumber) filledFields++;
    if (formData.address) filledFields++;
    if (formData.mobileNumber) filledFields++;
    if (formData.dateOfBirth) filledFields++;
    if (formData.gender) filledFields++;
    if (formData.website) filledFields++;
    if (formData.instagram) filledFields++;
    if (formData.facebook) filledFields++;
    if (formData.linkedin) filledFields++;
    if (formData.twitter) filledFields++;
    if (educationaInfo && educationaInfo[0]?.secondaryEducation) filledFields++;
    if (educationaInfo && educationaInfo[0]?.higherEducation) filledFields++;

    // Calculate the completion percentage
    return Number((filledFields / totalFields * 100).toFixed(0));

  };

  const handleEditClick = () => {
    if (educationaInfo && educationaInfo.length > 0) {
      route(`/candidateprofile`);
    } else {
      console.log("Educational details not available");
    }
  };

  const profileCompletionPercentage = calculateProfileCompletion();
  return (
    <>
      <div>
        <Sidebar />
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-3">
            <form>
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img
                      src={candidatefrofile || CandidateDefaultIcon}
                      alt=""
                      className="rounded-circle"
                      width="150"
                    />
                    <div className="mt-3">
                      <h4>
                        Hi,{formData.firstname} {formData.lastname}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <ul className="list-inline m-0">

                      {formData.website && (
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => handleSocialLinkClick(formData.website)}
                          >
                            <FcGoogle className="mb-1 mx-1" color="#1DA1F2" size={28} />
                          </Link>
                        </li>
                      )}

                      {formData.twitter && (
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => handleSocialLinkClick(formData.twitter)}
                          >
                            <FaXTwitter className="mb-1 mx-1" color="#3D454E" size={28} />
                          </Link>
                        </li>
                      )}

                      {formData.facebook && (
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => handleSocialLinkClick(formData.facebook)}
                          >
                            <FaFacebook className="mb-1 mx-1" color="#1877F2" size={28} />
                          </Link>
                        </li>
                      )}

                      {formData.instagram && (
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => handleSocialLinkClick(formData.instagram)}
                          >
                            <FaInstagram className="mb-1 mx-1" color="#E4405F" size={28} />
                          </Link>
                        </li>
                      )}

                      {formData.linkedin && (
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => handleSocialLinkClick(formData.linkedin)}
                          >
                            <FaLinkedin className="mb-1 mx-1" color="#0077B5" size={28} />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card align-items-center">
                <div className="  justify-content-center mr-3 pl-3 py-4">
                  <div
                    className="progress-circle"
                    style={{
                      position: 'relative',
                      width: '100px',
                      height: '100px',
                      borderRadius: '50%',
                      background: `conic-gradient(
                              #3498db 0%,
                              #3498db ${profileCompletionPercentage}%,
                              #ecf0f1 ${profileCompletionPercentage}%,
                              #ecf0f1 100%
                                 )`,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <span className="percentage-label" style={{ fontSize: '18px', color: '#2c3e50', fontWeight: 'bold' }}>
                      {profileCompletionPercentage}%
                    </span>
                  </div>
                </div>
                <h5 className="title align-items-center" style={{ fontSize: '20px', color: '#2c3e50', marginTop: '-10px', textAlign: 'center' }}>
                  Completion
                </h5>
              </div>
              <div className="applied-jobs-list border rounded p-3 bg-white ">
                <Row className="d-flex flex-column align-items-center text-center mb-2">
                  <Col md={12}>
                    <h5 className="mt-1 btn btn-primary p-2"
                      onClick={() => setShowRecentlyAppliedJobs(!showRecentlyAppliedJobs)}
                    >
                      Recently Applied Jobs
                    </h5>
                  </Col>
                  {Array.isArray(reversedJobs) && reversedJobs.length > 0 ? (
                    reversedJobs.slice(0, 5).map((applyjobs, index) => (
                      <React.Fragment key={applyjobs?._id}>
                        <Col md={12}>
                          <div
                            key={applyjobs?._id}
                            className={
                              applyjobs.addclassNameBookmark ===
                              "candidate-list-box mt-4 p-2"
                            }
                          >
                            <div className="mt-2">
                              <Row className="align-items-center">
                                <Col sm={10}>
                                  <div className="mb-2 mb-md-0 text-center mt-2 ml-md-3">
                                    <h5 id="jobAppliedJob" className="fs-16 mb-0">
                                      <Link
                                        id="appliedJob"
                                        to={`/jobdetails?id=${applyjobs.jobId?._id}`}
                                        className="text-dark"
                                      >
                                        {applyjobs.jobId?.jobTitle &&
                                          applyjobs.jobId.jobTitle.split(' ')
                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                            .join(' ')
                                        }
                                      </Link>
                                    </h5>
                                    <p
                                      id="jobApplyCompanyName"
                                      className="text-muted fs-13 mb-0"
                                    >
                                      {applyjobs?.companyId
                                        ? applyjobs.companyId.basicInfo?.companyName
                                        : "N/A"}
                                    </p>
                                  </div>

                                  <div className="align-items-center mb-1 ">
                                    <span
                                      className={`badge fs-13 mt-2 mx-1 ${applyjobs.jobStatus === 'not-shortlisted' ?
                                        'bg-danger-subtle text-danger' :
                                        applyjobs.jobStatus === 'shortlisted' ?
                                          'bg-success-subtle text-success' :
                                          applyjobs.jobStatus === 'in review' ?
                                            'bg-primary-subtle text-primary' :
                                            'bg-white text-dark'
                                        }`}
                                      style={{
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                      }}
                                    >
                                      {applyjobs.jobStatus === 'not-shortlisted' && <FaTimesCircle className='mr-1' />}
                                      {applyjobs.jobStatus === 'shortlisted' && <FaCheckCircle className='mr-1' />}
                                      {applyjobs.jobStatus === 'in review' && <FaHourglass className='mr-1' />}
                                      {(applyjobs.jobStatus !== 'not-shortlisted' && applyjobs.jobStatus !== 'shortlisted' && applyjobs.jobStatus !== 'in review') &&
                                        <FaPaperPlane style={{ marginRight: '5px' }} />
                                      }
                                      {capitalizeWords(applyjobs.jobStatus)}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                        <hr className="w-75 mt-1 mb-1" />
                      </React.Fragment>
                    ))
                  ) : (
                    <div className="text-center">
                      <h6>No Applied Jobs Found</h6>
                    </div>
                  )}
                  <Col md={12} className="text-center">
                    <Link to="/appliedjobslist" className="btn btn-primary p-1 mt-2">
                      View More
                    </Link>
                  </Col>
                </Row>
              </div>
            </form>
          </div>

          <div className="col-md-8">
            <form>
              <div className="col-md-20">
                <div className="card mb-1">
                  <div className="card-body">
                    {/* <div className="form-row align-items-center">
                      <div className="col-sm-2">
                        <label for="fullName" className="mb-0">
                          Name
                        </label>
                      </div>
                      <div className="col-sm-10">
                        :- {formData.firstname} {formData.lastname}
                      </div>
                    </div> */}
                    <div className="d-flex justify-content-between align-items-center">
                      <h3 className="text-2xl font-bold text-nowrap">Personal Details</h3>
                      <FaEdit
                        className="text-lg mb-2"
                        onClick={handleEditClick}
                        style={{ cursor: "pointer", color: "black" }}
                      />
                    </div>
                    <hr />
                    <div class="col mb-3 justify-content-center table-responsive">
                      <label for="colFormLabel" class="col-sm-2 ">
                        <strong className="mx-auto text-nowrap">
                          Full Name
                        </strong>
                      </label>
                      <div class="col-sm-10">
                        <div className="col-sm-20">
                          {formData.firstname} {formData.lastname}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="col mb-3 justify-content-center">
                      <label for="colFormLabel" class="col-sm-2 ">
                        <strong className="mx-auto text-nowrap">Email</strong>
                      </label>
                      <div class="col-md-10">
                        <div className="col-sm-20">{formData.email}</div>
                      </div>
                    </div>
                    <hr />
                    <div class="col mb-3 justify-content-center">
                      <label for="colFormLabel" class="col-sm-2 ">
                        <strong className="mx-auto text-nowrap">Contact</strong>
                      </label>
                      <div class="col-md-10">
                        <div className="col-sm-20">{formData.mobileNumber}</div>
                      </div>
                    </div>
                    <hr />
                    <div class="col mb-3 justify-content-center">
                      <label for="colFormLabel" class="col-sm-2 ">
                        <strong className="mx-auto text-nowrap">Address</strong>
                      </label>
                      <div class="col-md-10">
                        <div className="col-sm-20">{formData.address}</div>
                      </div>
                    </div>
                    <hr />
                    <div class="col mb-3 justify-content-center">
                      <label for="colFormLabel" class="col-sm-2">
                        <strong className="mx-auto text-nowrap">
                          Aadhar Number
                        </strong>
                      </label>
                      <div class="col-md-10">
                        <div className="col-sm-20">{formData.aadharnumber}</div>
                      </div>
                    </div>
                    <hr />
                    <div class="col mb-3 justify-content-center">
                      <label for="colFormLabel" class="col-sm-2 ">
                        <strong className="mx-auto text-nowrap">
                          Date Of Birth
                        </strong>
                      </label>
                      <div class="col-md-10">
                        <div className="col-sm-20">{formData.dateOfBirth}</div>
                      </div>
                    </div>
                    <hr />
                    <div class="col mb-3 justify-content-center">
                      <label for="colFormLabel" class="col-sm-2 ">
                        <strong className="mx-auto text-nowrap">Gender</strong>
                      </label>
                      <div class="col-md-10">
                        <div className="col-sm-20">{formData.gender}</div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </form>
            <div className=" mb-1">
              <form>
                <EducationalDetailsShow />
              </form>
            </div>
            <div className=" mb-1">
              <form>
                <CandidateSkills />
              </form>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};
