import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Col, Container, Row, Input, Label } from "reactstrap";
//import { Validation } from "../../pages/ExtraPages/Components/Validation";

//Import Images
import contactImage from "../../assets/images/Contact us-amico.png";
import { Validation } from "../ExtraPages/Components/Validation";
import { useAuth } from "../../hooks/useAuth";
  
const ContactContent = () => {
  const {contactusCompany}=useAuth();
  const {
    FullnameValidation,
    EmailValidation
} = Validation();
const [formData,setFormData]=useState({
  name:"",
  email: "",
  subject:"",
  comments:""
})
const handleOnChange = (e) => {
  let { name, value } = e.target;
  setFormData((pre) => ({ ...pre, [name]: value }));
};
const handleOnSubmit = (e) => {
  e.preventDefault();
  contactusCompany(formData);
};

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center mt-5">
            <Col lg={6}>
              <div className="section-title mt-4 mt-lg-0">
                <h3 className="title">Get in touch</h3>
                <p className="text-muted">
                Get started on your career path with FresherZone – your go-to platform for raising awareness,
                 increasing traffic, and building valuable connections.
                </p>
                <Form
                  method="post"
                  className="contact-form mt-4"
                  name="myForm"
                  id="myForm"
                  onSubmit={handleOnSubmit} 
                >
                  <span id="error-msg"></span>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="nameInput" className="form-label">
                          Name
                        </Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          onKeyUp={FullnameValidation}
                          onChange={handleOnChange}
                          className="form-control"
                          placeholder="Enter your name"
                          required
                        />
                      </div>
                      <span id="fullnamevalidation"></span>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="emailInput" className="form-label">
                          Email
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          onKeyUp={EmailValidation}
                          onChange={handleOnChange}
                          placeholder="Enter your email"
                          required
                        />
                      <span id="Email"></span>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="subjectInput" className="form-label">
                          Subject
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="subject"
                          placeholder="Enter your subject"
                          onChange={handleOnChange}
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="meassageInput" className="form-label">
                          Your Message
                        </Label>
                        <textarea
                          className="form-control"
                          placeholder="Enter your message"
                          name="comments"
                          onChange={handleOnChange}
                          rows="3"
                          required
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-end">
                    <button
                      type="submit"
                      id="submit"
                      name="submit"
                      className="btn btn-primary"
                     
                    >
                      {" "}
                      Send Message <i className="uil uil-message ms-1"></i>
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={5} className="ms-auto order-first order-lg-last">
              <div className="text-center">
                <img src={contactImage} alt="" className="img-fluid" />
              </div>
              <div className="mt-4 pt-3">
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-map-marker"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">
                   402, BG Arcade, Near Sun Orbit, Sun City Road, Sinhgad Rd, Anand Nagar, Pune, Maharashtra 411051
                    </p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-envelope"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">info@globalion.in</p>
                  </div>
                </div>
                {/* <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-phone-alt"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">(+245) 223 1245</p>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="map">
        <iframe
          title="maps"
          src="https://maps.google.com/maps?q=BG%20Arcade%20402,%20Sinhgad%20Road,Nimbaj%20Nagar,Pune,Maharastra&t=&z=13&ie=UTF8&iwloc=&output=embed"
          height="350"
          style={{ border: `0`, width: `100%` }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </React.Fragment>
  );
};

export default ContactContent;
