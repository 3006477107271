
import React, { useState } from "react";
const Pagination = ({ jobsPerPage, totalJobs, currentPage, paginate }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalJobs / jobsPerPage); i++) {
    pageNumbers.push(i);
  }
  // Calculate the range of pages to display (e.g., show 5 pages at a time)
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(startPage + 4, Math.ceil(totalJobs / jobsPerPage));
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination job-pagination mb-0 justify-content-center mt-4">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)} aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>

        {pageNumbers.slice(startPage - 1, endPage).map((number) => (
          <li key={number} className={`page-item ${number === currentPage ? "active" : ""}`}>
            <a className="page-link" href="#" onClick={() => paginate(number)}>
              {number}
            </a>
          </li>
        ))}

        <li className={`page-item ${currentPage === Math.ceil(totalJobs / jobsPerPage) ? "disabled" : ""}`}>
          <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)} aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;