import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import JobGridHeader from "./JobGridHeader";
import JobVacancy from "./JobVacancy";
// import JobVacancyNew from "./JobVacancyNew";
import Section from "./Section";
import Selected from "./Selected";
import { useJob } from "../../../hooks/useJob";

const JobGrid = () => {

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedJobType, setSelectedJobType] = useState('');
  const { getalljobs } = useJob();

  useEffect(() => {
    getalljobs();
  }, []);

  document.title = "Job Grid";

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  const handleJobTypeChange = (value) => {
    setSelectedJobType(value);
  };

  document.title = "Job Grid";
  return (
    <React.Fragment>
      <Section />
      <section className="section d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <JobGridHeader
                setSelectedTitles={setSelectedTitles}
                handleCountryChange={handleCountryChange}
                handleCityChange={handleCityChange}
                onJobTypeChange={handleJobTypeChange}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Selected setSelectedOptions={setSelectedOptions} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={12}>
              <JobVacancy
                selectedOptions={selectedOptions}
                selectedTitles={selectedTitles}
                selectedCountry={selectedCountry}
                selectedCity={selectedCity}
                selectedJobType={selectedJobType} // Pass selectedJobType to JobVacancy
              />
            </Col>
            {/* Uncomment the next line if you want to center JobVacancyNew as well */}
            {/* <Col lg={6}>
            <JobVacancyNew />
          </Col> */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
};

export default JobGrid;
