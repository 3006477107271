import React from "react";
import Section from "./Section"
import ReportAnIssuePage from "./ReportAnIssuePage";



const ReportAnIssue = () => {
  document.title = "Report An Issue";
  return (
    <React.Fragment>
       <Section />
         <ReportAnIssuePage />
    </React.Fragment>
  );
};

export default ReportAnIssue;
