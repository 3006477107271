import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useJob } from '../../../hooks/useJob';
import { categoryList } from "../../Home/Category_And_iconslist";


const Categories = () => {
  const { getalljobs, jobList } = useJob();
  const [categoryJobCounts, setCategoryJobCounts] = useState({});
  useEffect(() => {
    const counts = {};

    // Initialize counts with zeros for all categories
    categoryList.forEach((category) => {
      counts[category] = 0;
    });

    // Increment the count for each category present in the jobList
    if (jobList && Array.isArray(jobList)) {
      jobList.forEach((job) => {
        if (job.jobCategory && Array.isArray(job.jobCategory)) {
          job.jobCategory.forEach((categoryObj) => {
            const category = categoryObj.category;
            counts[category] = (counts[category] || 0) + 1;
          });
        }
      });
    }
    setCategoryJobCounts(counts);
  }, [jobList]);

  useEffect(() => {
    getalljobs();
  }, []);

  // Convert categoryJobCounts to the structure used in categories
  const categorie = Object.entries(categoryJobCounts).map(([jobName, jobNumbers]) => ({
    jobName,
    jobCategories: [{ jobName, jobNumbers }], // Adjust as needed
  }));

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <p className="badge bg-primary fs-14 mb-2">Jobs Live Today</p>
                <h4>Browse Job By Categories</h4>
                <p className="text-muted">
                Tell us about your project, and we'll match you with the right freelancers.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            {categorie.map((categoriesDetails, key) => (
              <Col lg={4} key={key}>
                <Card className="job-Categories-box bg-light border-0">
                  <CardBody className="p-3">
                    <ul className="list-unstyled job-Categories-list mb-0">
                      {(categoriesDetails.jobCategories || []).map((jobCategoriesDetails, key) => (
                        <li key={key}>
                          <Link  className="primary-link" to={`/joblist?category=${jobCategoriesDetails.jobName}`} >
                            {jobCategoriesDetails.jobName}{" "}
                            <span className="badge bg-info-subtle text-secondary float-end">
                              {jobCategoriesDetails.jobNumbers}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

    </React.Fragment>
  );
};

export default Categories;
