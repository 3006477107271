import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col } from "reactstrap";
import Pagination from '../../../Jobs/JobList2/Pagination';
import { useJob } from '../../../../hooks/useJob';
import { formatDistanceToNow } from 'date-fns';
import { baseURL } from '../../../../repositories/Repository';
import { faLandmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCheckCircle, FaTimesCircle, FaHourglass, FaPaperPlane } from 'react-icons/fa';

const JobLists = ({ selectedFilter }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage] = useState(10);
    const { appliedjobs, appliedjobslist } = useJob();

    useEffect(() => {
        appliedjobslist();
    }, [selectedFilter]);

    // Checking appliedjobs is null or undefined
    if (!appliedjobs) {
        return null;
    }

    const capitalizeWords = (str) => {
        return str.split(/\s+/).map(word => {
            if (word.includes('-')) {
                return word.split('-').map(subWord => subWord.charAt(0).toUpperCase() + subWord.slice(1)).join('-');
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
        }).join(' ');
    };

    //reverse all jobs
    const reversedJobs = Array.isArray(appliedjobs) ? appliedjobs.slice().reverse() : [];

    const handleFilterChange = (value) => {
        setCurrentPage(1);
    };
    const filterJobs = (jobs, filter) => {
        const currentTime = new Date();

        return jobs.filter(job => {
            // Check if jobId is available
            if (!job.jobId) {
                return false;
            }
            const appliedTime = new Date(job.appliedAt);

            switch (filter) {
                case "one":
                    const oneHourAgo = new Date(currentTime);
                    oneHourAgo.setHours(currentTime.getHours() - 1);
                    return appliedTime >= oneHourAgo && appliedTime <= currentTime;

                case "two":
                    const oneDayAgo = new Date(currentTime);
                    oneDayAgo.setDate(currentTime.getDate() - 1);
                    return appliedTime >= oneDayAgo && appliedTime <= currentTime;

                case "three":
                    const twoDaysAgo = new Date(currentTime);
                    twoDaysAgo.setDate(currentTime.getDate() - 2);
                    return appliedTime >= twoDaysAgo && appliedTime <= currentTime;

                case "week":
                    const oneWeekAgo = new Date(currentTime);
                    oneWeekAgo.setDate(currentTime.getDate() - 7);
                    return appliedTime >= oneWeekAgo && appliedTime <= currentTime;

                case "month":
                    const oneMonthAgo = new Date(currentTime);
                    oneMonthAgo.setMonth(currentTime.getMonth() - 1);
                    return appliedTime >= oneMonthAgo && appliedTime <= currentTime;

                case "year":
                    const oneYearAgo = new Date(currentTime);
                    oneYearAgo.setFullYear(currentTime.getFullYear() - 1);
                    return appliedTime >= oneYearAgo && appliedTime <= currentTime;

                default:
                    return true;
            }
        });
    };
    const filteredJobs = filterJobs(reversedJobs, selectedFilter);
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <React.Fragment>
            <div className="ml-4 align-items-center justify-content-center  pl-3 py-4 w-100 mt-n3">
                <Row className="mr-5">
                    {Array.isArray(currentJobs) && currentJobs.length > 0 ? (
                        currentJobs?.map((applyjobs, index) => (
                            <React.Fragment key={applyjobs?._id}>
                                <Col md={6}>
                                    <div
                                        key={applyjobs?._id}
                                        className={
                                            applyjobs.addclassNameBookmark === true
                                                ? "candidate-list-box bookmark-post card mt-4 "
                                                : "candidate-list-box card mt-4"
                                        }
                                    >
                                        <div className="p-2 ">
                                            <Row className="align-items-center">
                                                <Col lg={3}>
                                                    <div className='mb-2 mb-md-2 text-center mt-2 mt-sm-2'>
                                                        <Link
                                                            id="companydetails"
                                                            to={`/companydetails?id=${applyjobs.jobId?._id}&companyId=${applyjobs.companyId?._id}`}>
                                                            {applyjobs?.companyId?.logo ? (
                                                                <div className="avatar-container">
                                                                    <img
                                                                        className="avatar-logo rounded-3"
                                                                        src={`${baseURL}/${applyjobs?.companyId?.logo?.file}`}
                                                                        alt="Company Logo"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="avatar-container">
                                                                    <FontAwesomeIcon
                                                                        icon={faLandmark}
                                                                        className="avatar-logo"
                                                                        color='#373840'
                                                                        style={{ width: '80px', height: '80px' }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Link>
                                                    </div>

                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-2 mb-md-0 text-center mt-2">
                                                        <h5 id="jobAppliedJob" className="fs-18 mb-0">
                                                            <Link
                                                                id="appliedJob"
                                                                to={`/jobdetails?id=${applyjobs.jobId?._id}`}
                                                                className="text-dark"
                                                            >
                                                                {applyjobs.jobId.jobTitle &&
                                                                    applyjobs.jobId.jobTitle.split(' ')
                                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                        .join(' ')
                                                                }
                                                            </Link>
                                                        </h5>
                                                        <p
                                                            id="jobApplyCompanyName"
                                                            className="text-muted fs-14 mb-0"
                                                        >
                                                            {applyjobs.companyId
                                                                ? applyjobs.companyId.basicInfo.companyName
                                                                : "N/A"}
                                                        </p>
                                                    </div>
                                                </Col>

                                                <Col lg={4}>
                                                    <div className="flex flex-col md:flex-row md:items-center md:space-x-2 mb-0 mt-1 p-2">
                                                        <div className="text-center mb-2">
                                                            {applyjobs?.appliedAt && (
                                                                <p className="badge fs-13 mt-1 mx-1 bg-blue text-light">
                                                                    {formatDistanceToNow(new Date(applyjobs.appliedAt), {
                                                                        addSuffix: true,
                                                                    })}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="bg-light">
                                            <Col md={2}>
                                                <div className="text-center mb-2">
                                                    <span
                                                        className={`badge fs-13 mt-2 mx-1 ${applyjobs.jobStatus === 'not-shortlisted' ?
                                                            'bg-danger-subtle text-danger' :
                                                            applyjobs.jobStatus === 'shortlisted' ?
                                                                'bg-success-subtle text-success' :
                                                                applyjobs.jobStatus === 'in review' ?
                                                                    'bg-primary-subtle text-primary' :
                                                                    'bg-white text-dark'
                                                            }`}
                                                        style={{
                                                            padding: '5px 10px',
                                                            borderRadius: '5px',
                                                        }}
                                                    >
                                                        {applyjobs.jobStatus === 'not-shortlisted' && <FaTimesCircle className='mr-1' />}
                                                        {applyjobs.jobStatus === 'shortlisted' && <FaCheckCircle className='mr-1' />}
                                                        {applyjobs.jobStatus === 'in review' && <FaHourglass className='mr-1' />}
                                                        {(applyjobs.jobStatus !== 'not-shortlisted' && applyjobs.jobStatus !== 'shortlisted' && applyjobs.jobStatus !== 'in review') &&
                                                            <FaPaperPlane style={{ marginRight: '5px' }} />
                                                        }
                                                        {capitalizeWords(applyjobs.jobStatus)}
                                                    </span>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                </Col>
                                {index % 2 !== 0 && <div className="w-100"></div>}
                            </React.Fragment>
                        ))
                    ) : (
                        <div className="text-center">
                            <h5>No jobs found</h5>
                        </div>
                    )}
                </Row>

                <Pagination
                    jobsPerPage={jobsPerPage}
                    totalJobs={filteredJobs.length}
                    currentPage={currentPage}
                    paginate={paginate} />

            </div >
        </React.Fragment >
    )
}
export default JobLists;
