import React from "react";
import Routes from "./Routes/index";
import "./assets/scss/themes.scss";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { login } from "./store/auth/reducer";
import { useEffect } from "react";

function App() {

  useEffect(() => {
    // Periodically check and clean up local storage
    setInterval(() => {
      const storedData = localStorage.getItem('timeSpan');
      if (storedData) {
        const { timeSpan } = JSON.parse(storedData);
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - timeSpan;

        // Remove data if it's older than 24 hours
        if (timeDifference > 24 * 60 * 60 * 1000) {
          localStorage.removeItem('role');
          localStorage.removeItem('token');
          localStorage.removeItem('timeSpan');
        }
      }
    }, 6000); // Run every hour (60000 milliseconds)
  }, [])

  const token = localStorage.getItem('token')
  const dispatch = useDispatch()
  if (token) {
    dispatch(login())
  }
  return (
    <>
      <ToastContainer
        hideProgressBar={true}
        autoClose={'3000'}
      />
      <Routes />
    </>
  );
}

export default App;
