import React from "react";
import { Container, Row, Col } from "reactstrap";


const FraudAlertPage = () => {
  const FraudAlertPage = [
    {
      id: 1,
      policyTitle: "Use for FresherZone",
      policyRules: [
        {
          id: 1,
          policyInnerRule:
          "At FresherZone, available at FresherZone.com, safeguarding the privacy of our users is a top priority. Our Privacy Policy outlines the types of information we collect and how we utilize it to enhance the user experience. If you have any further inquiries or need additional details regarding our Privacy Policy, please feel free to reach out to us via email at info"
        },
        {
          id: 2,
          policyInnerRule:
            " If you have additional questions or require more information about our Privacy Policy."
        },
        {
          id: 3,
          policyInnerRule:
          "This Privacy Policy is specifically designed for our online activities at FresherZone.com and pertains to the information shared and/or collected from visitors to our website. Please note that this policy does not extend to information collected offline or through channels other than our official website."
        },
        {
          id: 4,
          policyInnerRule:
         
"Similarly, at FresherZone, we prioritize the protection of children online. We strongly encourage parents and guardians to actively engage in, monitor, and guide the online activities of their children while accessing our website. It is essential to note that this policy exclusively addresses information shared and collected online through FresherZone.com and does not extend to data collected offline or through other channels outside of this website."
        }
      ]
    },
    {
      id: 2,
      policyTitle: "We use your information to :",
      policyRules: [
        {
          id: 1,
          policyInnerRule: "  Digital Marketing Solutions for Tomorrow"
        },
        {
          id: 2,
          policyInnerRule: " Our Talented & Experienced Marketing Agency"
        },
        {
          id: 3,
          policyInnerRule:
            " Individualized Profile Creation"
        },
        {
          id: 4,
          policyInnerRule: " Enhanced User Experience"
        }
      ]
    },
    {
      id: 3,
      policyTitle: "Privacy and copyright protection",
      policyRules: [
        {
          id: 1,
          policyInnerRule:
            "In the realm of career exploration, FresherZone stands out amid an abundance of opportunities and resources. Our platform goes beyond the conventional, offering engaging content that goes beyond the ordinary, providing insightful and sometimes amusing stories to make your career journey both informative and enjoyable."
        },
        {
          id: 2,
          policyInnerRule:
            " Our platform provides engaging content that offers unique insights and occasionally adds a touch of humor, ensuring your career journey is both informative and enjoyable."
        }
      ]
    }
  ];
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            {FraudAlertPage.map((FraudAlertDetails, key) => (
              <Col lg={12} key={key}>
                <h5 className="mb-4">{FraudAlertDetails.policyTitle}</h5>
                <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                  {FraudAlertDetails.policyRules.map(
                    (privacyandpolicyInner, key) => (
                      <li key={key}>{privacyandpolicyInner.policyInnerRule}</li>
                    )
                  )}
                </ul>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default FraudAlertPage;
