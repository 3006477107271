import React, { useState } from "react";
import { Container } from "reactstrap";
import JobLists from "./JobLists";
import Selected from "./Selected";

const AppliedJobsList = () => {
    const [selectedFilter, setSelectedFilter] = useState("all");
          
    const handleFilterChange = (value) => {
        setSelectedFilter(value);
    };

    document.title = "Applied Jobs";
    return (
        <React.Fragment>
            <section >
                <Container >
                    <Selected onFilterChange={handleFilterChange} selectedFilter={selectedFilter} />
                    <JobLists selectedFilter={selectedFilter} />
                </Container>
            </section>
        </React.Fragment>
    );
};

export default AppliedJobsList;
