import React from "react";
import Categories from "./Categories";
import Cta from "./Cta";
import Section from "./Section";
import Footer from "../../../Layout/CommonLayout/Footer";

const JobsCategories = () => {
  document.title =
    "Job Categories";
  return (
    <React.Fragment>
      <Section />
      <Categories />
     
      
    </React.Fragment>
  );
};

export default JobsCategories;
