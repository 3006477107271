import React from "react";
import { Col, Input, Row } from "reactstrap";
import { Validation } from "../../Components/Validation";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
const {
  CompanynameValidation,
  StatenameValidation,
  CitynameValidation,
  ContrynameValidation,
  NumberValidation,
  PostalcodeValidation,
  PanCardValidation,
  GSTnumberValidation,
} = Validation();

export const BasicFieldsOfCompany = ({
  loading,
  formData,
  address,
  documents,
  handleOnChange,
  handleOnAddressChange,
  handleOnDocumentChange,
}) => {
  return (
    <>
      <div className="mb-3">
        <label htmlFor="companyNameInput" className="form-label">
          Company Name
        </label>
        <Input
          name="companyName"
          value={formData.companyName}
          onChange={handleOnChange}
          onKeyUp={CompanynameValidation}
          type="text"
          className="form-control"
          required
          id="companyNameInput"
          placeholder="Enter your company name"
        />
        <span id="first" />
      </div>
      <div className="mb-3">
        <label htmlFor="address1Input" className="form-label">
          Address Line 1
        </label>
        <Input
          name="address1"
          value={address.address1}
          onChange={handleOnAddressChange}
          type="text"
          className="form-control"
          required
          id="address1Input"
          placeholder="Enter your address"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="address2Input" className="form-label">
          Address Line 2
        </label>
        <Input
          name="address2"
          value={address.address2}
          onChange={handleOnAddressChange}
          type="text"
          className="form-control"
          required
          id="address2Input"
          placeholder="Enter your address"
        />
      </div>
      <Row>
        <Col sm={12} lg={6}>
          <div className="mb-3">
            <label htmlFor="cityInput" className="form-label">
              City
            </label>
            <Input
              name="city"
              value={address.city}
              onChange={handleOnAddressChange}
              onKeyUp={CitynameValidation}
              type="text"
              className="form-control"
              required
              id="cityInput"
              placeholder="Enter your city"
            />
            <span id="city" />
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <label htmlFor="stateInput" className="form-label">
              State
            </label>
            <Input
              name="state"
              value={address.state}
              onChange={handleOnAddressChange}
              onKeyUp={StatenameValidation}
              type="text"
              className="form-control"
              required
              id="stateInput"
              placeholder="Enter your state"
            />
            <span id="state" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <div className="mb-3">
            <label htmlFor="postalCodeInput" className="form-label">
              Postal Code
            </label>
            <Input
              name="postalCode"
              value={address.postalCode}
              onChange={handleOnAddressChange}
              onKeyUp={PostalcodeValidation}
              type="text"
              className="form-control"
              required
              id="postalCodeInput"
              placeholder="Enter your postal code"
            />
            <span id="postalcode" />
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <label htmlFor="countryInput" className="form-label">
              Country
            </label>
            <Input
              name="country"
              value={address.country}
              onChange={handleOnAddressChange}
              onKeyUp={ContrynameValidation}
              type="text"
              className="form-control"
              required
              id="countryInput"
              placeholder="Enter your country"
            />
            <span id="contry" />
          </div>
        </Col>
      </Row>
      <div className="mb-3">
        <label htmlFor="dateOfRegistration" className="form-label">
          Date of Registration
        </label>
        <Input
          name="dateOfRegistration"
          value={formData.dateOfRegistration}
          onChange={handleOnChange}
          type="date"
          className="form-control"
          required
          id="dateOfRegistration"
          // placeholder="Enter your mobile number"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="mobileNumber" className="form-label">
          Mobile Number
        </label>
        <Input
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleOnChange}
          onKeyUp={NumberValidation}
          maxLength={10}
          type="number"
          className="form-control"
          required
          id="mobileNumber"
          placeholder="Enter your mobile number"
        />
        <span id="Number"/>
      </div>
      <div className="mb-3">
        <label htmlFor="panNumber" className="form-label">
          PAN Number
        </label>
        <Input
          name="panNumber"
          value={formData.panNumber}
          onChange={handleOnChange}
          onKeyUp={PanCardValidation}
          type="text"
          className="form-control"
          required
          id="panNumber"
          placeholder="Enter your PAN number"
        />
        <span id="Pancard" />
      </div>
      <div className="mb-3">
        <label htmlFor="gstNumber" className="form-label">
          GST Number
        </label>
        <Input
          name="gstNumber"
          value={formData.gstNumber}
          onChange={handleOnChange}
          onKeyUp={GSTnumberValidation}
          type="text"
          maxLength={15}
          className="form-control"
          required
          id="gstNumber"
          placeholder="Enter your GST number"
        />
        <span id="gstnumber" />
      </div>
      <div>
        <Row>
          <Col sm={12} lg={6}>
            <div className="mb-3">
              <label htmlFor="fileNameInput" className="form-label">
                Select Document
              </label>
              <Input 
                name="name"
                value={documents.name}
                onChange={handleOnDocumentChange}
                type="select"
                className="form-control form-control-lg py-2"
                required
                id="fileNameInput"
              >
                <option selected disabled value="">
                  select type
                </option>
                <option className="options" value="shop-act">
                  Shop Act
                </option>
                <option className="options" value="roc">
                  ROC
                </option>
                <option className="options" value="udyam-aadhar">
                  Udyam Aadhar
                </option>
              </Input>
            </div>
          </Col>
          <Col>
            <div className="mb-3 " hidden={documents.name === ""}>
              <label htmlFor="fileInput" className="form-label">
                Upload Document
              </label>
              <Input
                name="file"
                // value={documents.file}
                onChange={handleOnDocumentChange}
                type="file"
                className="form-control"
                size="lg"
                required
                id="fileInput"
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="mb-4">
        <div className="form-check">
          <Input
            name="agree"
            value={formData.agree == "true" ? "false" : "true"}
            onChange={handleOnChange}
            className="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            required
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            I agree to the{" "}
            <Link to="#" className="text-white text-decoration-underline">
              Terms and conditions
            </Link>
          </label>
        </div>
      </div>
      <div className="text-center">
        <button
          disabled={loading}
          type="submit"
          className="btn btn-white btn-hover w-100"
        >
          {!loading ? (
            "Sign Up"
          ) : (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </button>
      </div>
    </>
  );
};
