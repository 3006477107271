import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Section from "../../Jobs/JobList/Section";
import JobSearchOptions from "./JobSearchOptions";
import JobVacancyList from "./JobVacancyList";
import Popular from "./Popular";
import Sidebar from "./Sidebar";

import { useJob } from "../../../hooks/useJob";

const JobList = () => {
  document.title = "Job List";
  const [searchLocation, setSearchLocation] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  const { getalljobs, jobList } = useJob();
   // Add a state variable to track the selected datetype.
  const [selectDateType,setSelectDateType]=useState(['checkAll']);
  // Add a state variable to track the selected job category
  const [selectedJobCategory, setSelectedJobCategory] = useState("");

  useEffect(() => {
    getalljobs();
  }, [selectedJobType]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          
          <Row>
            <Col lg={9}>
              <div className="me-lg-5">
                <JobVacancyList
                  searchLocation={searchLocation}
                  selectedJobType={selectedJobType}
                  selectDateType={selectDateType}
                  selectedJobCategory={selectedJobCategory}
                  
                />
              </div>
            </Col>
            <Sidebar
              setSearchLocation={setSearchLocation}
              setSelectedJobType={setSelectedJobType}
              selectDateType={selectDateType}
              setSelectDateType={setSelectDateType}
              setSelectedJobCategory={setSelectedJobCategory}
            />
          </Row>
        </Container>
      </section>
      
    </React.Fragment>
  );
};

export default JobList;
