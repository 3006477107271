import React, { useEffect, useState, useRef } from 'react'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Validation } from '../../ExtraPages/Components/Validation';
import '../assets/css/Candidate.css'
import { useProfile } from '../../../hooks/useProfile';
import { RiseLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../layout/AdminLayout/Sidebar';
import { baseURL } from '../../../repositories/Repository';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const Profile = () => {
  const { reqCandidateInfo, profileInfo, updateCandidateInfo } = useProfile();
  const [initialProfileInfo, setInitialProfileInfo] = useState({});
  const route = useNavigate();
  const [loading, setloading] = useState(false);
  const [avatar, setAvatar] = useState();//for storing image
  useEffect(() => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 3000);
  }, []);
  const loadingStyle = {
    marginTop: '300px',
    textAlign: 'center',
    displayFlex: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    width: '100%',
    height: '100vh'
  };

  useEffect(() => {
    reqCandidateInfo();
  }, []);
  const [notEdit, setnotEdit] = useState({
    firstname: '',
    lastname: '',
    email: '',
    aadharnumber: '',
  });
  const [documents, setDocuments] = useState({
    candidateprofile: "",
  });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    address: '',
    mobileNumber: '',
    dateOfBirth: '',
    gender: '',
    skills: ''
  });

  const [links, setlinks] = useState({
    website: '',
    instagram: '',
    facebook: '',
    linkedin: '',
    twitter: '',
  });

  // const [skills, setSkills] = useState('');

  const [projectInfo, setProjectInfo] = useState([{
    projectName: '',
    projectDescription: ''
  }]);


  useEffect(() => {
    let logoPath = `${baseURL}/${profileInfo?.profile}`
    setAvatar(logoPath)
    setnotEdit({
      email: profileInfo?.email,
      aadharnumber: profileInfo?.aadharNumber,
    });

    setFormData({
      firstname: profileInfo?.firstName,
      lastname: profileInfo?.lastName,
      address: profileInfo?.address,
      mobileNumber: profileInfo?.mobileNumber,
      dateOfBirth: profileInfo?.dateOfBirth,
      gender: profileInfo?.gender,
      skills: profileInfo?.skills,
    });

    setlinks({
      website: profileInfo?.links?.website,
      instagram: profileInfo?.links?.instagram,
      facebook: profileInfo?.links?.facebook,
      linkedin: profileInfo?.links?.linkedin,
      twitter: profileInfo?.links?.twitter,
    });
    setDocuments({
      candidateprofile: profileInfo?.profile || '',
    });
    if (profileInfo?.projectInfo) {
      setProjectInfo(profileInfo.projectInfo);
    } else {
      setProjectInfo([{ projectName: '', projectDescription: '' }]);
    }
  }, [profileInfo]);

  const handleOnChange = (e) => {
    let { name, value, files } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setlinks((prev) => ({ ...prev, [name]: value }));

    if (files) {
      setAvatar(URL.createObjectURL(files[0]));
      setDocuments((prevValues) => {
        return {
          ...prevValues,
          [name]: files[0],
        };
      });
    }
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    const formValues = new FormData();
    formValues.append('formData', JSON.stringify(formData))
    formValues.append('links', JSON.stringify(links))
    formValues.append('projectInfo', JSON.stringify(projectInfo));
    formValues.append('candidateProfile', documents.candidateprofile)
    updateCandidateInfo(formValues)
  };

  const { FirstnameValidation,
    LastnameValidation,
    AddressValidation,
    EmailValidation,
    NumberValidation,
    AadharValidation,
    WebValidation,
    InstaValidation,
    FacebookValidation,
    TwitterValidation,
    LinkdinValidation
  } = Validation();


  const handleCancelClick = () => {
    // console.log('Cancelling changes:', initialJobInfo);
    setloading(initialProfileInfo);
    route('/candidatedashboard');
  };

  const handleOnAvatrRemove = () => {
    setAvatar(null);
    setDocuments((prevValues) => {
      return {
        ...prevValues,
        candidateprofile: '',
      };
    });
  };

  const Avatar = () => {
    const avatarStyle = {
      position: "relative",
      maxWidth: "80px",
      minHeight: "80px",
    };
  
    const logoStyle = {
      width: "80px",
      height: "80px",
    };
  
    const removeButtonStyle = {
      position: "absolute",
      top: "-10px",
      left: "70px",
      backgroundColor: "#3b3535",
      borderRadius: "50%",
      color: "#fff",
      fontSize: "10px",
      cursor: "pointer",
      padding: "0 5px",
    };
  
    if (avatar) {
      return (
        <div style={avatarStyle}>
          <img style={logoStyle} src={avatar} alt='avatar' />
          <span style={removeButtonStyle} className='remove-button' onClick={handleOnAvatrRemove}>
            X
          </span>
        </div>
      );
    }
  
    return null;
  };
  
  const addProjectField = () => {
    setProjectInfo([...projectInfo, { projectName: '', projectDescription: '' }]);
  };

  const handleProjectChange = (index, fieldName, value) => {
    const updatedProjects = [...projectInfo];
    if (updatedProjects[index]) {
      updatedProjects[index][fieldName] = value;
      setProjectInfo(updatedProjects);
    }
  };

  const removeProject = (index) => {
    const updatedProjects = [...projectInfo];
    updatedProjects.splice(index, 1);
    setProjectInfo(updatedProjects);
  };

  const fileInputRef = useRef(null);
  return (
    <>
      <div>
        <Sidebar />
      </div>
      <div >
        {loading ?
          <div style={loadingStyle}>
            <RiseLoader loading={loading} />
          </div>
          :
          <Container className='mt-5 mb-3 my-auto'>
            {/* <h1 className='mb-3 ml-2'>Edit Profile</h1> */}
            <Card className="side-border ">
              <CardBody className='side-border '>
                <Form onSubmit={handleSubmit}>
                  <h4 className="heading-small text-dark mb-4 ml-4 ">
                    Candidate Information
                    <hr className="hr hr-blurry" />
                  </h4>
                  <div className="pl-lg-4 pt-2">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            First Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            size="lg"
                            id="input-firstname"
                            placeholder="firstname"
                            type="text"
                            name="firstname"
                            style={{ cursor: 'not-allowed' }}
                            value={formData.firstname}
                            // onChange={handleOnChange}
                            onKeyUp={FirstnameValidation}
                            readOnly
                          /><span id='first' />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Last Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            size="lg"
                            id="input-lastname"
                            placeholder="lastname"
                            type="text"
                            name="lastname"
                            style={{ cursor: 'not-allowed' }}
                            value={formData.lastname}
                            // onChange={handleOnChange}
                            onKeyUp={LastnameValidation}
                            readOnly
                          /><span id='last' />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-number"
                          >
                            Aadhar No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            size="lg"
                            id="input-number"
                            type="text"
                            name="aadharnumber"
                            style={{ cursor: 'not-allowed' }}
                            value={notEdit.aadharnumber}
                            // onChange={handleOnChange}
                            onKeyUp={AadharValidation}
                            placeholder='0000-0000-0000'
                            readOnly
                          />
                          <span id='AadharNumber' />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email ID
                          </label>
                          <Input
                            className="form-control-alternative"
                            size="lg"
                            id="input-email"
                            placeholder="eg.example123@gmail.com"
                            type="email"
                            name="email"
                            style={{ cursor: 'not-allowed' }}
                            value={notEdit.email}
                            // onChange={handleOnChange}
                            onKeyUp={EmailValidation}
                            readOnly
                            required
                          />
                          <span id='Email' />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            size="lg"
                            id="input-address"
                            placeholder="Address"
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleOnChange}
                            onKeyUp={AddressValidation}
                            required
                          /><span id='address' />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-number"
                          >
                            Contact No.
                          </label>
                          <Input
                            className="form-control-alternative"
                            size="lg"
                            id="input-number"
                            type="number"
                            name="mobileNumber"
                            value={formData.mobileNumber}
                            onChange={handleOnChange}
                            onKeyUp={NumberValidation}
                            required
                          />
                          <span id='Number' />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Date of Birth
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            size="lg"
                            placeholder="YYYY-MM-DD"
                            type="date"
                            name="dateOfBirth"
                            value={formData.dateOfBirth}
                            onChange={handleOnChange}
                            required
                          />
                        </FormGroup>
                      </Col>


                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-gender"
                          >
                            Gender
                          </label>
                          <br />
                          <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleOnChange}
                            class="form-select bg-white" required>
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Others</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-url"
                          >
                            Candidate Website
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-url"
                            size="lg"
                            placeholder="website"
                            type="url"
                            name="website"
                            value={links.website}
                            onChange={handleOnChange}
                            onKeyUp={WebValidation}
                          />
                          <span id='url'></span>
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-url"
                          >
                            Instagram link
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-url"
                            size="lg"
                            placeholder="url"
                            type="url"
                            name="instagram"
                            value={links.instagram}
                            onChange={handleOnChange}
                            onKeyUp={InstaValidation}
                          />
                          <span id='instaurl'></span>
                        </FormGroup>
                      </Col>

                    </Row>


                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-url"
                          >
                            Facebook link
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-url"
                            size="lg"
                            placeholder="url"
                            type="url"
                            name="facebook"
                            value={links.facebook}
                            onChange={handleOnChange}
                            onKeyUp={FacebookValidation}
                          />
                          <span id='faceurl'></span>
                        </FormGroup>
                      </Col>


                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-url"
                          >
                            Linkedin link
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-url"
                            size="lg"
                            placeholder="url"
                            type="url"
                            name="linkedin"
                            value={links.linkedin}
                            onChange={handleOnChange}
                            onKeyUp={LinkdinValidation}
                          />
                          <span id='linkdinurl'></span>
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-url"
                          >
                            Twitter link
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-url"
                            size="lg"
                            placeholder="url"
                            type="url"
                            name="twitter"
                            value={links.twitter}
                            onChange={handleOnChange}
                            onKeyUp={TwitterValidation}
                          />
                          <span id='twitterurl'></span>
                        </FormGroup>
                      </Col>
                      <Col lg='6'>
                        <FormGroup>
                          <label
                            className='form-control-label labeltype'
                            htmlFor='input-Twitter'
                          >
                            Candidate Profile Upload
                          </label>
                          <br />
                          {!documents.candidateprofile ? (
                            <>
                              <Input
                                type='file'
                                id='img'
                                name='candidateprofile'
                                accept='image/*'
                                size='lg'
                                onChange={handleOnChange}
                                ref={fileInputRef}
                              />

                            </>
                          ) : (
                            <Avatar />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label htmlFor="skills">Skills</label>
                          <Input
                            type="text"
                            name="skills"
                            id="skills"
                            value={formData.skills}
                            onChange={handleOnChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <h5>PROJECT:
                          <i
                            className="fas fa-plus float-right"
                            style={{ color: "green", padding: "4px", fontSize: "2em", cursor: "pointer" }}
                            onClick={addProjectField}
                          >
                          </i></h5>
                      </Col>
                    </Row>
                    {projectInfo?.map((project, index) => (
                      <Row key={index}>
                        <Col lg="6">
                          <FormGroup>
                            <label htmlFor={`projectName${index}`}>
                              Project Name
                            </label>
                            <Input
                              type="text"
                              name={`projectName${index}`}
                              id={`projectName${index}`}
                              value={project.projectName}
                              onChange={(e) => handleProjectChange(index, 'projectName', e.target.value)}
                            />

                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label htmlFor={`projectDescription${index}`}>
                              Project Description
                            </label>
                            <Input
                              type="text"
                              name={`projectDescription${index}`}
                              id={`projectDescription${index}`}
                              value={project.projectDescription}
                              onChange={(e) => handleProjectChange(index, 'projectDescription', e.target.value)}
                            />

                          </FormGroup>
                        </Col>
                        <Row>
                          <Col lg="12" className="d-flex justify-content-end">
                            <FontAwesomeIcon icon={faTrash} className="trash-icon" onClick={() => removeProject(index)} />
                          </Col>
                        </Row>
                      </Row>
                    ))}
                  </div>
                  <Button className='btn btn-success mb-3 ml-3'>Update</Button>
                  <button className='btn btn-success mb-3 ml-3' onClick={handleCancelClick}>Cancel</button>
                </Form>
              </CardBody>
            </Card>
            {/* <Button className='btn btn-success mb-3 ml-5'>Save Profile</Button> */}

          </Container>
        }
        {/* <Button className='btn btn-danger' >Save Profile</Button> */}
      </div >
    </>
  )
}

export default Profile;