import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosInstance from "../../repositories/Repository";


export const verifyOtpReq = createAsyncThunk('auth/verify-otp', async () => {
    try {
        const response = await axiosInstance.get('auth/verify-otp')
        if (response.data) {
            return response.data
        }
    } catch (error) {
        console.log(error);
    }
})