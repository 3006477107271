import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Input, Label } from "reactstrap";
import { useProfile } from "../../hooks/useProfile";
import { Validation } from "../../pages/ExtraPages/Components/Validation";
import { baseURL } from "../../repositories/Repository";
import { useJob } from "../../hooks/useJob";


export const JobApply = ({ isOpen, toggleModal, jobId }) => {

  const { reqCandidateInfo, profileInfo } = useProfile();
  const { appliedjobs, appliedjobslist, postJobApplication } = useJob();

  useEffect(() => {
    appliedjobslist();
  }, []);

  useEffect(() => {
    reqCandidateInfo();
  }, []);

  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    jobId: "",
    studentName: "",
    phoneNumber: "",
    email: "",
    coveringLatter: "",
  });

  useEffect(() => {
    setFormData({
      jobId,
      studentName: `${profileInfo?.firstName} ${profileInfo?.lastName}`,
      phoneNumber: profileInfo?.mobileNumber,
      email: profileInfo?.email,
      coveringLatter: "",
      resume: null,
    });
  }, [jobId, profileInfo]);

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Check if files are present
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const isFormValid = () => {
    return (
      formData.studentName &&
      formData.email &&
      formData.phoneNumber &&
      formData.coveringLatter &&
      formData.coveringLatter.trim() !== "" &&
      formData.resume
    );
  };

  //Handle resume download 
  const handleDownloadResume = async (resumePath, studentName) => {
    try {
      if (!resumePath || !studentName) {
        console.error("Resume path or student name is undefined or null.");
        return;
      }
      const resumeUrl = `${baseURL}/${resumePath.replace(/\\/g, "/")}`;
      const response = await fetch(resumeUrl);
      const blob = await response.blob();
      const fileName = `${studentName.replace(/\s+/g, '_')}_Resume.pdf`;
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = fileName;
      downloadLink.click();
      window.URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.error("Error during resume download:", error);
    }
  };

  //Handle resume view 
  const handleViewResume = (resumePath, studentName) => {
    try {
      if (!resumePath || !studentName) {
        console.error('Resume path or student name is undefined or null.');
        return;
      }
      const resumeUrl = `${baseURL}/${resumePath.replace(/\\/g, '/')}`;
      window.open(resumeUrl, '_blank');
    } catch (error) {
      console.error('Error during resume view:', error);
    }
  };

  const { NumberValidation } = Validation();
  const [resumeOption, setResumeOption] = useState("manual");

  const handleResumeOptionChange = (option) => {
    setResumeOption(option);
    setFile(null);
    if (option === "new" && appliedjobs.length > 0) {
      const mostRecentJob = appliedjobs[appliedjobs.length - 1];
      if (mostRecentJob?.resume) {
        setFormData((prev) => ({ ...prev, resume: mostRecentJob.resume }));
      } else {
        console.error("Resume details are missing or incomplete.");
        setFormData((prev) => ({ ...prev, resume: null }));
      }
    } else {
      setFormData((prev) => ({ ...prev, resume: null }));
    }
  };

  //Handle the Job apply conditions 
  const handleApply = (e) => {
    const appendData = new FormData();
    e.preventDefault();
    appendData.append("jobInfo", JSON.stringify(formData));
    appendData.append("resume", file);
    postJobApplication(appendData);
    toggleModal();
    setResumeOption("manual");
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalBody className="modal-body p-5">
          <div className="text-center mb-4">
            <h5 className="modal-title" id="staticBackdropLabel">
              Apply For This Job
            </h5>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '-15px',
              right: '-10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              width: '35px',
              height: '35px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0',
              backgroundColor: '#c9c6c6',
            }}
            onClick={() => { toggleModal(); setResumeOption("manual"); }}
            // Apply hover effect
            onMouseEnter={(e) => e.target.style.backgroundColor = "#a8a5a5"}
            onMouseLeave={(e) => e.target.style.backgroundColor = "#c9c6c6"}
          >
            <i className="fa fa-times"></i>
          </div>

          <div className="mb-3">
            <Label for="nameControlInput" className="form-label labeltype">
              Name
            </Label>
            <Input
              type="text"
              className="form-control"
              id="nameControlInput"
              placeholder="Enter your name"
              style={{ cursor: "not-allowed" }}
              name="studentName"
              value={formData.studentName}
              readOnly
            />
          </div>
          <div className="mb-3">
            <Label for="emailControlInput2" className="form-label labeltype">
              Email Address
            </Label>
            <Input
              type="email"
              className="form-control"
              id="emailControlInput2"
              placeholder="Enter your email"
              name="email"
              style={{ cursor: "not-allowed" }}
              value={formData.email}
              readOnly
            />
          </div>
          <div className="mb-3">
            <Label for="mobilenumber" className="form-label labeltype">
              Mobile Number
            </Label>
            <Input
              type="number"
              className="form-control"
              name="phoneNumber"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleOnChange}
              onKeyUp={NumberValidation}
              required
            />
            <span id="Number" />
          </div>
          <div className="mb-3">
            <Label for="coveringletter" className="form-label labeltype">
              Covering Letter
            </Label>
            <textarea
              className="form-control"
              id="coveringLatter"
              name="coveringLatter"
              rows="3"
              placeholder="Enter cover letter"
              value={formData.coveringLatter}
              onChange={handleOnChange}
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <Label className="form-label labeltype">Resume Upload</Label>
            <div>
              <label>
                <input
                  type="radio"
                  value="new"
                  name="resume"
                  id="resume"
                  checked={resumeOption === "new"}
                  onChange={() => handleResumeOptionChange("new")}
                />
                <span className="ml-1">
                  Recent Resume
                </span>
              </label>
            </div>
            {resumeOption === "new" && formData.resume && (
              <div>
                {/* <p>Recent Resume: {formData?.resume.name}</p> */}
                <div>
                  {formData?.resume && (
                    <>
                      <button
                        className="btn btn-success btn-sm mr-2"
                        onClick={() => {
                          handleViewResume(formData?.resume, `${formData?.studentName},`);
                        }}
                      >
                        <i className="fa fa-eye" /> View
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDownloadResume(formData?.resume, `${formData?.studentName}`)}
                      >
                        <i className="fa fa-download" /> Download
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="mt-2">
              <label>
                <input
                  type="radio"
                  value="manual"
                  checked={resumeOption === "manual"}
                  onChange={() => handleResumeOptionChange("manual")}
                />
                <span className="ml-1">
                  Upload  New Resume
                </span>
              </label>
              {resumeOption === "manual" && (
                <div className="mt-2">
                  <Input
                    type="file"
                    className="form-control"
                    id="resume"
                    name="resume"
                    onChange={handleOnChange}
                    accept=".pdf,.doc,.docx"
                    required
                  />
                </div>
              )}
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-100 mt-2"
            onClick={handleApply}
            disabled={!isFormValid()}
          >
            Apply Now
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};