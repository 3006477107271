import Repository from "./Repository"

class AuthRepository {

    // REGISTERATION REQUEST
    async postCompanyRegistrationInfo(params) {
        const response = await Repository.post('/company/company-reg', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }
    async postStudentRegistrationInfo(params) {
        const response = await Repository.post('/student/student-reg', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }
    async sendUserOtpReq(params) {
        const response = await Repository.post('/auth/send-otp-reg', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }
    async verifyUserOtpReq(params) {
        const response = await Repository.post('/auth/verify-otp', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    // LOGIN REQUESTS
    async sendUserLogInOtpReq(params) {
        const response = await Repository.post('/auth/send-otp-login', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }
    async loginReq(params) {
        const response = await Repository.post('/auth/login', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }


    //CANDIDATE REQUEST
    async getcandidateUsers() {
        const response = await Repository.get("/public/all-students")
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }
    //Contact Us 
    async getcontactus(params) {

        const response = await Repository.post("/public/contact-us", params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }
     //Notification store of candidate
     async getstorenotification(params) {
      
        const response = await Repository.post("/company/notification", params)
            .then((response) => {
              
            }).catch((error) => {
                
               
            })
            return response
    }
    //Notification update of candidate
    async getupdatenotification(params) {
      console.log(params);
        const response = await Repository.post("/student/update-notification", params)
            .then((response) => {
              
            }).catch((error) => {
                
               
            })
            return response
    }
     
     //Notification store of candidatea
     async getcandidatenotification() {
      
        const response = await Repository.get("/student/get-notification")
        .then((response) => {
            return response
        }).catch((error) => {
            console.log(error)
            return error
        })
    return response
        
    }

}

export default new AuthRepository()