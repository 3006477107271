import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Label,
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import '../assets/css/Candidate.css'
import { useJob } from '../../../hooks/useJob';
import Sidebar from "../layout/AdminLayout/Sidebar"

const UpdateEducationalDetails = ({ id }) => {
    const { educationaInfo, reqEducationalInfo, updateEducationalDetails } = useJob();
    const route = useNavigate();

    useEffect(() => {
        reqEducationalInfo();
    }, [])

    const [inputFields, setInputFields] = useState({
        secondaryEducation: [{
            schoolName: '',
            boardUniversity: '',
            startMonthYear: '',
            endMonthYear: '',
            gradeType: '',
            obtainedMarks: '',
            totalMarks: '',
            percentage: '',
            cgpa: ''
        }],
        higherEducation: [{
            collegeName: '',
            university: '',
            coursename: '',
            startDate: '',
            endDate: '',
            gradeTypes: '',
            obtainedMarks: '',
            totalMarks: '',
            percentage: '',
            cgpa: ''
        }]
    });

    useEffect(() => {
        if (educationaInfo) {
            setInputFields({
                secondaryEducation: educationaInfo?.[0]?.secondaryEducation.map((education) => ({
                    schoolName: education?.schoolName || '',
                    boardUniversity: education?.boardUniversity || '',
                    startMonthYear: education?.startMonthYear || '',
                    endMonthYear: education?.endMonthYear || '',
                    gradeType: education?.gradeType || '',
                    obtainedMarks: education?.obtainedMarks || '',
                    totalMarks: education?.totalMarks || '',
                    percentage: education?.percentage || '',
                    cgpa: education?.cgpa || ''
                })) || [educationaInfo],
                higherEducation: educationaInfo?.[0]?.higherEducation.map((education) => ({
                    collegeName: education?.collegeName || '',
                    university: education?.university || '',
                    coursename: education?.coursename || '',
                    startDate: education?.startDate || '',
                    endDate: education?.endDate || '',
                    gradeTypes: education?.gradeTypes || '',
                    obtainedMarks: education?.obtainedMarks || '',
                    totalMarks: education?.totalMarks || '',
                    percentage: education?.percentage || '',
                    cgpa: education?.cgpa || ''
                })) || [educationaInfo]
            });
        }
        else {
            console.error('education not found with id:', id);
        }
    }, [id, educationaInfo])

    const calculateCGPA = (gradeValue) => {
        return parseFloat(gradeValue);
    };

    const handleFormChange = (type, index, name, value) => {
        const data = { ...inputFields };
        if (name === 'gradeType' || name === 'gradeTypes') {
            data[type][index][name] = value;
            data[type][index].obtainedMarks = '';
            data[type][index].totalMarks = '';
            data[type][index].percentage = '';
        } else {
            data[type][index][name] = value;
        }
        if ((data[type][index].gradeType === 'CGPA' || data[type][index].gradeTypes === 'CGPA') && value !== '') {
            data[type][index].cgpa = parseFloat(value).toFixed(2);
        }

        if (data[type][index].gradeType === 'Percentage' || data[type][index].gradeTypes === 'Percentage') {
            const obtainedMarks = parseFloat(data[type][index].obtainedMarks);
            const totalMarks = parseFloat(data[type][index].totalMarks);

            if (!isNaN(obtainedMarks) && !isNaN(totalMarks) && totalMarks !== 0) {
                const percentage = (obtainedMarks / totalMarks) * 100;
                data[type][index].totalMarks = totalMarks;
                data[type][index].percentage = percentage.toFixed(2);
            }
        }
        setInputFields((prev) => ({
            ...prev,
            [type]: prev[type].map((edu, i) => (i === index ? { ...edu, [name]: value } : edu))
        }));
    };

    const addFields = (type) => {
        const newField = {
            collegeName: '',
            university: '',
            coursename: '',
            startDate: '',
            endDate: '',
            gradeTypes: '',
            // gradeValues: ''
            obtainedMarks: '',
            totalMarks: '',
            percentage: '',
            cgpa: ''
        };
        setInputFields((prev) => ({
            ...prev,
            [type]: [...prev[type], newField]
        }));
    };

    const removeFields = (type, index) => {
        if (index > 0) {
            const data = { ...inputFields };
            data[type].splice(index, 1);
            setInputFields(data);
        }
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        console.log("update data-->", inputFields);
        updateEducationalDetails(inputFields)
    };

    const handleCancelClick = () => {
        route('/candidatedashboard');
    };

    return (
        <>
            <div>
                <Sidebar />
            </div>
            <div>
                <Container className='mt-5 mb-3 my-auto'>
                    <Card className="side-border ">
                        <CardBody className='side-border '>
                            <div className="mt-2">
                                <h2>Update Educational Details</h2><hr></hr>
                                <h5>Secondary Education:</h5>
                                <Form onSubmit={handleOnSubmit}>

                                    {inputFields.secondaryEducation.map((edu, index) => (
                                        <div key={index}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="schoolName" className="form-label labeltype">
                                                    Name of School:
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="schoolName"
                                                    name="schoolName"
                                                    value={edu.schoolName}
                                                    onChange={(event) => handleFormChange("secondaryEducation", index, 'schoolName', event.target.value)}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup className="mb-3">
                                                <Label htmlFor="boardUniversity" className="form-label labeltype">
                                                    Name of Board:
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="boardUniversity"
                                                    name="boardUniversity"
                                                    value={edu.boardUniversity}
                                                    onChange={(event) => handleFormChange("secondaryEducation", index, 'boardUniversity', event.target.value)}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup className="mb-3">
                                                <Label htmlFor="startMonthYear" className="form-label labeltype">
                                                    Start Date:
                                                </Label>
                                                <Input
                                                    type="date"
                                                    className="form-control"
                                                    id="startMonthYear"
                                                    name="startMonthYear"
                                                    value={edu.startMonthYear}
                                                    onChange={(event) => handleFormChange("secondaryEducation", index, 'startMonthYear', event.target.value)}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup className="mb-3">
                                                <Label htmlFor="endMonthYear" className="form-label labeltype">
                                                    End Date:
                                                </Label>
                                                <Input
                                                    type="date"
                                                    className="form-control"
                                                    id="endMonthYear"
                                                    name="endMonthYear"
                                                    value={edu.endMonthYear}
                                                    required
                                                    onChange={(event) => handleFormChange("secondaryEducation", index, 'endMonthYear', event.target.value)}

                                                />
                                            </FormGroup>

                                            <FormGroup className="mb-3">
                                                <Label className="form-label labeltype">
                                                    Select CGPA or Percentage:
                                                </Label>
                                                <div className="form-check">
                                                    <Input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id={"cgpaRadio-secondary-${index}"}
                                                        name={"gradeType-secondary-${index}"}
                                                        value="CGPA"
                                                        checked={edu.gradeType === 'CGPA'}
                                                        onChange={() => handleFormChange("secondaryEducation", index, 'gradeType', 'CGPA')}
                                                        required
                                                    />
                                                    <Label className="form-check-label" htmlFor={"cgpaRadio-secondary-${index}"}>
                                                        CGPA
                                                    </Label>
                                                </div>

                                                <div className="form-check">
                                                    <Input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id={"percentageRadio-secondary-${index}"}
                                                        name={"gradeType-secondary-${index}"}
                                                        value="Percentage"
                                                        checked={edu.gradeType === 'Percentage'}
                                                        onChange={() => handleFormChange("secondaryEducation", index, 'gradeType', 'Percentage')}
                                                    />
                                                    <Label className="form-check-label" htmlFor={"percentageRadio-secondary-${index}"}>
                                                        Percentage
                                                    </Label>
                                                </div>
                                            </FormGroup>


                                            {edu.gradeType === 'CGPA' && (
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor={`cgpa-${index}`} className="form-label labeltype">
                                                        CGPA:
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        id={`cgpa-${index}`}
                                                        name={`cgpa-${index}`}
                                                        value={isNaN(edu.cgpa) ? '' : parseFloat(edu.cgpa).toString()}
                                                        onChange={(event) => handleFormChange("secondaryEducation", index, 'cgpa', event.target.value)}
                                                    />
                                                </FormGroup>
                                            )}


                                            {edu.gradeType === 'Percentage' && (
                                                <>
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="obtainedMarks" className="form-label labeltype">
                                                            Obtained Marks:
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="obtainedMarks"
                                                            name="obtainedMarks"
                                                            value={edu.obtainedMarks}
                                                            onChange={(event) => handleFormChange("secondaryEducation", index, 'obtainedMarks', event.target.value)}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="totalMarks" className="form-label labeltype">
                                                            Total Marks:
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="totalMarks"
                                                            name="totalMarks"
                                                            value={edu.totalMarks}
                                                            onChange={(event) => handleFormChange("secondaryEducation", index, 'totalMarks', event.target.value)}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="percentage" className="form-label labeltype">
                                                            Percentage:
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="percentage"
                                                            name="percentage"
                                                            value={edu.percentage || ''}
                                                            readOnly
                                                        />
                                                    </FormGroup>
                                                </>
                                            )}
                                        </div>
                                    ))}<br></br>


                                    <i
                                        className="fas fa-plus float-right"
                                        style={{ color: "green", padding: "4px", fontSize: "2em", cursor: "pointer" }}
                                        onClick={() => addFields('higherEducation')}
                                    >
                                    </i>

                                    {inputFields.higherEducation.map((edu, index) => (
                                        <div key={index}>
                                            <FormGroup className="mb-3">
                                                <h5>Higher Education:</h5>
                                                <Label htmlFor="collegeName" className="form-label labeltype">
                                                    College Name:
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="collegeName"
                                                    name="collegeName"
                                                    value={edu.collegeName}
                                                    onChange={(event) => handleFormChange("higherEducation", index, 'collegeName', event.target.value)}
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="university" className="form-label labeltype">
                                                    Name of Board/University:
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="university"
                                                    name="university"
                                                    value={edu.university}
                                                    onChange={(event) => handleFormChange("higherEducation", index, 'university', event.target.value)}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup className="mb-3">
                                                <Label htmlFor="coursename" className="form-label labeltype">
                                                    Course Name:
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="coursename"
                                                    name="coursename"
                                                    value={edu.coursename}
                                                    onChange={(event) => handleFormChange("higherEducation", index, 'coursename', event.target.value)}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup className="mb-3">
                                                <Label htmlFor="startDate" className="form-label labeltype">
                                                    Start Date:
                                                </Label>
                                                <Input
                                                    type="date"
                                                    className="form-control"
                                                    id="startDate"
                                                    name="startDate"
                                                    value={edu.startDate}
                                                    onChange={(event) => handleFormChange("higherEducation", index, 'startDate', event.target.value)}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup className="mb-3">
                                                <Label htmlFor="endMonthYear" className="form-label labeltype">
                                                    End Date:
                                                </Label>
                                                <Input
                                                    type="date"
                                                    className="form-control"
                                                    id="endDate"
                                                    name="endDate"
                                                    value={edu.endDate}
                                                    required
                                                    onChange={(event) => handleFormChange("higherEducation", index, 'endDate', event.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup className="mb-3">
                                                <Label className="form-label labeltype">
                                                    Select CGPA or Percentage:
                                                </Label>
                                                <div className="form-check">
                                                    <Input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id={`cgpaRadio-higher-${index}`}
                                                        name={`gradeTypes-higher-${index}`}
                                                        value="CGPA"
                                                        checked={edu.gradeTypes === 'CGPA'}
                                                        onChange={() => handleFormChange("higherEducation", index, 'gradeTypes', 'CGPA')}
                                                        required
                                                    />
                                                    <Label className="form-check-label" htmlFor={`cgpaRadio-higher-${index}`}>
                                                        CGPA
                                                    </Label>
                                                </div>

                                                <div className="form-check">
                                                    <Input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id={`percentageRadio-higher-${index}`}
                                                        name={`gradeTypes-higher-${index}`}
                                                        value="Percentage"
                                                        checked={edu.gradeTypes === 'Percentage'}
                                                        onChange={() => handleFormChange("higherEducation", index, 'gradeTypes', 'Percentage')}
                                                        required
                                                    />
                                                    <Label className="form-check-label" htmlFor={`percentageRadio-higher-${index}`}>
                                                        Percentage
                                                    </Label>
                                                </div>

                                            </FormGroup>

                                            {edu.gradeTypes === 'CGPA' && (
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor={`cgpa-${index}`} className="form-label labeltype">
                                                        CGPA:
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        id={`cgpa-${index}`}
                                                        name={`cgpa-${index}`}
                                                        value={isNaN(edu.cgpa) ? '' : parseFloat(edu.cgpa).toString()}
                                                        onChange={(event) => handleFormChange("higherEducation", index, 'cgpa', event.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            )}


                                            {edu.gradeTypes === 'Percentage' && (
                                                <>
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="obtainedMarks" className="form-label labeltype">
                                                            Obtained Marks:
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="obtainedMarks"
                                                            name="obtainedMarks"
                                                            value={edu.obtainedMarks}
                                                            onChange={(event) => handleFormChange("higherEducation", index, 'obtainedMarks', event.target.value)}
                                                            required
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="totalMarks" className="form-label labeltype">
                                                            Total Marks:
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="totalMarks"
                                                            name="totalMarks"
                                                            value={edu.totalMarks}
                                                            onChange={(event) => handleFormChange("higherEducation", index, 'totalMarks', event.target.value)}
                                                            required
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="percentage" className="form-label labeltype">
                                                            Percentage:
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="percentage"
                                                            name="percentage"
                                                            value={edu.percentage || ''}
                                                            readOnly
                                                        />
                                                    </FormGroup>
                                                </>
                                            )}


                                            {index > 0 && (
                                                <i
                                                    className="fas fa-trash-alt float-right"
                                                    style={{ color: "red", padding: "4px", fontSize: "2em", cursor: "pointer" }}
                                                    onClick={() => removeFields('higherEducation', index)}
                                                />
                                            )}

                                        </div>
                                    ))}

                                    <Button type="submit" className='btn btn-success mb-3 ml-2'>Update</Button>
                                    <Button className='btn btn-success mb-3 ml-3' onClick={handleCancelClick}>Cancel</Button>
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    );
}

export default UpdateEducationalDetails