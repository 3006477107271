import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

export const AuthProvider = ({ children }) => {
    const { isAuthenticated } = useSelector((state) => state.authReducer)
    // console.log(isAuthenticated)
    if (!isAuthenticated) {
        return <Navigate to='/signin' replace />
    }
    return children
}

