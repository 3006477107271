import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import { Sidebar } from "../layout/AdminLayout/Sidebar";
import Pagination from "../../Jobs/JobList2/Pagination";
import { useJob } from "../../../hooks/useJob";
import { BsPerson, BsPhone, BsBriefcaseFill } from "react-icons/bs";
import { MdMailOutline } from "react-icons/md";

const ShortlistedCandidate = () => {
  const { getjobApplication, applyjob } = useJob();
  const [shortlistcandidate, setShortlistcandidate] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    getjobApplication();
  }, []);

  // Check if jobcount is an array and set the candidate accordingly
  useEffect(() => {
    if (Array.isArray(applyjob)) {
      const shortlistcandidate = applyjob.filter(
        (application) => application?.jobStatus === "shortlisted"
      );
      setShortlistcandidate(shortlistcandidate);
    }
  }, [applyjob]);

  const reversedCandidate = [...shortlistcandidate].reverse();

  // Filter candidates based on search term
  const filteredCandidates = reversedCandidate.filter(
    (candidate) =>
      candidate?.candidateId.firstName
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      candidate?.jobId?.jobTitle
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  // Get current jobs
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentCandidate = filteredCandidates.slice(
    indexOfFirstJob,
    indexOfLastJob
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <div >
        <Sidebar />
      </div>
      <Container>
        <Row className="mt-4 ml-4 mr-2 ">
          <Col xs="12" sm="6" md="6" lg="4">
            <FormGroup>
              <h6 className="ml-2">Search Candidate</h6>
              <div className="input-group rounded">
                <Input
                  type="search"
                  className="form-control rounded w-100"
                  placeholder="Search by candidate name or job title"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}

                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <div className="p-3 align-items-center">
          <Row className="justify-content-center">
            {Array.isArray(currentCandidate) && currentCandidate.length > 0 ? (
              currentCandidate?.map((applyjobs, index) => (

                <React.Fragment key={applyjobs?._id}>
                  {applyjobs?.jobId ? (
                    <Col md={6}>
                      <div
                        key={applyjobs?._id}
                        className={
                          applyjobs.length >= 0
                            ? "candidate-list-box bookmark-post card mt-4 "
                            : "candidate-list-box card mt-4"
                        }
                      >

                        <div className="">
                          <Row className="align-items-center ">
                            <Col lg={5}>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-2 mb-0 mt-1 p-2">
                                <div className='mb-2  text-center mt-2 mt-sm-2'>
                                  <h5> <BsPerson className="mr-2 text-warning" /> {applyjobs?.candidateId.firstName} {applyjobs?.candidateId.lastName}</h5>
                                </div>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-2 mb-0 mt-1 p-2">
                                <div className='mb-2 text-center mt-2 mt-sm-2'>
                                  <MdMailOutline className="mr-2 text-danger" /> {applyjobs?.candidateId.email}
                                </div>
                              </div>
                            </Col>

                            <Col lg={5}>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-2 mb-0 mt-1 p-2">
                                <div className='mb-2 text-center mt-2 mt-sm-2'>
                                  <BsBriefcaseFill className="mr-2 text-primary" />  {applyjobs?.jobId?.jobTitle}
                                </div>
                              </div>
                            </Col>
                            <Col lg={5}>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-2 mb-0 mt-1 p-2">
                                <div className='mb-2 text-center mt-2 mt-sm-2'>
                                  <h6> <BsPhone className="m-2 text-blue" />  {applyjobs?.phoneNumber}</h6>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    null// Optional: Render alternative content or apply styling when jobId is null

                  )}
                  {index % 2 !== 0 && <div className="w-100"></div>}
                </React.Fragment>

              ))
            ) : (
              <div className="text-center">
                <h5>No Candidate Found</h5>
              </div>
            )}
          </Row>
        </div>

        <Pagination
          jobsPerPage={jobsPerPage}
          totalJobs={filteredCandidates.length}
          currentPage={currentPage}
          paginate={paginate}
        />
      </Container>
    </>
  );
};
export default ShortlistedCandidate;