import React, { useRef, useState } from 'react'
import 
{
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
const Profile = () => {
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedAge, setSelectedAge] = useState('');
  const [selectedQulification, setSelectedQulification] = useState('');
  const [SelectedLanguge, setSelectedLanguge] = useState('');
  const [selectedSalrytype, setSelectedSalrytype] = useState('');
  const [selectedProfilestatus, setSelectedProfilestatus] = useState('');
  const [SelectedCategory, setSelectedCategory] = useState('');
  const [isShown, setIsShown] = useState(false);
  const handleSelectChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const Age = (event) => {
    setSelectedAge(event.target.value);
  };
  const Qulification = (event) => {
    setSelectedQulification(event.target.value);
  };
  const Category = (event) => {
    setSelectedCategory(event.target.value);
  };
  const Salarytype = (event) => {

    setSelectedSalrytype(event.target.value);
  };
  const Profile = (event) => {

    setSelectedProfilestatus(event.target.value);
  };
  const laguage = (event) => {

    setSelectedLanguge(event.target.value);
  };

  const handleClick = () => {
    //open file input box on click of another element
    inputRef.current.click();
  };

  const inputRef = useRef(null);

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    event.target.value = null;
  };

  return (
    <>
      <div className='bg-light'>
        <Container className='bg-gradient-light ml-2 shadow mt-3'>
          <h1 className='mb-3 ml-2'>Edit Profile</h1>
              <Card className="bg-gradient-light shadow border-secondary border-0">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>

                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        size="sm"
                      >
                        Settings
                      </Button>
                    </Col>
                    <Col className="text-left" xs="4" style={{ display: 'none' }} ref={inputRef} type="file">
                      <Button
                        color="primary"
                        href="#pablo"

                        size="sm"
                        onClick={handleClick}
                      >
                        Browse
                      </Button>
                    </Col><br /><br />
                    <div>
                      <input style={{ display: 'none' }} ref={inputRef} type="file" />
                      <button onClick={handleClick} type="button" className="btn btn-primary">Browse</button>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h5 className="heading-small text-muted mb-4 ml-4">
                      User information
                    </h5>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Full Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              size="lg"
                              id="input-username"
                              placeholder="Username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Date of Birth
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              size="lg"
                              placeholder="jesse@example.com"
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Gender
                            </label>
                            <br />

                            <select value={selectedGender} onChange={handleSelectChange} class="form-select bg-white" >

                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Others</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Age
                            </label>
                            <br />

                            <select value={selectedAge} onChange={Age} class="form-select bg-white" >

                              <option value="10-20">10-20</option>
                              <option value="20-30">20-30</option>
                              <option value="30-40">30-40</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Phone Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="eg.abc@123gmail.com"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Qualification
                            </label>
                            <br />

                            <select value={selectedQulification} onChange={Qulification} class="form-select bg-white" >

                              <option value=">Associate Degree">Associate Degree</option>
                              <option value="Bachelor Degree">Bachelor Degree</option>
                              <option value="Master's Degree">Master's Degree</option>
                              <option value="Doctorate Degree">Doctorate Degree</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Languge
                            </label>
                            <br />

                            <select value={SelectedLanguge} onChange={laguage} class="form-select bg-white" >

                              <option value="Marathi">Marathi</option>
                              <option value="English">English</option>
                              <option value="Hindi">Hindi</option>
                              <option value="Turkish">Turkish</option>
                              <option value="Japnese">Japnese</option>
                              <option value="French">French</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Salary Type
                            </label>
                            <br />

                            <select value={selectedSalrytype} onChange={Salarytype} class="form-select bg-white" >
                              <option value="Yearly">Yearly</option>
                              <option value=" Monthaly">Monthaly</option>
                              <option value="Weekly">Weekly</option>
                              <option value="Daily">Daily</option>
                              <option value="Hourly">Hourly</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Salary⟨₹⟩
                            </label>
                            <Input
                              className="form-control-alternative"
                             
                              id="input-username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Show My Profile
                            </label>
                            <br />

                            <select value={selectedProfilestatus} onChange={Profile} class="form-select bg-white" >

                              <option value="Hide">Hide</option>
                              <option value="Show">Show</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Category
                            </label>
                            <br />

                            <select value={SelectedCategory} onChange={Category} class="form-select bg-white" >

                              <option value="Addvertising">Addvertising</option>
                              <option value="Application">Application</option>
                              <option value="Customer">Customer</option>
                              <option value="Design">Design</option>
                              <option value="Developer">Developer</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Pune"
                              id="input-city"
                              placeholder="City"
                              type="text"
                              size="lg"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="India"
                              id="input-country"
                              placeholder="Country"
                              type="text"
                              size="lg"
                            />
                          </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Job Tittle
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              placeholder="Job Tittle"
                              type="text"
                              size="lg"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                    </div>
                    <hr className="my-4" />
                    {/* Description */}
                    <h5 className="heading-small text-muted mb-4 ml-4">Discription</h5>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>Discription</label>
                        <Input
                          className="form-control-alternative"
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </div>
                  </Form>
                </CardBody>
              </Card>
        
          {/*       
        <div className="container bg-primary m-0">
      <div className="row">
        <div className="col-md-6">
          <div className=" text-white p-3"> 
            <h2>Bootstrap Styled Div</h2>
            <p>This div is styled using Bootstrap classes.</p>
          </div>
        </div>
      </div>
    </div> */}
          <Button className='btn btn-success mb-3 ml-5'>Save Profile</Button>
        </Container>
        {/* <Button className='btn btn-danger' >Save Profile</Button> */}
      </div>
    </>
  )
}

export default Profile