import React, { useState } from "react";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import signInImage from "../../assets/images/auth/sign-in.png";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { AuthFields } from "./Modules/AuthFields";
import { useEffect } from "react";

const SignIn = () => {
  document.title = "Sign In | Fresherszone";
  const route = useNavigate();
  const { loading, isOtpSent, isOtpReSent, logIn, sendUserLogInOtp, reSendUserLogInOtp } = useAuth()
  const [isOtpExp, setIsOtpExp] = useState(true);
  const [formData, setFormData] = useState({
    userName: "",
    otp: "",
    role: "student"
  })

  const handleOnChange = (e) => {
    let { name, value } = e.target
    setFormData((pre) => ({ ...pre, [name]: value }))
  }

  const handleOnSendOtpReq = (e) => {
    sendUserLogInOtp({
      email: formData.email,
      role: formData.role,
    });
  };

  const handleOnReSendOtpReq = (e) => {
    reSendUserLogInOtp({
      email: formData.email,
      role: formData.role,
    });
    setFormData((prev) => ({ ...prev, otp: "" }))
  };

  const handleOnVerifyOtpReq = async (e) => {
    e.preventDefault();
    await logIn(formData);
    const redirectToJobId = sessionStorage.getItem('redirectToJobId');
    // Redirect to job details page if there is a valid job ID
    if (redirectToJobId && redirectToJobId !== 'null' && redirectToJobId !== '') {
      route(`/jobdetails?id=${redirectToJobId}`);
      sessionStorage.removeItem('redirectToJobId'); // Clear the stored job details
    } else {
      // If no valid job ID, navigate to the home page
      route('/');
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault()
    // sendUserOtp(formData)
  }

  const authProps = {
    loading,
    formData,
    isOtpSent,
    isOtpExp,
    setIsOtpExp,
    handleOnChange,
    handleOnSendOtpReq,
    handleOnReSendOtpReq,
    handleOnVerifyOtpReq,
  };

  useEffect(() => {
    setIsOtpExp(false);
  }, [isOtpReSent]);

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <h4>Freshers Zone</h4>
                            </Link>
                            <div className="mt-5">
                              <img
                                src={signInImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 h-100 text-white">
                            <div className="text-white mb-2">
                              <h3>SignIn</h3>
                            </div>
                            <div className="select_role" >
                              <button
                                name="role"
                                value="student"
                                onClick={handleOnChange}
                                style={{
                                  width:'100px',
                                  padding: '2px',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  backgroundColor: formData.role === 'student' ? 'white' : 'transparent',
                                  color: formData.role === 'student' ? 'black' : 'white'
                                }}
                                type="button"
                              >
                                Candidate
                              </button>
                              <button
                                name="role"
                                value="company"
                                onClick={handleOnChange}
                                style={{
                                  width:'100px',
                                  padding: '2px',
                                  border: '1px solid #ccc',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                  backgroundColor: formData.role === 'company' ? 'white' : 'transparent',
                                  color: formData.role === 'company' ? 'black' : 'white'
                                }}
                                type="button"
                              >
                                Company
                              </button>
                            </div>

                            <div className="w-100">
                              <Form onSubmit={handleOnSubmit} className="auth-form">
                                <AuthFields {...authProps} />
                              </Form>
                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  Don't have an account ?{" "}
                                  <Link
                                    to="/signup"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    Sign Up{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
