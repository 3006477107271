export const getIconForCategory = (categoryName) => {
    switch (categoryName.toLowerCase()) {
        case 'accounting & finance':
          return 'fa-solid:calculator';
        case 'admin support':
          return 'fa-solid:user-tie';
        case 'administrative officer':
          return 'fa-solid:briefcase';
        case 'animal care worker':
          return 'fa-solid:paw';
        case 'bank jobs':
          return 'fa-solid:university';
        case 'catering & tourism':
          return 'fa-solid:utensils';
        case 'customer service':
          return 'fa-solid:headset';
        case 'data entry job':
          return 'fa-solid:keyboard';
        case 'defence jobs':
          return 'fa-solid:shield-alt';
        case 'digital marketing':
          return 'mdi:target-account';
        case 'diploma jobs':
          return 'fa-solid:graduation-cap';
        case 'forest worker':
          return 'fa-solid:tree';
        case 'government jobs':
          return 'fa-solid:building';
        case 'health care jobs':
          return 'fa-solid:heartbeat';
        case 'it / software jobs':
          return 'mdi:desktop-classic';
        case 'logistics / transportation':
          return 'fa-solid:truck';
        case 'manufacturing & production':
          return 'fa-solid:industry';
        case 'marketing & advertising':
          return 'fa-solid:ad';
        case 'performing arts & media':
          return 'fa-solid:microphone';
        case 'project manager':
          return 'fa-solid:briefcase';
        case 'purchasing manager':
          return 'fa-solid:shopping-cart';
        case 'retail & customer services':
          return 'fa-solid:store';
        case 'sports jobs':
          return 'fa-solid:futbol';
        case 'teaching jobs':
          return 'fa-solid:chalkboard-teacher';
        default:
          return 'fa-solid:question-mark';
      }
  };

  export const categoryList = [
    'Accounting & Finance',
    'Admin Support',
    'Administrative Officer',
    'Animal Care Worker',
    'Bank Jobs',
    'Catering & Tourism',
    'Customer Service',
    'Data Entry Job',
    'Defence Jobs',
    'Digital Marketing',
    'Diploma Jobs',
    'Forest Worker',
    'Government Jobs',
    'Health Care Jobs',
    'It / Software Jobs',
    'Logistics / Transportation',
    'Manufacturing & Production',
    'Marketing & Advertising',
    'Performing Arts & Media',
    'Project Manager',
    'Purchasing Manager',
    'Sports Jobs',
    'Retail & Customer Services',
    'Teaching Jobs',
  ];