import React, { Suspense } from "react";
import { userRoutes, authRoutes, adminRoutes, candidateRoutes } from "./allRoutes";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./AuthProvider";

/* Layout */
const CommonLayout = React.lazy(() => import('../Layout/CommonLayout/index'))
const AuthLayout = React.lazy(() => import('../Layout/AuthLayout'))
const AdminLayout = React.lazy(() => import('../pages/Admin/layout/AdminLayout/index'))
const CandidateLayout = React.lazy(() => import('../pages/Candidate/layout/AdminLayout/index'))

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
};

const Index = () => {
  return (
    <React.Fragment>
      <Suspense fallback={Loader()}>
        <Routes>
          <Route>
            {authRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<AuthLayout>{route.component}</AuthLayout>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>

          <Route>
            {userRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  // <AuthProvider >
                    <CommonLayout>
                      {route.component}
                    </CommonLayout>
                  // </AuthProvider>
                }
                key={idx}
                exact={true}
              />
            ))}
          </Route>
          <Route>
            {adminRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <AuthProvider>
                    <AdminLayout>{route.component}</AdminLayout>
                  </AuthProvider>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>

          <Route>
            {candidateRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <AuthProvider>
                    <CandidateLayout>{route.component}</CandidateLayout>
                  </AuthProvider>
                }
                key={idx}
                exact={true}
              />
            ))}
          </Route>

        </Routes>
      </Suspense>
    </React.Fragment>
  );
};

export default Index;
