import { Col, Row } from "reactstrap";
import Select from "react-select";

const options = [
  { value: 'Accounting & Finance', label: 'Accounting & Finance' },
  { value: 'Admin Support', label: 'Admin Support' },
  { value: 'Customer Service', label: 'Customer Service' },
  { value: 'Bank Jobs', label: 'Bank Jobs' },
  { value: 'Data Entry Job', label: 'Data Entry Job' },
  { value: 'Purchasing Manager', label: 'Purchasing Manager' },
  { value: 'Project Manager', label: 'Project Manager' },
  { value: 'Marketing & Advertising', label: 'Marketing & Advertising' },
  { value: 'Catering & Tourism', label: 'Catering & Tourism' },
  { value: 'Government Jobs', label: 'Government Jobs' },
  { value: 'Defence Jobs', label: 'Defence Jobs' },
  { value: 'Teaching Jobs', label: 'Teaching Jobs' },
  { value: 'Retail & Customer Services', label: 'Retail & Customer Services' },
  { value: 'Diploma Jobs', label: 'Diploma Jobs' },
  { value: 'Health Care Jobs', label: 'Health Care Jobs' },
  { value: 'Manufacturing & Production', label: 'Manufacturing & Production' },
  { value: 'Performing Arts & Media', label: 'Performing Arts & Media' },
  { value: 'It / Software Jobs', label: 'It / Software Jobs' },
  { value: 'Logistics / Transportation', label: 'Logistics / Transportation' },
  { value: 'Sports Jobs', label: 'Sports Jobs' },
  { value: 'Forest Worker', label: 'Forest Worker' },
  { value: 'Animal Care Worker', label: 'Animal Care Worker' },
  { value: 'Digital Marketing', label: 'Digital Marketing' },
  { value: 'Administrative Officer', label: 'Administrative Officer' }
];

const sortedOptions = [...options].sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
const Selected = ({ selectedOptions, setSelectedOptions }) => {
  
  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  };

  return (
    <>
      <Row className="mt-4">
        <Col lg={12}>
          <h6 className="fs-16 mb-3">Your Selected</h6>
          <div className="selecte-tag position-relative">
            <Select
              isMulti
              color="primary"
              name="colors"
              options={sortedOptions}
              value={selectedOptions}
              onChange={handleSelectChange}
              className="selecte-tag position-relative"
              classNamePrefix="select"
              isSearchable={true}
            />
          </div>
        </Col>
        {/* <Col lg={12}>
          <div className="mt-4">
            <h6 className="fs-16">Showing {filteredOptions.length} results</h6>
            <ul>
              {filteredOptions.map((job) => (
                <li key={job.value}>{job.label}</li>
              ))}9+
            </ul>
          </div>
        </Col> */}
      </Row>
    </>
  );
};

export default Selected;
