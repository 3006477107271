import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import CompanyLogo from "../../assets/images/fresherzone-logo.png";
import CallIcon from '@mui/icons-material/Call';

const Footer = () => {
  const footer = [
    {
      id: 1,
      menu: [
        {
          id: 1,
          link: "/aboutus",
          subTitle: "About Us"
        },
        {
          id: 2,
          link: "/contact",
          subTitle: "Contact Us"
        },
        {
          id: 3,
          link: "/Grievances",
          subTitle: "Grievances"
        },
        {
          id: 4,
          link: "/sitemap",
          subTitle: "Sitemap"
        },
      ]
    },

    {
      id: 2,
      menu: [
        {
          id: 1,
          link: "/privacyandpolicy",
          subTitle: "Privacy Policy"
        },
        {
          id: 2,
          link: "/termsandconditions",
          subTitle: "Terms & Conditions"
        },
        {
          id: 3,
          link: "/trustandsafety",
          subTitle: "Trust & Safety"
        },
      ]
    },
    {
      id: 3,
      menu: [
        {
          id: 1,
          link: "/fraudalert",
          subTitle: "Fraud Alert"
        },
        {
          id: 2,
          link: "/reportanissue",
          subTitle: "Report An Issue"
        },
        {
          id: 3,
          link: "/contact",
          subTitle: "Help Center"
        },
        {
          id: 4,
          link: "/faqs",
          subTitle: "FAQ'S"
        },

      ]
    }
  ];
  // const footerIcons = [
  //   {
  //     id: 1,
  //     socialIcon: "uil uil-facebook-f"
  //   },
  //   {
  //     id: 2,
  //     socialIcon: "uil uil-linkedin-alt"
  //   },
  //   {
  //     id: 3,
  //     socialIcon: "uil uil-google"
  //   },
  //   {
  //     id: 4,
  //     socialIcon: "uil uil-twitter"
  //   }
  // ];
  return (
    <React.Fragment>

      <section className="bg-footer">
        <Container>
          <Row>
            <Col lg={4}> 
              <div className="footer-item mt-4 mt-lg-0 mr-5">
                <img src={CompanyLogo} height="50" width="" alt="image not found" />
                <p className="text-white-50 mt-4">
                  402, BG Arcade, Near Sun Orbit, Sun City Road, Sinhgad Rd, Anand Nagar, Pune, Maharashtra 411051
                </p>
                <p className="text-white-50 mt-4">
                  <CallIcon />
                  <span className="ml-2">
                    +91 86001 69319
                  </span>
                </p>

                {/* <p className="text-white mt-3">Follow Us on:</p>
                <ul className="footer-social-menu list-inline mb-0">
                  {footerIcons.map((footerIcondetails, key) => (
                    <li className="list-inline-item" key={key}>
                      <Link to="#">
                        <i className={footerIcondetails.socialIcon}></i>
                      </Link>
                    </li>
                  ))}
                </ul> */}
              </div>
            </Col>

            {footer.map((footerdetails, key) => (
              <Col lg={2} xs={6} key={key}>
                <div className="footer-item mt-4 mt-lg-0">
                  <p className="fs-16 text-white mb-4">{footerdetails.title}</p>
                  {(footerdetails.menu || []).map((menuInner, key) => (
                    <ul className="list-unstyled footer-list mb-0 mr-2 mt-1" key={key}>
                      <li>
                        <Link to={menuInner.link}>
                          <i className="mdi mdi-chevron-right"></i>{" "}
                          {menuInner.subTitle}
                        </Link>
                      </li>
                    </ul>
                  ))}
                </div>
              </Col>
            ))}
          </Row>


        </Container>
      </section>
      <div className="footer-alt">
        <Container>
          <Row>
            <Col lg={12}>
              <p className="text-white-50 text-center mb-0">
                All Rights Reserved &copy; {new Date().getFullYear()}
                {" "}by{" "}
                <Link
                  to="//globalion.in/"
                  target="_blank"
                  className="text-reset text-decoration-underline"
                >
                  Globalion Technology Solutions LLP
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Footer;
