import React from "react";
import { Validation } from "../../Components/Validation";
import { Input, Spinner } from "reactstrap";
import { Link } from "react-router-dom";

const {
    FirstnameValidation,
    LastnameValidation,
    NumberValidation,
    AadharValidation,
} = Validation();

export const BasicFieldsofStudent = ({ loading, formData, handleOnChange }) => {
  return (
    <>
      <div>
        <div className="mb-3">
          <label htmlFor="firstNameInput" className="form-label">
            First Name
          </label>
          <Input
            name="firstName"
            value={formData.firstName}
            onChange={handleOnChange}
            onKeyUp={FirstnameValidation}
            type="text"
            className="form-control"
            required
            id="firstNameInput"
            placeholder="Enter your first name"
          />{" "}
          <span id="first" />
        </div>
        <div className="mb-3">
          <label htmlFor="lastNameInput" className="form-label">
            Last Name
          </label>
          <Input
            name="lastName"
            value={formData.lastName}
            onChange={handleOnChange}
            onKeyUp={LastnameValidation}
            type="text"
            className="form-control"
            required
            id="lastNameInput"
            placeholder="Enter your last name"
          />
          <span id="last" />
        </div>
        <div className="mb-3">
          <label htmlFor="mobileNumber" className="form-label">
            Mobile Number
          </label>
          <Input
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleOnChange}
            onKeyUp={NumberValidation}
            type="number"
            className="form-control"
            required
            id="mobileNumber"
            placeholder="Enter your mobile number"
          />
          <span id="Number" />
        </div>
        <div className="mb-3">
          <label htmlFor="aadharNumber" className="form-label">
            Aadhar Number
          </label>
          <Input
            name="aadharNumber"
            value={formData.aadharNumber}
            onChange={handleOnChange}
            onKeyUp={AadharValidation}
            type="number"
            className="form-control"
            required
            id="aadharNumber"
            placeholder="Enter your aadhar number"
          />
          <span id="AadharNumber" />
        </div>
        <div className="mb-4">
          <div className="form-check">
            <Input
              name="agree"
              value={formData.agree == "true" ? "false" : "true"}
              onChange={handleOnChange}
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              required
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              I agree to the{" "}
              <Link to="#" className="text-white text-decoration-underline">
                Terms and conditions
              </Link>
            </label>
          </div>
        </div>
        <div className="text-center">
          <button
            disabled={loading}
            type="submit"
            className="btn btn-white btn-hover w-100"
          >
            {!loading ? (
              "Sign Up"
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </div>
    </>
  );
};
