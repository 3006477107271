import Repository from "./Repository"

class JobsRepository {

    // Post a job by company
    async postJob(params) {
        const response = await Repository.post('/company/job-post', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    // Post a job by company
    async alljoblist() {
        const response = await Repository.get('/public/all-jobs')
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async recentJobs() {
        const response = await Repository.get('/public/recent-jobs')
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async internshipjobs() {
        const response = await Repository.get('/public/internship-jobs')
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }


    async fetchCategories() {
        const response = await Repository.get('/public/categories')
            .then((response) => {
                return response
            }).catch((error) => {
                return error
            })
        return response
    }

    async randomJobs() {
        const response = await Repository.get('/public/random-jobs')
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async companyalljoblist() {
        const response = await Repository.get('/company/job-list')
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async companyjobdelete(id) {

        const response = await Repository.delete(`/company/job-delete/${id}`)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }


    async freelancerJobs() {
        const response = await Repository.get('/public/freelancer-jobs')
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async parttimeJobs() {
        const response = await Repository.get('/public/part-time-jobs')
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }


    async fulltimeJobs() {
        const response = await Repository.get('/public/full-time-jobs')
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }


    async Jobs() {
        const response = await Repository.get('/public/full-time-jobs')
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async updatejoblist(params) {
        try {
            const { id, jobInfo } = params
            const response = await Repository.patch(`/company/update-job/${id}`, jobInfo);
            return response;
        } catch (error) {
            console.error("Error updating job list:", error);
            throw error;
        }
    }

    async Jobscategorycount() {
        const response = await Repository.get('/public/job-count')
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async educationalDetails(params) {
        const response = await Repository.post('/student/educational-details', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async geteducationProfile(params) {
        const response = await Repository.get('/student/education-profile', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async updateEducationalDetails(data) {
        const response = await Repository.patch(`/student/update-educational-details`, data)
            .then((response) => {
                return response;
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async jobApplication(params) {
        const response = await Repository.post('/student/apply-job', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }
    async getjobApplication(companyId) {

        try {
            const params = { companyId };
            const response = await Repository.get('/company/applied-jobs-details', { params });
            return response;
        } catch (error) {
            console.error('Error in getjobApplication:', error);
            throw error;
        }
    }

    //candidate job apply
    async applyjobdetails(params) {
        const response = await Repository.get('/student/applied-jobs-details', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }

    async updateJobStatus(params) {
        const response = await Repository.patch('/company/update-job-status', params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response
    }
    async returnmonthwisecount(params) {
        const response = await Repository.get('/company/monthwisecount', params)
            .then((response) => {
                return response
            }).catch((error) => {
             
                return error
            })
        return response
    }

    //Notification store of company
    async getstorecompanynotification(params) {
        
        const response = await Repository.post("/company/company-notification", params)
            .then((response) => {
                console.log(response);
                return response
            }).catch((error) => {
                return error
            })
    }

    async getcompanynotification(params) {
        const response = await Repository.get("/company/company-getnotification", params)
            .then((response) => {
                return response
            }).catch((error) => {
               
                return error
            })
        return response

    }
    async updatecompanynotification(params) {
        const response = await Repository.post("/company/company-updatenotification", params)
            .then((response) => {
                return response
            }).catch((error) => {
                console.log(error)
                return error
            })
        return response

    }
}

export default new JobsRepository()