import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Row, Spinner } from "reactstrap";
import { useJob } from "../../../hooks/useJob";
import { baseURL } from "../../../repositories/Repository";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../JobList2/Pagination";
import { useSelector } from "react-redux";
import { JobApply } from "../../../components/shared/JobApply";
import { LuClock3 } from "react-icons/lu";
import { FaCalendar } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { useProfile } from "../../../hooks/useProfile";
import { sassNull } from "sass";

const JobVacancyList = ({
  searchLocation,
  selectedJobType,
  selectDateType,
  selectedJobCategory,

}) => {
  //Apply Now Model
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [profileStatus, setProfileStatus] = useState();
  const route = useNavigate();

  const { getalljobs, jobList } = useJob();
  const [currentPage, setCurrentPage] = useState(1);
  const { showprofile, profile } = useProfile();
  const jobsPerPage = 8;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getalljobs().then(() => {
      setLoading(false); // Set loading to false when data is fetched
    });
    showprofile().then(() => {
      setLoading(false); // Set loading to false when data is fetched
    });
  }, []);

  const locationHook = useLocation();
  const params = new URLSearchParams(locationHook.search);
  const jobSearchValue = params.get('jobSearch');
  const selectedCountry = params.get('country');
  const category = params.get('category');

  //Format timestamp difference to human-readable time ago string
  const formatTimestampWithDifference = (timestamp) => {
    // Convert timestamp to Date objects
    const date = new Date(timestamp);
    const currentTime = new Date();

    // Calculate time difference in milliseconds
    const timeDifference = currentTime - date;

    // Convert time difference to seconds, minutes, hours, and days
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // Return formatted time ago string based on the calculated difference
    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  };
  // formating date
  const formatDate = (dateform) => {
    const date = new Date(dateform);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };
  //count hours
  const isJobWithinLastXHours = (job, hours) => {
    const jobDate = new Date(job.postedAt);
    const timeDifference = new Date() - jobDate;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference <= hours;
  };
  //count days
  const isJobWithinLastXDays = (job, days) => {
    const jobDate = new Date(job.postedAt);

    const timeDifference = new Date() - jobDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference <= days;
  };
  //reverse all joblist
  const reversedJobs = Array.isArray(jobList) ? jobList.slice().reverse() : [];
  // All filteration logic
  const filteredJobs = (reversedJobs || []).filter((job) => {
    const jobTypeFilter = job.jobType;

    const isWithinSelectedDateType = Array.isArray(selectDateType) && selectDateType.some((dateType) => {
      switch (dateType) {
        case "lastHour":
          return isJobWithinLastXHours(job, 1);

        case "24hours":
          return isJobWithinLastXHours(job, 24);

        case "last_7_days":
          return isJobWithinLastXDays(job, 7);

        case "last_14_days":
          return isJobWithinLastXDays(job, 14);

        case "last_30_days":
          return isJobWithinLastXDays(job, 30);

        default:
          return isJobWithinLastXDays(job, 90);
      }
    });
    const isCategoryMatch =
      (!selectedJobCategory && !category) ||
      (job &&
        job.jobCategory &&
        job.jobCategory.some(
          (categoryObj) =>
            (selectedJobCategory &&
              categoryObj.category.toLowerCase().includes(selectedJobCategory.toLowerCase())) ||
            (category &&
              categoryObj.category.toLowerCase().includes(category.toLowerCase()))
        ));

    const hasMatchingLocation =
      job.jobLocations &&
      job.jobLocations.some((location) => {
        return (
          location &&
          location.location &&
          typeof location.location === "string" &&
          (
            (!searchLocation || (searchLocation && typeof searchLocation === "string" &&
              location.location.toLowerCase().includes(searchLocation.toLowerCase())))
            &&
            (!selectedCountry || (selectedCountry && typeof selectedCountry === "string" &&
              location.location.toLowerCase().includes(selectedCountry.toLowerCase())))
          )
        );
      });

    const isTypeMatch =
      !selectedJobType ||
      (jobTypeFilter &&
        jobTypeFilter.toLowerCase() === selectedJobType.toLowerCase());
    // Check if job name or company matches the search value
    const matchesSearchValue =
      (!jobSearchValue || (jobSearchValue && typeof jobSearchValue === "string" &&
        ((job.jobTitle && job.jobTitle.toLowerCase().includes(jobSearchValue.toLowerCase())) ||
          (job.companyId.basicInfo.companyName && job.companyId.basicInfo.companyName.toLowerCase().includes(jobSearchValue.toLowerCase())))
      ));
    return (
      hasMatchingLocation &&
      (hasMatchingLocation || !searchLocation) &&
      (hasMatchingLocation || !selectedCountry) &&
      (isTypeMatch || !selectedJobType) &&
      isWithinSelectedDateType &&
      isCategoryMatch &&
      matchesSearchValue
    );
  });

  const openApplyModal = (jobId) => {
    if (isAuthenticated) {
      setSelectedJobId(jobId);
      setApplyModalOpen(true);
    } else {
      // Store the job details in session storage before redirecting
      sessionStorage.setItem('redirectToJobId', jobId);
      toast.warning('You need to login to apply for this job.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // Delayed redirection to the sign-in page after 2 seconds (adjust the time as needed)
      setTimeout(() => {
        route('/signin');
      }, 2000);
    }
  };

  const closeApplyModal = () => {
    setSelectedJobId(null); // Reset the selected job ID when the modal is closed
    setApplyModalOpen(false);
  };

  // Apply pagination
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  useEffect(() => {
    if (profile && profile.role === 'student') {
      setProfileStatus(profile.role);
    } else if ((profile && profile.role === 'campany')) {
      setProfileStatus(profile.role);
    }
    else {
      setProfileStatus(profile);
    }
  }, [profile]);
  return (
    <React.Fragment>
      {loading ? (
        <div className="text-center mt-5">
          <Button
            color="primary"
            disabled
          >
            <Spinner size="sm">
              Loading...
            </Spinner>
            <span>
              {' '}Loading...
            </span>
          </Button>
        </div>
      ) : (
        <div>
          {currentJobs.length === 0 ? (
            <h5 className="text-center font-light">No jobs found</h5>
          ) : (
            currentJobs.map((job) => (
              <div
                key={job._id}
                className={
                  job.addclassNameBookmark === true
                    ? "candidate-list-box bookmark-post card mt-4 "
                    : "candidate-list-box card mt-4"
                }
              >
                {/* <div className="bookmark-label text-center">
                <Link to="#" className="align-middle text-white">
                  <i className="mdi mdi-star"></i>
                </Link>
              </div> */}
                <div className="p-3">
                  <Row className="align-items-center">
                    <Col md={2}>
                      <div
                        id="jobVacancyCompanyLogo"
                        className="text-center mb-4 mb-md-0"
                      >
                        <Link to={`/companydetails?id=${job?._id}&companyId=${job?.companyId?._id}`}>
                          {job?.companyId?.logo ? (
                            <div className="avatar-container">
                              <img
                                className="avatar-logo rounded-3"
                                src={`${baseURL}/${job?.companyId?.logo?.file}`}
                                alt="Company Logo"
                              />
                            </div>
                          ) : (
                            <div className="avatar-container">
                              <FontAwesomeIcon
                                icon={faLandmark}
                                className="avatar-logo"
                                color="#373840"
                                style={{ width: '80px', height: '80px' }} 
                              />
                            </div>
                          )}
                        </Link>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="mb-2 mb-md-0 text-center">
                        <h5 id="jobVacancyJob" className="fs-18 mb-0">
                          <Link
                            to={`/jobdetails?id=${job._id}`}
                            className="text-dark"
                          >
                            {job.jobTitle &&
                              job.jobTitle.split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')
                            }
                          </Link>
                        </h5>
                        <p
                          id="jobVacancyCompanyName"
                          className="text-muted fs-14 mb-0"
                        >
                          {job.companyId
                            ? job.companyId.basicInfo.companyName
                            : "N/A"}
                        </p>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="ml-2 md:ml-12 text-center">
                        <div className="">
                          {/* <i className="mdi mdi-map-marker text-primary inline-block align-middle"></i> */}
                        </div>
                        <p className="inline-block align-middle text-center">
                          <IoLocationOutline className="text-primary inline-block align-middle me-1 mb-1" />
                          {job.jobLocations
                            .map((location) => location.location)
                            .join(", ")}
                        </p>
                      </div>
                    </Col>

                    <Col md={2}>
                      {/* <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="text-primary me-1"
                    /> */}
                      {/* <span className="text-muted">
                      <FaCalendar />
                      {formatDate(job.postedAt)}
                    </span> */}
                      <div className="flex flex-col md:flex-row md:items-center md:space-x-2 mb-0 mt-2">
                        <div className="flex items-center text-muted">
                          {/* <i className="uil uil-clock-three text-primary me-1"></i> */}
                          <p className="text-nowrap inline-block align-middle text-center">
                            <FaCalendar className="text-success inline-block align-middle me-1" />
                            {formatDate(job.postedAt)}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row md:items-center md:space-x-2 mb-0 mt-2">
                        <div className="flex items-center text-muted">
                          {/* <i className="uil uil-clock-three text-primary me-1"></i> */}
                          <p className="text-nowrap inline-block align-middle text-center">
                            <LuClock3 className="text-danger inline-block align-middle me-1" />
                            {formatTimestampWithDifference(job.postedAt)}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="text-center mt-3">
                        <span
                          className={`badge fs-13 mt-1 mx-1 ${job.fullTime === true
                            ? "bg-success-subtle text-success"
                            : job.partTime === true
                              ? "bg-danger-subtle text-danger"
                              : job.freelance === true
                                ? "bg-primary-subtle text-light"
                                : "bg-primary text-light" // Default style
                            }`}
                        >
                          {job.jobType}
                        </span>
                        {(job.badges || []).map((badgeInner, key) => (
                          <span
                            className={
                              "badge " + badgeInner.badgeclassName + " fs-13 mt-1"
                            }
                            key={key}
                          >
                            {badgeInner.badgeName}
                          </span>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
                {
                  profileStatus === 'student' && (
                    <div className="p-2 bg-light">
                      <Row className="text-center">
                        <Col lg={2} md={3}>
                          <div>
                            <Link
                              to="#"
                              onClick={() => openApplyModal(job._id)}
                              className="primary-link"
                            >
                              Apply Now <i className="mdi mdi-chevron-double-right"></i>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                   {profileStatus === null && (
                    <div className="p-2 bg-light">
                      <Row className="text-center">
                        <Col lg={2} md={3}>
                          <div>
                            <Link
                              to="#"
                              onClick={() => openApplyModal(job._id)}
                              className="primary-link"
                            >
                              Apply Now <i className="mdi mdi-chevron-double-right"></i>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
              </div>
            ))
          )}
          <Pagination
            jobsPerPage={jobsPerPage}
            totalJobs={filteredJobs.length}
            currentPage={currentPage}
            paginate={paginate}
          />
          <div
            className="modal fade"
            id="applyNow"
            tabIndex="-1"
            aria-labelledby="applyNow"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              {/* Pass the selectedJobId to the JobApply component */}
              <JobApply
                isOpen={applyModalOpen}
                toggleModal={closeApplyModal}
                jobId={selectedJobId}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default JobVacancyList;
