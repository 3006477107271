import React, { useState } from "react";
import { Col, Input, Row } from "reactstrap";
import CountryOptions from "../../Home/SubSection/CountryOptions";
import JobType from "../../Home/SubSection/JobType";

const JobGridHeader = ({
  selectedTitles,
  setSelectedTitles,
  handleCountryChange,
  handleCityChange,
  onJobTypeChange }) => {

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const handleOnChange = (e) => {
    let { value } = e.target;
    setSelectedTitles(value);
  };

  const handleCountryChangeHeader = (value) => {
    setSelectedCountry(value);
    if (handleCountryChange) {
      handleCountryChange(value);
    }
  };

  const handleCityChangeHeader = (value) => {
    setSelectedCity(value);
    if (handleCityChange) {
      handleCityChange(value);
    }
  };
  return (
    <React.Fragment>
      <form action="#">
        <Row className="g-2">
          <Col lg={4} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-briefcase-alt"></i>
              <Input
                type="search"
                className="form-control filter-input-box"
                id="exampleFormControlInput1"
                placeholder="Job, company... "
                style={{ marginTop: "-10px" }}
                value={selectedTitles}
                onChange={handleOnChange}
              />
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-location-point"></i>
              <CountryOptions
                onCountryChange={handleCountryChangeHeader}
                onCityChange={handleCityChangeHeader}
              />
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-clipboard-notes"></i>
              <JobType onJobTypeChange={onJobTypeChange} />
            </div>
          </Col>
          {/* <Col lg={3} md={6}>
            <Link to="#" className="btn btn-primary w-100">
              <i className="uil uil-filter"></i> Fliter
            </Link>
          </Col> */}
        </Row>
      </form>
    </React.Fragment>
  );
};

export default JobGridHeader;
