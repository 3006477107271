import { useState } from "react"
import { toast } from 'react-toastify'
import JobsRepository from "../repositories/JobsRepository"
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';

export const useJob = () => {
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState(false)
    const [jobList, setJobList] = useState(null)
    const [recentJobs, setRecentJobs] = useState(null)
    const [randomJobs, setRandomJobs] = useState(null)
    const [companyjobList, setCompanyjobList] = useState(null)
    const [freelancerJobs, setFreelancerJobs] = useState(null)
    const [parttimeJobs, setPartTimeJobs] = useState(null)
    const [fullTimeJobs, setFullTimeJobs] = useState(null);
    const [joblistInfo, setjoblistInfo] = useState(null)
    const [internshipsJobs, setInternshipsJobs] = useState(null);
    const [categoriesCount, setCategoriesCount] = useState(false)
    const [educationaInfo, seteducationaInfo] = useState(null)
    const [appliedjobs, setAppliedJobs] = useState(null);
    const [applyjob, setapplyjob] = useState()
    const [monthwisecount, setMonthwisecount] = useState();
    const [companynotification, setCompanynotification] = useState();
    // const [jobApply, setJobApply] = useState(null)
    const route = useNavigate();

    const customToastStyle = {
        background: '#333',
        color: '#fff',
        borderRadius: '4px',
    };

    return {
        loading,
        jobList,
        recentJobs,
        categories,
        companyjobList,
        randomJobs,
        freelancerJobs,
        parttimeJobs,
        fullTimeJobs,
        internshipsJobs,
        joblistInfo,
        categoriesCount,
        educationaInfo,
        appliedjobs,
        applyjob,
        monthwisecount,
        customToastStyle,
        companynotification,
        // Job info

        postJobInfo: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.postJob(payload)
            if (response) {
                if (response.status == 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    route('/myjobs')
                }
                setLoading(false)
            }
        },

        fetchCategories: async () => {
            setLoading(true)
            const response = await JobsRepository.fetchCategories()
            if (response) {
                if (response.status == 200) {
                    setCategories(response.data.result)
                    setLoading(false)
                }
            }
        },
        getalljobs: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.alljoblist(payload)
            if (response.status === 200) {
                setJobList(response.data)
                setLoading(false)
            }
        },
        getRecentJobs: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.recentJobs(payload)
            if (response.status === 200) {
                setRecentJobs(response.data)
                setLoading(false)
            }
        },
        getRandomJobs: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.randomJobs(payload)
            if (response.status === 200) {
                setRandomJobs(response.data)
                setLoading(false)
            }
        },

        getjoblist: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.companyalljoblist(payload)
            if (response.status === 200) {
                setCompanyjobList(response.data)
                setLoading(false)
            }
        },

        getFreelancerJobs: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.freelancerJobs(payload)
            if (response.status === 200) {
                setFreelancerJobs(response.data)
                setLoading(false)
            }
        },

        getPartTimeJobs: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.parttimeJobs(payload)
            if (response.status === 200) {
                setPartTimeJobs(response.data)
                setLoading(false)
            }
        },

        getFullTimeJobs: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.fulltimeJobs(payload)
            if (response.status === 200) {
                setFullTimeJobs(response.data)
                setLoading(false)
            }

        },

        getInternshipsJobs: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.internshipjobs(payload)
            if (response.status === 200) {
                setInternshipsJobs(response.data)
                setLoading(false)
            }
        },

        getdeletejob: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.companyjobdelete(payload)
            if (response.status === 200) {
                toast.success(response.data.message, { style: customToastStyle })
                route('/myjobs')
                setLoading(false)
            }
        },

        updatejoblist: async (payload) => {
            setLoading(true);
            try {
                const response = await JobsRepository.updatejoblist(payload);
                if (response.status === 200) {
                    toast.success(response.data.message, { style: customToastStyle });
                    route('/myjobs');
                } else if (response.code === "ERR_NETWORK") {
                    toast.error(response.message, { style: customToastStyle });
                } else {
                    toast.warn(response.data.message, { style: customToastStyle });
                }
            } catch (error) {
                toast.error('An unexpected error occurred.', { style: customToastStyle });
            } finally {
                setLoading(false);
            }
        },

        getJobscategoryCount: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.Jobscategorycount(payload)
            if (response.status === 200) {
                setCategoriesCount(response.data)
                setLoading(false)
            }
        },

        educationalDetails: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.educationalDetails(payload)
            if (response) {
                if (response.status === 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    route('/candidatedashboard')
                } else {
                    toast.warning(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },

        reqEducationalInfo: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.geteducationProfile(payload)
            if (response.status === 200) {
                seteducationaInfo(response.data.result)
                setLoading(false)
            }
        },

        updateEducationalDetails: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.updateEducationalDetails(payload)
            if (response) {
                if (response.status === 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    route('/candidatedashboard')
                } else {
                    toast.warning(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },

        postJobApplication: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.jobApplication(payload)
            if (response) {
                if (response.status === 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                } else {
                    toast.warning(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },

        //for candidate applied jobs
        appliedjobslist: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.applyjobdetails(payload)
            if (response) {
                if (response.status == 200) {
                    setAppliedJobs(response.data.result)
                }
                else {
                    toast.warning(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },
        //for company  applied jobs
        getjobApplication: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.getjobApplication(payload)
            if (response.status === 200) {
                setapplyjob(response.data.result)
                setLoading(false)
            }
        },

        patchJobStatus: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.updateJobStatus(payload)
            if (response.status === 200) {
                await toast.success(response.data.message, { style: customToastStyle })
                setLoading(false)
            } else {
                await toast.warning(response.data.message, { style: customToastStyle })
            }
        },
        getmonthwisejobcount: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.returnmonthwisecount(payload)
            if (response.status === 200) {
                setMonthwisecount(response.data)

                setLoading(false)
            } else {

            }
        },
        saveCompanyNotification: async (payload) => {
            try {

                await JobsRepository.getstorecompanynotification(payload);
                setLoading(true);
            } catch (error) {
                console.error('Error saving company notification:', error);
            }
        },


        reqcompanynotification: async () => {
            setLoading(true)
            const response = await JobsRepository.getcompanynotification()
            if (response) {
                setCompanynotification(response?.data?.result)
            } else {
                toast.warning(response.data.message, { style: customToastStyle })
            }
            setLoading(false)
        },
        //candidate notification 
        updatecompanyNotification: async (payload) => {
            setLoading(true)
            const response = await JobsRepository.updatecompanynotification(payload)

            if (response) {

            } else {

            }
            setLoading(false)

        },

    }
}
