import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useJob } from '../../../hooks/useJob';
import Pagination from '../../Jobs/JobList2/Pagination';
import { FaTimes, FaSearch } from "react-icons/fa";

const Notification = () => {
    const [modal, setModal] = useState(false);
    const openModal = () => setModal(!modal);
    const [currentPage, setCurrentPage] = useState(1);
    const route = useNavigate();
    const [notificationperpage] = useState(20);
    const { companynotification, reqcompanynotification } = useJob();
    useEffect(() => {
        reqcompanynotification()
    }, []);
    const companynotifications = Array.isArray(companynotification) ? companynotification.slice().reverse() : [];
    const indexofLastnotification = currentPage * notificationperpage;
    const indexofFirstNotification = indexofLastnotification - notificationperpage;
    const formatTimestampWithDifference = (timestamp) => {
        // Convert timestamp to Date objects
        const date = new Date(timestamp);
        const currentTime = new Date();

        // Calculate time difference in milliseconds
        const timeDifference = currentTime - date;

        // Convert time difference to seconds, minutes, hours, and days
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        // Return formatted time ago string based on the calculated difference
        if (days > 0) {
            return `${days} days ago`;
        } else if (hours > 0) {
            return `${hours} hours ago`;
        } else if (minutes > 0) {
            return `${minutes} minutes ago`;
        } else {
            return `${seconds} seconds ago`;
        }
    };
    const notificationsofcompany = companynotifications.slice(indexofFirstNotification, indexofLastnotification);
    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handleCancel = () => {
        route("/");
    };
    return (
        <React.Fragment>
            <Container>
               
                <Card className="side-border mt-4">
                <span
                    className="fa-times-icon"
                    onClick={handleCancel}
                    style={{
                        top: '-20px',
                        right: '-16px', 
                        position: "absolute",
                        cursor: "pointer",
                        borderRadius: "50%",
                        backgroundColor: "#bebcbc",
                        padding: "6px",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                        zIndex: "9999",
                        transition: "background-color 0.3s"
                    }}
                    // Apply hover effect
                    onMouseEnter={(e) => e.target.style.backgroundColor = "#c4bfdd"}
                    onMouseLeave={(e) => e.target.style.backgroundColor = "#bebcbc"}
                >
                    <FaTimes size={20} />
                </span>

                    <CardBody className="side-border">
                        <h3 className="fw-bolder mb-3 ">Stay Connected</h3>
                        <hr />
                        <Row>
                            <Col lg={12}>
                                <div>
                                    {notificationsofcompany?.map((companydata) => (

                                        <div className='mb-4 '>

                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                                                        <i className="uil uil-user-check"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link
                                                        to={`/jobdetails?id=${companydata.jobId}`}
                                                        className="text-dark notification-item d-block active"
                                                        key={companydata._id}
                                                    > <h6 className="mt-0 mb-1 fs-14">
                                                            {companydata.message}</h6></Link>
                                                    <p className="mb-0 fs-12 text-muted">
                                                        <i className="mdi mdi-clock-outline"></i> <span>{formatTimestampWithDifference(companydata.timestamp)}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <hr />

                                        </div>

                                    ))}
                                </div>
                            </Col>
                        </Row>
                        <Pagination
                            jobsPerPage={notificationperpage}
                            totalJobs={companynotifications.length}
                            currentPage={currentPage}
                            paginate={paginate}
                        />
                    </CardBody>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export default Notification;
