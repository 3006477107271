import React from 'react'
import { Container, Card, CardHeader, Row, Col, FormGroup } from "reactstrap";
// import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
// import '../../Admin/assets/css/dashboard.css';
const Appliedjobs = () => {
    return (
        <div className='bg-gradient-light h-100'>
            <Container className='bg-gradient-light mt-5 abc'>
                <Card className='second'>
                    <CardHeader className="bg-gradient-light border-0 ">
                        <Row className="align-items-center mb-4  ">
                            <Col xs="8">
                                <h3 className="mb-0 ">Applied jobs</h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <Row className=' mt-4 ml-2'>
                        <Col sm="4">
                            <FormGroup>
                            <h6 className='ml-5'>Search Jobs</h6>
                                <div className="input-group rounded">
                                    <span className="input-group-text border-0" id="search-addon">
                                        <i className="fas fa-search" />
                                    </span>
                                    <input type="search" className="form-control rounded w-50" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                </div>
                                <span className='text-muted ml-5'>No jobs found</span>
                            </FormGroup>
                        </Col>
                        <Col sm="4" className='ml-5'>
                            <FormGroup>
                                 <h6 className='ml-2'>Sort by</h6>
                                <Form.Select aria-label="Default select example">
                                    <option>Default</option>
                                    <option value="Newest">Newest</option>
                                    <option value="Oldest">Oldest</option>
                                </Form.Select>
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
                {/* <footer className='text-muted text-center'>
                    © 2023 Fresherszone. All Rights Reserved. A Product By Globalion Technology Solutions LLP
                </footer> */}
            </Container>
        </div>
    )
}
export default Appliedjobs









