import React from "react";
import Section from "../Sitemap/Section";
import SitemapPage from "../Sitemap/SitemapPage";

const Sitemap = () => {
  document.title = "Sitemap";
  return (
    <React.Fragment>
      <Section />
      <SitemapPage />
    </React.Fragment>
  );
};

export default Sitemap;
