import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

const ServicePage = () => {
  const servicePage = [
    {
      id: 1,
      serviceIcon: "uim-object-ungroup",
      serviceName: "Extensive Job Opportunities",
      serviceText:
        "Our platform is a treasure trove of job openings specifically designed for freshers and interns. We collaborate with a wide range of companies,from startups to established corporations, across various industries to ensure that you have a diverse array of opportunities to choose from."
    },
    {
      id: 2,
      serviceIcon: "uim-telegram-alt",
      serviceName: "User-Friendly Interface",
      serviceText:
        " We know how overwhelming job hunting can be, especially for freshers. Our user-friendly interface is designed to provide a seamless experience, allowing you to browse through job listings, apply with ease, and track your applications effortlessly."
    },
    {
      id: 3,
      serviceIcon: "uim-airplay",
      serviceName: "Display Jobs",
      serviceText:
        "Intrinsically incubate intuitive opportunities and real-time potentialities. Appropriately communicate one-to-one technology."
    },
    {
      id: 4,
      serviceIcon: "uim-rocket",
      serviceName: "Career Resources and Guidance",
      serviceText:
        " Beyond job listings, we offer valuable resources, tips, and career guidance to help you navigate through the early stages of your professional life successfully."
    },
    {
      id: 5,
      serviceIcon: "uim-history",
      serviceName: "Community and Support",
      serviceText:
        "At FreshersZone.in, you’re not alone on your journey. Our platform fosters a supportive community where you can connect with like-minded individuals, share experiences, and seek advice from those who have been in your shoes before."
    },
    {
      id: 6,
      serviceIcon: "uim-bookmark",
      serviceName: "Internship Opportunities",
      serviceText:
        "We understand the significance of internships in shaping your career. That’s why we curate a wide range of internship opportunities to help you gain practical experience, enhance your skills, and build a robust foundation for your future endeavors.."
    },
  ];
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="section-title text-center">
                <h3 className="title mb-3">
                  Providing our trusted{" "}
                  <span className="text-warning">Services</span>
                </h3>
                <p className="text-muted">
                Our services are well-regarded, and clients appreciate the straightforward layout when considering what we offer.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            {servicePage.map((servicePageDetails, key) => (
              <Col lg={4} md={6} key={key}>
                <Card className="service-box mt-4 border-0">
                  <CardBody className="p-4">
                    <div className="service-icon icons-md">
                      <Icon
                        icom={servicePageDetails.serviceIcon}
                        color="#02af74"
                      />
                    </div>
                    <div className="mt-4">
                      <h5>{servicePageDetails.serviceName}</h5>
                      <p className="text-muted">
                        {servicePageDetails.serviceText}
                      </p>
                    </div>
                    {/* <div className="learn-more">
                      <Link to="#" className="form-text text-primary">
                        Learn More <i className="uil uil-angle-right-b"></i>
                      </Link>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ServicePage;
