import React, { useEffect, useState } from 'react'
import '../assets/css/Dashboard.css';
import { RiseLoader } from 'react-spinners';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { Validation } from '../../ExtraPages/Components/Validation';
import { useProfile } from '../../../hooks/useProfile';
import { baseURL } from '../../../repositories/Repository';
import { Sidebar } from '../layout/AdminLayout/Sidebar';
import { toast } from 'react-toastify';

const CompanyProfile = () => {
    const { reqCompanyInfo, updateCompanyInfo, profileInfo } = useProfile();
    const [initialProfileInfo, setInitialProfileInfo] = useState({});
    const route = useNavigate();

    const { NumberValidation, FirstnameValidation,
        StatenameValidation, PostalcodeValidation,
        CitynameValidation,
        AadharValidation, WebValidation,
        InstaValidation, FacebookValidation,
        TwitterValidation, LinkdinValidation,
        ROCValidation } = Validation();

    const [loading, setloading] = useState(false);
    useEffect(() => {
        setloading(true);
        setTimeout(() => {
            setloading(false);
        }, 3000);
    }, []);

    const loadingStyle = {
        marginTop: '300px',
        textAlign: 'center',
        displayFlex: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        width: '100%',
        height: '100vh'
    };

    const [address, setAddress] = useState({
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        location: "",
    });

    const [documents, setDocuments] = useState({
        companyLogo: "",
    });

    const [readableData, setReadableData] = useState({
        companyName: "",
        email: "",
        panNumber: "",
        dateOfRegistration: "",
        gstNumber: "",
    })
    const [basicInfo, setBasicInfo] = useState({
        mobileNumber: "",
        shopact: "",
        roc: " ",
        udyogAadhar: "",
        companySize: " ",
        category: " ",
        discription: " "
    });

    const [links, setLinks] = useState({
        website: " ",
        instagramLink: "",
        facebookLink: " ",
        linkedinLink: " ",
        twitterLink: " ",
    })
    const [avatar, setAvatar] = useState(null)

    const handleOnChange = (e) => {
        let { name, value, files } = e.target;

        if (files) {
            const uploadedImage = files[0];
            const img = new Image();
            img.src = URL.createObjectURL(uploadedImage);
            img.onload = function () {
                if (img.width === 80 && img.height === 80) {
                    setAvatar(URL.createObjectURL(uploadedImage));
                    setDocuments((prevValues) => ({
                        ...prevValues,
                        [name]: uploadedImage,
                    }));
                } else {
                    toast.error('Please upload an image with dimensions 80x80 pixels.', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            };
        } else {
            setBasicInfo((pre) => ({ ...pre, [name]: value }));
        }
    };

    const handleOnAddressChange = (e) => {
        let { name, value } = e.target;
        setAddress((pre) => ({ ...pre, [name]: value }));
    };

    const handleOnLinks = (e) => {
        let { name, value } = e.target;
        setLinks((pre) => ({ ...pre, [name]: value }));
    };

    useEffect(() => {
        let logoPath = `${baseURL}/${profileInfo?.logo?.file}`
        setAvatar(logoPath)
        setReadableData({
            email: profileInfo?.basicInfo?.email || '',
            panNumber: profileInfo?.basicInfo?.panNumber || '',
            gstNumber: profileInfo?.basicInfo?.gstNumber || '',
            dateOfRegistration: profileInfo?.basicInfo?.dateOfRegistration || '',
        })

        setBasicInfo({
            companyName: profileInfo?.basicInfo?.companyName || '',
            mobileNumber: profileInfo?.basicInfo?.mobileNumber || '',
            udyogAadhar: profileInfo?.basicInfo?.udyogAadhar || '',
            shopact: profileInfo?.basicInfo?.shopact || '',
            roc: profileInfo?.basicInfo?.roc || '',
            companySize: profileInfo?.basicInfo?.companySize || '',
            category: profileInfo?.basicInfo?.category || '',
            discription: profileInfo?.basicInfo?.discription || '',
        });
        setLinks({
            website: profileInfo?.basicInfo?.links?.website || '',
            instagramLink: profileInfo?.basicInfo?.links?.instagramLink || '',
            facebookLink: profileInfo?.basicInfo?.links?.facebookLink || '',
            linkedinLink: profileInfo?.basicInfo?.links?.linkedinLink || '',
            twitterLink: profileInfo?.basicInfo?.links?.twitterLink || '',

        });
        setAddress({
            address1: profileInfo?.address?.address1 || '',
            address2: profileInfo?.address?.address2 || '',
            city: profileInfo?.address?.city || '',
            state: profileInfo?.address?.state || '',
            country: profileInfo?.address?.country || '',
            postalCode: profileInfo?.address?.postalCode || '',
            location: profileInfo?.address?.location || '',
        });
        setDocuments({
            companyLogo: profileInfo?.logo?.file || '',
        });

        setInitialProfileInfo({
            email: profileInfo?.basicInfo?.email || '',
            panNumber: profileInfo?.basicInfo?.panNumber || '',
            gstNumber: profileInfo?.basicInfo?.gstNumber || '',
            dateOfRegistration: profileInfo?.basicInfo?.dateOfRegistration || '',
            companyName: profileInfo?.basicInfo?.companyName || '',
            mobileNumber: profileInfo?.basicInfo?.mobileNumber || '',
            udyogAadhar: profileInfo?.basicInfo?.udyogAadhar || '',
            shopact: profileInfo?.basicInfo?.shopact || '',
            roc: profileInfo?.basicInfo?.roc || '',
            companySize: profileInfo?.basicInfo?.companySize || '',
            category: profileInfo?.basicInfo?.category || '',
            discription: profileInfo?.basicInfo?.discription || '',
            website: profileInfo?.basicInfo?.links?.website || '',
            instagramLink: profileInfo?.basicInfo?.links?.instagramLink || '',
            facebookLink: profileInfo?.basicInfo?.links?.facebookLink || '',
            linkedinLink: profileInfo?.basicInfo?.links?.linkedinLink || '',
            twitterLink: profileInfo?.basicInfo?.links?.twitterLink || '',
            address1: profileInfo?.address?.address1 || '',
            address2: profileInfo?.address?.address2 || '',
            city: profileInfo?.address?.city || '',
            state: profileInfo?.address?.state || '',
            country: profileInfo?.address?.country || '',
            postalCode: profileInfo?.address?.postalCode || '',
            location: profileInfo?.address?.location || '',
            companyLogo: profileInfo?.logo?.file || '',
        });

    }, [profileInfo]);


    const handleCancelClick = () => {
        // console.log('Cancelling changes:', initialJobInfo);
        setloading(initialProfileInfo);
        route('/companydashboard');
    };


    useEffect(() => {
        reqCompanyInfo();
    }, []);

    const handleOnSubmit = (e) => {
        const formData = new FormData();
        e.preventDefault();
        formData.append("basicInfo", JSON.stringify(basicInfo));
        formData.append("address", JSON.stringify(address))
        formData.append("links", JSON.stringify(links))
        formData.append("logo", documents.companyLogo)
        updateCompanyInfo(formData)
    };

    const handleOnAvatrRemove = () => {
        setAvatar(null)
        setDocuments((prevValues) => {
            return {
                ...prevValues,
                companyLogo: ""
            }
        })
    }
    const Avatar = () => {
        const avatarStyle = {
          position: "relative",
          maxWidth: "80px",
          minHeight: "80px",
        };
      
        const logoStyle = {
          width: "80px",
          height: "80px",
        };
      
        const removeButtonStyle = {
          position: "absolute",
          top: "-10px",
          left: "70px",
          backgroundColor: "#3b3535",
          borderRadius: "50%",
          color: "#fff",
          fontSize: "10px",
          cursor: "pointer",
          padding: "0 5px",
        };
      
        if (avatar) {
          return (
            <div style={avatarStyle}>
              <img style={logoStyle} src={avatar} alt='avatar' />
              <span style={removeButtonStyle} className='remove-button' onClick={handleOnAvatrRemove}>
                X
              </span>
            </div>
          );
        }
      
        return null;
      };
    return (
        <>
            <div className='mt-0 relative z-50'>
                <Sidebar />
            </div>
            <div className='bg-light mt-5 -ml-5'>
                {loading ?
                    <div style={loadingStyle}>
                        <RiseLoader loading={loading} />
                    </div>
                    :
                    <Form onSubmit={handleOnSubmit} className="auth-form">
                        <Container className='mt-3 mb-3'>
                            <Card className="side-border">
                                {/* <Card className='border-0'> */}
                                <CardBody className='side-border'>
                                    <CardHeader>
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="heading-small text-dark mb-2 mr-4 text-nowrap">
                                                    Edit Company Profile
                                                </h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <Form>

                                        <div className="pl-lg-4 mt-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="name"
                                                        >
                                                            Company Name
                                                        </label>
                                                        <Input
                                                            name='companyName'
                                                            className="form-control-alternative bg-white"
                                                            size="lg"
                                                            id="input-name"
                                                            placeholder="name"
                                                            type="text"
                                                            value={basicInfo.companyName}
                                                            readonly
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-email"
                                                        >
                                                            Email Address
                                                        </label>
                                                        <Input
                                                            name='email'
                                                            className="form-control-alternative bg-white"
                                                            id="input-email"
                                                            size="lg"
                                                            type="email"
                                                            value={readableData.email}
                                                            readonly
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-CompanyAddress"
                                                        >
                                                            Company Address 1
                                                        </label>
                                                        <Input
                                                            name='address1'
                                                            className="form-control-alternative bg-white"
                                                            id="input-CompanyAddress"
                                                            size="lg"
                                                            placeholder="Company address1"
                                                            type="text"
                                                            value={address.address1}
                                                            onChange={handleOnAddressChange}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-CompanyAddress2"
                                                        >
                                                            Company Address 2
                                                        </label>
                                                        <Input
                                                            name='address2'
                                                            className="form-control-alternative bg-white"
                                                            id="input-CompanyAddress"
                                                            size="lg"
                                                            placeholder="Company address1"
                                                            type="text"
                                                            value={address.address2}
                                                            onChange={handleOnAddressChange}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>

                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-CompanyCity"
                                                        >
                                                            City
                                                        </label>
                                                        <Input
                                                            name='companyCity'
                                                            className="form-control-alternative bg-white"
                                                            id="input-Companycity"
                                                            size="lg"
                                                            placeholder="City"
                                                            type="text"
                                                            value={address.city}
                                                            onChange={handleOnAddressChange}
                                                            onKeyUp={FirstnameValidation}
                                                            required
                                                        /><span id='first' />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-companyState"
                                                        >
                                                            State
                                                        </label>
                                                        <br />

                                                        <Input
                                                            className="form-control-alternative bg-white"
                                                            id="input-companystate"
                                                            size="lg"
                                                            placeholder="State"
                                                            type="text"
                                                            value={address.state}
                                                            onChange={handleOnAddressChange}
                                                            onKeyUp={StatenameValidation}
                                                            name='state'
                                                        /><span id="state" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-companyCode"
                                                        >
                                                            Postal Code
                                                        </label>
                                                        <br />

                                                        <Input
                                                            className="form-control-alternative bg-white"
                                                            id="input-companyCode"
                                                            size="lg"
                                                            placeholder="Postal code"
                                                            type="number"
                                                            value={address.postalCode}
                                                            onChange={handleOnAddressChange}
                                                            onKeyUp={PostalcodeValidation}
                                                            name='postalCode'
                                                        /><span id='postalcode' />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-companyCountry"
                                                        >
                                                            Country
                                                        </label>
                                                        <br />

                                                        <Input
                                                            className="form-control-alternative bg-white"
                                                            id="input-companyCountry"
                                                            size="lg"
                                                            placeholder="Country"
                                                            type="text"
                                                            value={address.country}
                                                            onChange={handleOnAddressChange}
                                                            onKeyUp={CitynameValidation}

                                                            name='country'
                                                        /><span id='city' />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-contact"
                                                        >
                                                            Mobile Number
                                                        </label>
                                                        <Input
                                                            name='mobileNumber'
                                                            className="form-control-alternative bg-white"
                                                            placeholder='Contact'
                                                            type="number"
                                                            size="lg"
                                                            value={basicInfo.mobileNumber}
                                                            onChange={handleOnChange}
                                                            onKeyUp={NumberValidation}
                                                            required
                                                        /><span id="Number" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-companysize"
                                                        >
                                                            Company Size
                                                        </label>
                                                        <select value={basicInfo.companySize} defaultValue="1-50" onChange={handleOnChange} className="form-select bg-white border-rounded" name='companySize'>
                                                            <option value="1-50">1-50</option>
                                                            <option value="50-100">50-100</option>
                                                            <option value="100-200">100-200</option>
                                                            <option value="200-300">200-300</option>
                                                            <option value="300-400">300-400</option>
                                                            <option value="500&above">500 & above</option>
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-location"
                                                        >
                                                            Location
                                                        </label>
                                                        <br />
                                                        <Input
                                                            name='location'
                                                            className="form-control-alternative bg-white"
                                                            size="lg"
                                                            placeholder='Location'
                                                            id="input-location"
                                                            type="text"
                                                            value={address.location}
                                                            onChange={handleOnAddressChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-dateofRegistration"
                                                        >
                                                            Date of Registration
                                                        </label>
                                                        <Input
                                                            name='dateofRegistration'
                                                            className="form-control-alternative bg-white"
                                                            size="lg"
                                                            id="input-date"

                                                            value={readableData.dateOfRegistration}
                                                            readOnly
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-gstno"
                                                        >
                                                            GST No
                                                        </label>
                                                        <br />
                                                        <Input
                                                            name='gstNumber'
                                                            className="form-control-alternative bg-white"
                                                            id="input-gstno"
                                                            size="lg"
                                                            placeholder="GST No"
                                                            type="text"
                                                            value={readableData.gstNumber}
                                                            readOnly
                                                        /><span id='gstnumber' />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-udyogAadhar"
                                                        >
                                                            Udyog Aadhar
                                                        </label>
                                                        <br />
                                                        <Input
                                                            name='udyogAadhar'
                                                            className="form-control-alternative bg-white"
                                                            id="input-udyogAadhar"
                                                            size="lg"
                                                            placeholder="UdyogAadhar"
                                                            type="number"
                                                            value={basicInfo.udyogAadhar}
                                                            onChange={handleOnChange}
                                                            onKeyUp={AadharValidation}
                                                        /><span id='AadharNumber' />

                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-shopact"
                                                        >
                                                            Shop-Act Number
                                                        </label>
                                                        <br />
                                                        <Input
                                                            name='shopact'
                                                            className="form-control-alternative bg-white"
                                                            id="input-shopact"
                                                            size="lg"
                                                            placeholder="Shopact"
                                                            type="number"
                                                            value={basicInfo.shopact}
                                                            onChange={handleOnChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-roc"
                                                        >
                                                            ROC
                                                        </label>
                                                        <br />
                                                        <Input
                                                            name='roc'
                                                            className="form-control-alternative bg-white"
                                                            id="input-roc"
                                                            size="lg"
                                                            placeholder="ROC"
                                                            type="text"
                                                            value={basicInfo.roc}
                                                            onChange={handleOnChange}
                                                            onKeyUp={ROCValidation}
                                                        /><span id='rocNumber' />

                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-pancardno"
                                                        >
                                                            Pancard No
                                                        </label>
                                                        <br />

                                                        <Input
                                                            className="form-control-alternative bg-white"
                                                            id="input-pancardno"
                                                            size="lg"
                                                            placeholder="Pancard No"
                                                            type="text"
                                                            value={readableData.panNumber}
                                                            name='pancardno'
                                                            readOnly
                                                        /><span id='Pancard' />
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-category"
                                                        >
                                                            Category
                                                        </label>
                                                        <br />
                                                        <select value={basicInfo.category} defaultValue="Advertising" onChange={handleOnChange} class="form-select bg-white" name='category'>
                                                            <option value="Advertising">Advertising</option>
                                                            <option value="Application">Application</option>
                                                            <option value="Customer">Customer</option>
                                                            <option value="Design">Design</option>
                                                            <option value="Developer">Developer</option>
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-instagram"
                                                        >
                                                            Instagram Link
                                                        </label>
                                                        <br />
                                                        <Input
                                                            name='instagramLink'
                                                            className="form-control-alternative bg-white"
                                                            id="input-instagramlink"
                                                            size="lg"
                                                            placeholder="Instagram url"
                                                            type="url"
                                                            value={links.instagramLink}
                                                            onChange={handleOnLinks}
                                                            onKeyUp={InstaValidation}
                                                        /><span id='instaurl' />

                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-LinkedIn"
                                                        >
                                                            LinkedIn Link
                                                        </label>
                                                        <br />
                                                        <Input
                                                            name='linkedinLink'
                                                            className="form-control-alternative bg-white"
                                                            id="input-LinkedIn"
                                                            size="lg"
                                                            placeholder="LinkedIn url"
                                                            type="url"
                                                            value={links.linkedinLink}
                                                            onChange={handleOnLinks}
                                                            onKeyUp={LinkdinValidation}
                                                        /><span id='linkdinurl' />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-Twitter"
                                                        >
                                                            Twitter Link
                                                        </label>
                                                        <br />
                                                        <Input
                                                            name='twitterLink'
                                                            className="form-control-alternative bg-white"
                                                            id="input-Twitter"
                                                            size="lg"
                                                            placeholder="Twitter url"
                                                            type="url"
                                                            value={links.twitterLink}
                                                            onChange={handleOnLinks}
                                                            onKeyUp={TwitterValidation}
                                                        /><span id='twitterurl' />

                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-facebook"
                                                        >
                                                            Facebook Link
                                                        </label>
                                                        <br />
                                                        <Input
                                                            name='facebookLink'
                                                            className="form-control-alternative bg-white"
                                                            id="input-facebooklink"
                                                            size="lg"
                                                            placeholder="Facebook url"
                                                            type="url"
                                                            value={links.facebookLink}
                                                            onChange={handleOnLinks}
                                                            onKeyUp={FacebookValidation}
                                                        /><span id='faceurl' />

                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-website"
                                                        >
                                                            Website
                                                        </label>
                                                        <br />
                                                        <Input
                                                            className="form-control-alternative bg-white"
                                                            id="input-website"
                                                            size="lg"
                                                            placeholder="Website url"
                                                            type="url"
                                                            value={links.website}
                                                            onChange={handleOnLinks}
                                                            onKeyUp={WebValidation}
                                                            name='website'
                                                        /><span id='url' />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label labeltype"
                                                            htmlFor="input-Twitter"
                                                        >
                                                            Company Logo
                                                        </label>
                                                        <br />
                                                        {!documents.companyLogo ?
                                                            <Input
                                                                type="file"
                                                                id="img"
                                                                name="companyLogo"
                                                                accept="image/*"
                                                                size="lg"
                                                                value={documents.companyLogo}
                                                                onChange={handleOnChange}
                                                            />
                                                            : <Avatar />}
                                                        {!documents.companyLogo && (
                                                            <p>
                                                                <span className="font-weight-bold">Note:</span>
                                                                <span className='text-danger'>
                                                                    Please upload an image with dimensions (80x80) pixels.
                                                                    </span>
                                                            </p>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <div className="lg-4">
                                                            <label className='labeltype'>Description</label>
                                                            <Input
                                                                name='discription'
                                                                className="form-control-alternative"
                                                                rows="2"
                                                                type="textarea"
                                                                value={basicInfo.discription}
                                                                onChange={handleOnChange}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                    <Button className='btn btn-success mb-3 ml-3'>Update</Button>
                                    <button className='btn btn-success mb-3 ml-3' onClick={handleCancelClick}>Cancel</button>
                                </CardBody>
                            </Card>
                        </Container>
                    </Form>
                }
            </div>
        </>
    )
}

export default CompanyProfile