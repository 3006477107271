import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useJob } from "../../../hooks/useJob";
import { baseURL } from "../../../repositories/Repository";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { JobApply } from "../../../components/shared/JobApply";
import { toast } from "react-toastify";
import { IoLocationOutline } from "react-icons/io5";
import { MdAccountBalanceWallet } from "react-icons/md";
import { useProfile } from "../../../hooks/useProfile";

const JobVacancyPost = () => {
  //Apply Now Model
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const { showprofile, profile } = useProfile();
  const [profileStatus, setProfileStatus] = useState();
  const route = useNavigate();

  const { getRandomJobs, randomJobs } = useJob();
  useEffect(() => {
    getRandomJobs();
    showprofile();
  }, []);
  useEffect(() => {
    if (profile && profile.role === 'student') {
      setProfileStatus(profile.role)
    }
    else if (profile && profile.role === 'campany') {
      setProfileStatus(profile.role)
    }
    else {
      setProfileStatus(profile)
    }
  })
  const navigate = useNavigate();
  const openApplyModal = (jobId) => {
    if (isAuthenticated) {
      setSelectedJobId(jobId);
      setApplyModalOpen(true);
    } else {
      // Store the job details in session storage before redirecting
      sessionStorage.setItem("redirectToJobId", jobId);
      toast.warning("You need to login to apply for this job.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // Delayed redirection to the sign-in page after 2 seconds (adjust the time as needed)
      setTimeout(() => {
        route("/signin");
      }, 2000);
    }
  };

  const closeApplyModal = () => {
    setSelectedJobId(null); // Reset the selected job ID when the modal is closed
    setApplyModalOpen(false);
  };

  const handleJobTitleClick = (jobId) => {
    navigate(`/jobdetails?id=${jobId}`);
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      {randomJobs?.map((jobVacancyPostDetails, key) => (
        <div
          key={key}
          className={
            jobVacancyPostDetails.addclassNameBookmark === true
              ? "job-box bookmark-post card mt-4"
              : "job-box card mt-4"
          }
        >
          <div className="p-4">
            <Row>
              <Col md={2}>
                <div
                  id="recentCompanyLogo"
                  className="text-center mb-4 mb-md-0"
                >
                  <Link
                    to={`/companydetails?id=${jobVacancyPostDetails?._id}&companyId=${jobVacancyPostDetails?.companyId}`}
                    className="text-dark"
                  >
                    {jobVacancyPostDetails && jobVacancyPostDetails?.companyLogo ? (
                      <div className="avatar-container">
                        <img
                          className="avatar-logo rounded-3"
                          src={`${baseURL}/${jobVacancyPostDetails?.companyLogo}`}
                          alt="Company Logo"
                        />
                      </div>
                    ) : (
                      <div className="avatar-container">
                        <FontAwesomeIcon
                          icon={faLandmark}
                          className="avatar-logo"
                          color='#373840'
                          style={{ width: '80px', height: '80px' }}
                        />

                      </div>
                    )}
                  </Link>
                </div>
              </Col>

              <Col lg={10}>
                <div className="mt-3 mt-lg-0  text-center">
                  <h5 id="jobVacancyJob"
                    className="fs-17 mb-1"
                    onClick={() => handleJobTitleClick(jobVacancyPostDetails._id)}
                  >
                    <Link
                      to={`/jobdetails?id=${jobVacancyPostDetails._id}`}
                      className="text-dark"
                    >
                      {jobVacancyPostDetails?.jobTitle &&
                        jobVacancyPostDetails.jobTitle.charAt(0).toUpperCase() +
                        jobVacancyPostDetails.jobTitle.slice(1)}
                    </Link>{" "}
                  </h5>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <p
                        id="jobVacancyCompanyName"
                        className="text-muted fs-14 mb-0"
                      >
                        {jobVacancyPostDetails?.companyName}
                      </p>
                    </li>
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0">
                        {/* <i className="mdi mdi-map-marker"></i> */}
                        <IoLocationOutline className="text-primary inline-block align-middle me-1 mb-1" />
                        {jobVacancyPostDetails?.jobLocations
                          .map((location) => location.location)
                          .join(", ")}
                      </p>
                    </li>
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0">
                        {/* <i className="uil uil-wallet"></i>{" "} */}
                        <MdAccountBalanceWallet className="mb-1 text-success" />
                        {jobVacancyPostDetails?.salary}
                      </p>
                    </li>
                  </ul>
                  <div className="mt-2">
                    <span
                      className={
                        jobVacancyPostDetails?.jobType === true
                          ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                          : jobVacancyPostDetails?.partTime === true
                            ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                            : jobVacancyPostDetails?.freeLance === true
                              ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                              : jobVacancyPostDetails?.internship === true
                                ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                                : ""
                      }
                    >
                      {jobVacancyPostDetails?.timing}
                    </span>
                    {/* {(jobVacancyPostDetails.badges || []).map(
                      (badgeInner, key) => (
                        <span
                          className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                          key={key}
                        >
                          {badgeInner.badgeName}
                        </span>
                      )
                    )} */}
                  </div>
                </div>
              </Col>
            </Row>
            {/* <div className="favorite-icon">
              <Link to="#">
                <i className="uil uil-heart-alt fs-18"></i>
              </Link>
            </div> */}
          </div>
          <div className="p-3 bg-light">
            <div className="row justify-content-between">
              <Col md={8}>
                <div>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <i className="uil uil-tag"></i> Job Types :
                    </li>
                    <li className="list-inline-item">
                      {jobVacancyPostDetails?.jobType}
                    </li>
                  </ul>
                </div>
              </Col>
              {
                profileStatus === 'student' && (

                  <Col md={3}>
                    <div className="text-md-end">
                      <Link
                        to={`/jobdetails?id=${jobVacancyPostDetails._id}`}
                        onClick={() => openApplyModal(jobVacancyPostDetails._id)}
                        className="primary-link"
                      >
                        Apply Now <i className="mdi mdi-chevron-double-right"></i>
                      </Link>
                    </div>
                  </Col>
                )
              }
              {
                profileStatus === null && (

                  <Col md={3}>
                    <div className="text-md-end">
                      <Link
                        to={`/jobdetails?id=${jobVacancyPostDetails._id}`}
                        onClick={() => openApplyModal(jobVacancyPostDetails._id)}
                        className="primary-link"
                      >
                        Apply Now <i className="mdi mdi-chevron-double-right"></i>
                      </Link>
                    </div>
                  </Col>
                )
              }
            </div>
          </div>
        </div>
      ))}

      <div className="text-center mt-4">
        <Link to="/joblist" className="primary-link form-text">
          View More <i className="mdi mdi-arrow-right"></i>
        </Link>
      </div>

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          {/* Pass the selectedJobId to the JobApply component */}
          <JobApply
            isOpen={applyModalOpen}
            toggleModal={closeApplyModal}
            jobId={selectedJobId}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobVacancyPost;
