import React, { useState } from "react";
import { Col, Row } from "reactstrap";

const Selected = ({ onFilterChange , selectedFilter}) => {
    const handleFilterChange = (event) => {
        onFilterChange(event.target.value);
    };

    return (
        <React.Fragment>
            <Row className="align-items-center ml-4 w-100 mt-5">
                <Col lg={8}>
                    <div className="mb-2 mb-lg-0">
                        <h3 className="mb-0"> My Job Listings </h3>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="candidate-list-widgets">
                        <Row>
                            <Col lg={6}>
                            </Col>
                            <Col lg={6}>
                                <div className="selection-widget mt-2 mt-lg-0 mr-5">
                                    <select
                                        className="form-select"
                                        data-trigger
                                        name="choices-candidate-page"
                                        id="choices-candidate-page"
                                        aria-label="Default select example"
                                        onChange={handleFilterChange}  
                                        value={selectedFilter || "all"}
                                    >
                                        <option value="all">All</option>
                                        <option value="one">Last 1 hour</option>
                                        <option value="two">Last 1 day</option>
                                        <option value="three">Last 2 day</option>
                                        <option value="week">Last 1 week</option>
                                        <option value="month">Last 1 month</option>
                                        <option value="year">Last 1 year</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Selected;
