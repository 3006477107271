import React, { useEffect, useState } from "react";
// import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Input } from "reactstrap";
import { Validation } from "../Components/Validation";
import { Spinner } from "react-bootstrap";
import { Timer } from "../Components/Timer";
import { useAuth } from "../../../hooks/useAuth";
const { EmailValidation } = Validation();

export const AuthFields = ({
  loading,
  formData,
  isOtpSent,
  isOtpExp,
  setIsOtpExp,
  handleOnChange,
  handleOnSendOtpReq,
  handleOnReSendOtpReq,
  handleOnVerifyOtpReq,
}) => {
  const { isOtpReSent } = useAuth();
  return (
    <>
      <div className="mb-3 password_control">
        <label htmlFor="emailInput" className="form-label">
          Email
        </label>
        <Input
          name="email"
          value={formData.email}
          onChange={handleOnChange}
          onKeyUp={EmailValidation}
          type="email"
          className="form-control"
          required
          id="emailInput"
          placeholder="Enter your email"
          readOnly={isOtpSent}
        />
        <span id="Email" />
      </div>
      {isOtpSent && !isOtpExp && (
        <OutputField
          {...{ formData, handleOnChange, isOtpExp, isOtpSent, setIsOtpExp }}
        />
      )}
      {isOtpSent ? (
        isOtpExp ? (
          <MasterButton
            {...{
              onclickFun: handleOnReSendOtpReq,
              title: "Resend OTP",
              loading,
            }}
          />
        ) : (
          <MasterButton
            {...{
              onclickFun: handleOnVerifyOtpReq,
              title: "Verify OTP",
              loading,
            }}
          />
        )
      ) : (
        <MasterButton
          {...{
            onclickFun: handleOnSendOtpReq,
            title: "Send OTP",
            loading,
          }}
        />
      )}
    </>
  );
};

const OutputField = ({
  formData,
  handleOnChange,
  isOtpExp,
  isOtpSent,
  setIsOtpExp,
}) => {
  return (
    <div className="mb-3">
      <label htmlFor="otpInput" className="form-label">
        OTP expires in{" "}
        {isOtpSent && !isOtpExp && <Timer {...{ setIsOtpExp }} />}
      </label>
      <div className="password_control">
        <Input
          name="otp"
          value={formData.otp}
          onChange={handleOnChange}
          // onKeyUp={validatePassword}
          type="number"
          className="form-control"
          id="otpInput"
          placeholder="Enter your OTP"
        />
      </div>
      <span id="otp" />
    </div>
  );
};

const MasterButton = ({ title, loading, onclickFun }) => {
  return (
    <div className="text-center">
      <button
        disabled={loading}
        onClick={onclickFun}
        type="button"
        className="btn btn-white btn-hover w-100"
      >
        {!loading ? (
          title
        ) : (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </button>
    </div>
  );
};
