import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, Container, Row, Col, Table } from "reactstrap";
import { Sidebar } from "../layout/AdminLayout/Sidebar";
import { useJob } from "../../../hooks/useJob";
import { useLocation } from "react-router-dom";
import { baseURL } from "../../../repositories/Repository";
import { FaTimes, FaSearch } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import ReactSelect from "react-select";
import Pagination from "../../Jobs/JobList2/Pagination";
import { useAuth } from "../../../hooks/useAuth";
import '../assets/css/Dashboard.css'

const ApplicationViews = () => {
  const { getjobApplication, applyjob, patchJobStatus } = useJob();
  const [applyjobs, setApplyJobs] = useState([]);
  const route = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const jobsPerPage = 15;
  const location = useLocation();
  const jobIdFromUrl = new URLSearchParams(location.search).get("id");
  const [commentInputs, setCommentInputs] = useState({});
  const { saveNotification } = useAuth();
  const cardRef = useRef(null); // Reference to the card element
  const [showCancelButton, setShowCancelButton] = useState(false); // Define showCancelButton state

  useEffect(() => {
    getjobApplication(jobIdFromUrl);
  }, [jobIdFromUrl]);

  useEffect(() => {
    if (applyjob && applyjob.length > 0) {
      const filteredApplyJobs = applyjob.filter((application) => {
        const jobId = application?.jobId?._id;
        return !jobIdFromUrl || (jobId && jobId === jobIdFromUrl);
      }).filter(application => application?.candidateId);
      setApplyJobs(filteredApplyJobs);
      const commentInputsData = {};
      filteredApplyJobs.forEach(application => {
        commentInputsData[application._id] = application.comment || "";
      });
      setCommentInputs(commentInputsData);
    } else {
      setApplyJobs([]);
      setCommentInputs({});
    }
  }, [applyjob, jobIdFromUrl]);

  const filterOptions = [
    { value: "All", label: "All" },
    { value: "hour", label: "Last hour" },
    { value: "day", label: "Last day" },
    { value: "week", label: "Last week" },
    { value: "month", label: "Last month" },
    { value: "year", label: "Last year" }
  ];

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleCancel = () => {
    route("/myjobs");
  };

  const handleDownloadResume = async (resumePath, studentName) => {
    try {
      if (!resumePath || !studentName) {
        console.error("Resume path or student name is undefined or null.");
        return;
      }
      const resumeUrl = `${baseURL}/${resumePath.replace(/\\/g, "/")}`;
      const response = await fetch(resumeUrl);
      const blob = await response.blob();
      const fileName = `${studentName.replace(/\s+/g, '_')}_Resume.pdf`;
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = fileName;
      downloadLink.click();
      window.URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.error("Error during resume download:", error);
    }
  };

  const handleViewResume = (resumePath, studentName) => {
    try {
      if (!resumePath || !studentName) {
        console.error('Resume path or student name is undefined or null.');
        return;
      }
      const resumeUrl = `${baseURL}/${resumePath.replace(/\\/g, '/')}`;
      window.open(resumeUrl, '_blank');
    } catch (error) {
      console.error('Error during resume view:', error);
    }
  };

  const data = [
    { value: 'shortlisted', label: 'shortlisted' },
    { value: 'not-shortlisted', label: 'not-shortlisted' },
    { value: 'in review', label: 'in review' },
    { value: 'interview', label: 'interview' },
    { value: 'onboard', label: 'onboard' }
  ];

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  //create notification 
  const setNotification = async (jobId, candidateId, name, jobTitle, companyName, jobStatus) => {
    try {
      if (jobStatus === 'shortlisted' || jobStatus === 'in review') {
        const notificationData = {
          candidateId: candidateId,
          message: `Dear ${name} ,your application for the ${jobTitle}  role at ${companyName} has been ${jobStatus}.`,
          jobStatus: jobStatus,
          jobId: jobId,

        };
        // Assuming this function is marked as async
        await saveNotification(notificationData);
      }
      else if (jobStatus === 'interview' || jobStatus === 'onboard') {
        let message;
        switch (jobStatus) {
          case 'interview':
            message = `Dear ${name}, your application for the ${jobTitle} role at ${companyName} is scheduled for an interview.`;
            break;
          case 'onboard':
            message = `Dear ${name}, congratulations! Your application for the ${jobTitle} role at ${companyName} has been accepted, and you are onboarded.`;
            break;
        }
        const notificationData = {
          candidateId: candidateId,
          message: message,
          jobStatus: jobStatus,
          jobId: jobId,
        };
        await saveNotification(notificationData);
      }
      else {
        const notificationData = {
          candidateId: candidateId,
          message: `Dear ${name} , your application for the  ${jobTitle} at ${companyName} has not been selected. We appreciate your interest and wish you the best.`,
          jobStatus: jobStatus,
          jobId: jobId,
        };
        // Assuming this function is marked as async
        await saveNotification(notificationData);
      }
    } catch (error) {
      console.error('Error submitting job application:', error);
    }
  };

  const changeStatus = async (jobId, jobStatus) => {
    try {
      await patchJobStatus({ jobId, jobStatus });
    } catch (error) {
      console.error('Error updating job status:', error);
    }
  };

  const changecomment = async (jobId, updatedComment) => {
    try {
      await patchJobStatus({ jobId, comment: updatedComment });
    } catch (error) {
      console.error('Error updating comment:', error.message);
    }
  };


  const [commentSaved, setCommentSaved] = useState(
    JSON.parse(localStorage.getItem("commentSaved")) || {}
  );

  const handleSaveComment = async (jobId, updatedComment) => {
    try {
      if (!commentSaved[jobId]) {
        await changecomment(jobId, updatedComment);
        setCommentSaved(prevState => ({
          ...prevState,
          [jobId]: true
        }));
        localStorage.setItem("commentSaved", JSON.stringify({
          ...commentSaved,
          [jobId]: true
        }));
      }
    } catch (error) {
      console.error('Error saving comment:', error);
    }
  };

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const currentJobs = applyjobs.slice(indexOfFirstJob, indexOfLastJob);
  const filteredJobs = currentJobs?.filter(application =>
    `${application?.candidateId?.firstName} ${application?.candidateId?.lastName}`.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const calculateTimeDifference = (appliedAt) => {
    const currentTime = new Date();
    const applicationTime = new Date(appliedAt);
    const timeDifference = currentTime.getTime() - applicationTime.getTime();
    const hoursDifference = Math.floor(timeDifference / (1000 * 3600));
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    const weeksDifference = Math.floor(timeDifference / (1000 * 3600 * 24 * 7));
    const monthsDifference = currentTime.getMonth() - applicationTime.getMonth() +
      (12 * (currentTime.getFullYear() - applicationTime.getFullYear()));
    const yearsDifference = currentTime.getFullYear() - applicationTime.getFullYear();
    return { hoursDifference, daysDifference, weeksDifference, monthsDifference, yearsDifference };
  };

  const filterApplicationsByTime = (application) => {
    if (selectedFilter === "All") {
      return true;
    }
    const { hoursDifference, daysDifference, weeksDifference, monthsDifference, yearsDifference } =
      calculateTimeDifference(application.appliedAt);
    switch (selectedFilter) {
      case "hour":
        return hoursDifference <= 1;
      case "day":
        return daysDifference <= 1;
      case "week":
        return weeksDifference <= 1;
      case "month":
        return monthsDifference <= 1;
      case "year":
        return yearsDifference <= 1;
      default:
        return true;
    }
  };

  const filteredApplications = applyjobs.filter(application => {
    const fullName = `${application?.candidateId?.firstName} ${application?.candidateId?.lastName}`;
    return fullName.toLowerCase().includes(searchQuery.toLowerCase()) && filterApplicationsByTime(application);
  });

  useEffect(() => {
    const handleMouseEnter = () => {
      setShowCancelButton(true);
    };

    const handleMouseLeave = (event) => {
      const card = cardRef.current;
      if (card && !card.contains(event.relatedTarget)) {
        setShowCancelButton(false);
      }
    };

    document.addEventListener("mouseenter", handleMouseEnter);
    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mouseenter", handleMouseEnter);
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);


  return (
    <>
      <div className="mr-4">
        <Sidebar />
      </div>
      <Container className="mt-5 mb-3 my-auto">
        <Row className="justify-content-center">
          <Col xs={12} md={9}>
            <Card className="side-border">
              <CardBody className="side-border">
                <span
                  className="fa-times-icon"
                  onClick={handleCancel}
                  style={{
                    position: "absolute",
                    top: '-20px',
                    right: '-20px',
                    cursor: "pointer",
                    borderRadius: "50%",
                    backgroundColor: "#bebcbc",
                    padding: "6px",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                    zIndex: "9999",
                    transition: "background-color 0.3s"
                  }}
                  // Apply hover effect
                  onMouseEnter={(e) => e.target.style.backgroundColor = "#c4bfdd"}
                  onMouseLeave={(e) => e.target.style.backgroundColor = "#bebcbc"}
                >
                  <FaTimes size={24} />
                </span>

                <Row>
                  <Col lg={6}>
                    <h3 className="heading-small text-dark mb-4">
                      Application Views
                    </h3>
                  </Col>
                  <Col lg={3} className='ml-1'>
                    <div className="mb-3 input-group">
                      <input
                        type="text"
                        placeholder="Search by student name..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="form-control"
                        style={{ height: '40px', paddingRight: '40px' }}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text border-color-gray" id="basic-addon2"><FaSearch /></span>
                      </div>
                    </div>
                  </Col>

                  <Col lg={2} className='ml-1'>
                    <div className="selection-widget mb-3">
                      <ReactSelect
                        className="mr-2"
                        placeholder="All"
                        value={filterOptions.find((option) => option.value === selectedFilter)}
                        options={filterOptions}
                        onChange={(selectedOption) => {
                          setSelectedFilter(selectedOption?.value);
                        }}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            width: '100%',
                            height: '40px',
                            borderColor: 'gray',
                          }),
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                {filteredApplications.length === 0 && (
                  <div className="text-center mt-3"> <h5>No candidate data available.</h5></div>
                )}
                {filteredApplications?.length > 0 ? (
                  <Table
                    responsive
                    className="table custom-table table-bordered table-hover"
                  >
                    <thead className="thead-dark">
                      <tr className="bordered text-nowrap">
                        <th>Sr No</th>
                        <th>Student Name</th>
                        <th>Email</th>
                        <th>Applied Date</th>
                        <th>Status</th>
                        <th>Comment</th>
                        <th>Resume Download</th>
                      </tr>
                    </thead>
                    <tbody className="text-nowrap">
                      {filteredApplications?.map((application, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{`${capitalize(application?.candidateId?.firstName)} ${capitalize(application?.candidateId?.lastName)}`}</td>
                          <td>{application?.candidateId?.email}</td>
                          <td>{formatDate(application?.appliedAt)}</td>
                          <td>
                            <ReactSelect
                              defaultValue={{
                                label: application.jobStatus ? application.jobStatus : 'Select..',
                                value: application.jobStatus ? application.jobStatus : null
                              }} options={data}
                              className="choices selectForm__inner"
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  border: 0,
                                  boxShadow: 'none',
                                  borderRadius: '0',
                                  outline: 'none',
                                  width: '100%',
                                  minWidth: '150px',
                                }),
                                menu: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                option: (base) => ({
                                  ...base,
                                  whiteSpace: 'nowrap',
                                }),
                              }}
                              menuPosition="fixed"
                              onChange={(selectedOption) => {
                                changeStatus(application?._id, selectedOption?.value)
                                setNotification(application?.jobId?._id, application?.candidateId?._id, application?.candidateId?.firstName, application?.jobId?.jobTitle, application?.companyId?.basicInfo?.companyName, selectedOption?.value)
                              }}
                            />
                          </td>

                          <td>
                            {commentSaved[application._id] ? (
                              <div style={{ whiteSpace: 'pre-wrap', marginTop: '5px' }}>
                                {commentInputs[application._id] && commentInputs[application._id].split('\n').map((line, index) => (
                                  <div key={index}>{line}</div>
                                ))}
                              </div>
                            ) : (
                              <>
                                <input
                                  type="text"
                                  value={commentInputs[application._id] || ""}
                                  onChange={(e) =>
                                    setCommentInputs((prevState) => ({
                                      ...prevState,
                                      [application._id]: e.target.value,
                                    }))
                                  }
                                />
                                <button
                                  className="btn btn-primary btn-sm ml-2"
                                  onClick={() =>
                                    handleSaveComment(
                                      application._id,
                                      commentInputs[application._id]
                                    )
                                  }
                                  disabled={commentSaved[application._id]}
                                >
                                  Save
                                </button>
                              </>
                            )}
                          </td>


                          <td>
                            {application?.resume && (
                              <>
                                <button
                                  className="btn btn-success btn-sm mr-2"
                                  onClick={() => {
                                    // changeStatus(application?._id, "Application View")
                                    handleViewResume(application?.resume, `${application?.candidateId?.firstName}_${application?.candidateId?.lastName},`);
                                  }}
                                >
                                  <i className="fa fa-eye" /> View
                                </button>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => handleDownloadResume(application?.resume, `${application?.candidateId?.firstName}_${application?.candidateId?.lastName}`)}
                                >
                                  <i className="fa fa-download" /> Download
                                </button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </Table>
                ) : (
                  <tr>
                    {/* <td colSpan="4">No data available for the specified job</td> */}
                  </tr>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Pagination
          jobsPerPage={jobsPerPage}
          totalJobs={filteredApplications.length}
          currentPage={currentPage}
          paginate={paginate}
        />
      </Container>

    </>
  );
};

export default ApplicationViews;