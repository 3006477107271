import React from "react";
import Section from "./Section"
import GrievancesPage from "./GrievancesPage";

const Grievances = () => {
  document.title = "Grievances";
  return (
    <React.Fragment>
      <Section />
      <GrievancesPage />
    </React.Fragment>
  );
};

export default Grievances;
