import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import AboutImage from "../../../assets/images/about/img-01.jpg";

const About = () => {
  return (
    <React.Fragment>
      <section className="section overflow-hidden">
        <Container>
          <Row className="align-items-center g-0">
            <Col lg={6}>
              <div className="section-title me-lg-5">
                <h3 className="title">About Us</h3>
                {/* <h2 className="title mb-4">
                  Why <span className="text-warning fw-bold">35,000+</span>{" "}
                  People Trust On FresherZone?
                </h2> */}
                <p className="text-muted">
                  Welcome to  
                  <Link to="fresherszone.in" className="ml-1 mr-1">
                  fresherszone.in
                  </Link>
                   – Your Gateway to Exciting Opportunities!
                  <p>
                  At FreshersZone.in, we understand that stepping into the professional world as a fresher or an intern can be both thrilling and daunting.
                  That’s why we are dedicated to being your ultimate companion on this journey, providing a comprehensive job search portal tailored exclusively for freshers and interns across all sectors.
                  </p>
                    <p>

                  <span className="fw-bold fs-16"> Our Vision:</span> Our vision is to bridge the gap between aspiring young talents and the vast array of opportunities waiting to be explored.
                  We strive to empower fresh graduates and interns by connecting them with companies that value their enthusiasm, fresh perspectives, and untapped potential.
                    </p>
                </p>

                <Row mt={4} pt={2}>
                  <Col md={6}>
                    <ul className="list-unstyled about-list text-muted mb-0 mb-md-3">
                      <li> Register for Free</li>
                      <li> Explore Job Opportunities</li>
                      <li> Connect with Recruiters</li>
                      <li> Build Your Career</li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="list-unstyled about-list text-muted">
                      <li> New jobs Innovation</li>
                      <li> 5000+ Companies</li>
                      <li> Easy Profile Management</li>
                      <li> and more...</li>
                    </ul>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Link to="#" className="btn btn-primary btn-hover">
                    Learn More{" "}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-img mt-4 mt-lg-0">
                <img src={AboutImage} alt="" className="img-fluid rounded" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default About;
