import { createSlice } from '@reduxjs/toolkit'
import { verifyOtpReq } from './thunk'

const initialState = {
    loading: false,
    isAuthenticated: false
}

const authReducer = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state) => {
            state.isAuthenticated = true
        },
        logOut: (state) => {
            state.isAuthenticated = false
        }
    },
    extraReducers: {
        [verifyOtpReq.pending]: (state) => {
            state.loading = true
        },
        [verifyOtpReq.fulfilled]: (state, { payload }) => {
            state.isAuthenticated = true
            state.loading = false
        },
        [verifyOtpReq.rejected]: (state) => {
            state.loading = false
        },
    }
})

export const { login, logOut } = authReducer.actions
export default authReducer.reducer