import React, { useState } from "react";
import { Collapse } from "reactstrap";

const AccordianContentLeft = () => {
  const [isCollapseFirst, setIsCollapseFirst] = useState(true);
  const toggleFirst = () => setIsCollapseFirst(!isCollapseFirst);

  const [isCollapseSecond, setIsCollapseSecond] = useState(false);
  const toggleSecond = () => setIsCollapseSecond(!isCollapseSecond);

  const [isCollapseThird, setIsCollapseThird] = useState(false);
  const toggleThird = () => setIsCollapseThird(!isCollapseThird);

  return (
    <React.Fragment>
      <div className="accordion-item mt-4 border-0">
        <h2 className="accordion-header" id="buyingone">
          <button
            className="accordion-button"
            onClick={toggleFirst}
            type="button"
          >
             How do I register on FresherZone?
         
          </button>
        </h2>
        <Collapse isOpen={isCollapseFirst} id="buying-one">
          <div className="accordion-body">
          To register on Fresherzone,decide whether you're a job seeker (choose Candidate) or a recruiter (choose Company). 
          Use a valid email address for account creation, as we use it for communication. Enter the OTP sent to your email. 
         Complete the signup form by providing the necessary details after this step.
          </div>
        </Collapse>
       
      </div>

      <div className="accordion-item mt-4 border-0">
        <h2 className="accordion-header" id="buyingtwo">
          <button
            className="accordion-button"
            onClick={toggleSecond}
            type="button"
          >
            How FresherZone Work ?
          </button>
        </h2>
        <Collapse isOpen={isCollapseSecond} id="buying-two">
          <div className="accordion-body">
          To secure a job through Fresherzone, begin by applying to relevant job listings. When you submit an application on Fresherzone,
           your details are automatically forwarded to the recruiter. 
          The recruiter will review your resume and profile information provided on Fresherzone.
           If your qualifications align with their requirements, they may reach out to you to arrange an interview.
          </div>
        </Collapse>
      </div>

      <div className="accordion-item mt-4 border-0">
        <h2 className="accordion-header" id="buyingthree">
          <button
            className="accordion-button"
            onClick={toggleThird}
            type="button"
          >
            How do I find job opportunities on FresherZone?
          </button>
        </h2>
        <Collapse isOpen={isCollapseThird} id="buying-three">
          <div className="accordion-body">
          You have the option to find jobs on FresherZone by searching for specific job titles or company names and city name. 
          In case you're overwhelmed with numerous job listings, you can refine your search using filters. 
          These filters include location, posting date, job type, and categories.
          If your search doesn't yield any results and shows "No Jobs Found," 
          try expanding your search by adjusting filters and ensuring there are no spelling errors in your search terms.
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default AccordianContentLeft;
