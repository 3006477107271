import { useState } from "react"
import AuthRepository from "../repositories/AuthRepository"
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { logOut, login } from "../store/auth/reducer"
import { icon } from "@fortawesome/fontawesome-svg-core"
import { FaInfo, FaExclamation, FaCheck, FaTimes } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';

export const useAuth = () => {
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpReSent, setIsOtpReSent] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [loading, setLoading] = useState(false)
    const [candidatedata, setCandidatedata] = useState("");
    const [candidatenotification, setCandidatenotification] = useState();
    const route = useNavigate()
    const dispatch = useDispatch()

    const customToastStyle = {
        background: '#333',
        color: '#fff',
        borderRadius: '4px',
    };

    return {
        loading,
        isOtpSent,
        isOtpVerified,
        isOtpReSent,
        candidatedata,
        customToastStyle,
        candidatenotification,
        setIsOtpSent: (payload) => {
            setIsOtpSent(payload)
        },

        // REGISTRATION
        companyRegistration: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.postCompanyRegistrationInfo(payload)
            if (response) {
                if (response.status == 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    route("/signin")
                } else if (response.code === "ERR_NETWORK") {
                    toast.error(response.message, { style: customToastStyle })
                } else {
                    toast.warn(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }

        },
        studentRegistration: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.postStudentRegistrationInfo(payload)
            if (response) {
                if (response.status == 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    route("/signin")
                } else if (response.code === "ERR_NETWORK") {
                    toast.error(response.message, { style: customToastStyle })
                } else {
                    toast.warn(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },
        sendUserOtp: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.sendUserOtpReq(payload)
            if (response) {
                if (response.status === 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    setIsOtpSent(true)
                } else if (response.code === "ERR_NETWORK") {
                    toast.error(response.message, { style: customToastStyle })
                } else {
                    toast.warn(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },
        reSendUserOtp: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.sendUserOtpReq(payload)
            if (response) {
                if (response.status == 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    setIsOtpSent(true)
                    setIsOtpReSent(true)
                } else if (response.code === "ERR_NETWORK") {
                    toast.error(response.message, { style: customToastStyle })
                } else {
                    toast.warn(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },
        verifyUserOtp: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.verifyUserOtpReq(payload)
            if (response) {
                if (response.status == 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    setIsOtpVerified(true)
                } else if (response.code === "ERR_NETWORK") {
                    toast.error(response.message, { style: customToastStyle })
                } else {
                    toast.warn(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },


        // LOGIN
        sendUserLogInOtp: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.sendUserLogInOtpReq(payload)
            if (response) {
                if (response.status == 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    setIsOtpSent(true)
                } else if (response.code === "ERR_NETWORK") {
                    toast.error(response.message, { style: customToastStyle })
                } else {
                    toast(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },
        reSendUserLogInOtp: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.sendUserLogInOtpReq(payload)
            if (response) {
                if (response.status == 200) {
                    toast.success(response.data.message, { style: customToastStyle })
                    setIsOtpSent(true)
                    setIsOtpReSent(true)
                } else if (response.code === "ERR_NETWORK") {
                    toast.error(response.message, { style: customToastStyle })
                } else {
                    toast.warn(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },


        logIn: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.loginReq(payload)
            if (response) {
                if (response.status == 200) {
                    localStorage.setItem('token', response.data?.accessToken)
                    localStorage.setItem('role', response.data?.setRole)
                    const timeSpan = new Date().getTime()
                    localStorage.setItem('timeSpan', JSON.stringify({ timeSpan }))
                    dispatch(login())
                    toast.success(response.data?.message, { style: customToastStyle })
                    setIsOtpVerified(true)
                    route("/")
                } else if (response.code === "ERR_NETWORK") {
                    toast.error(response.message, { style: customToastStyle })
                } else {
                    toast.warn(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },
        candidateUser: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.getcandidateUsers(payload)
            if (response.status === 200) {
                setCandidatedata(response.data)
                setLoading(false)
            }
        },

        logout: () => {
            localStorage.removeItem("token")
            localStorage.removeItem("role")
            localStorage.removeItem("timeSpan")
            dispatch(logOut())
        },

        contactusCompany: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.getcontactus(payload)
            if (response) {
                if (response.status == 200) {
                    toast.success(response.data.message, { style: customToastStyle })

                } else if (response.code === "ERR_NETWORK") {
                    toast.error(response.message, { style: customToastStyle })
                } else {
                    toast.warn(response.data.message, { style: customToastStyle })
                }
                setLoading(false)
            }
        },

        //candidate notification 
        saveNotification: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.getstorenotification(payload)

            if (response) {

            } else {

            }
            setLoading(false)

        },
        //candidate notification 
        updatecandidateNotification: async (payload) => {
            setLoading(true)
            const response = await AuthRepository.getupdatenotification(payload)

            if (response) {

            } else {

            }
            setLoading(false)

        },
        reqcandidatenotification: async () => {
            setLoading(true)
            const response = await AuthRepository.getcandidatenotification()
            if (response) {
                setCandidatenotification(response?.data?.result)
            } else {

            }
            setLoading(false)

        },

    }
}
