import React from "react";
import Section from "./Section"
import TrustAndSafetyPage from "./TrustAndSafetyPage";

const TrustAndSafety = () => {
  document.title = "Trust & Safety";
  return (
    <React.Fragment>
       <Section />
         <TrustAndSafetyPage/>      
    </React.Fragment>
  );
};

export default TrustAndSafety;
