import React, { useEffect } from 'react';
import { Card, CardBody, Container, Table, Modal, ModalBody, Col, Row, Dropdown, Input, } from 'reactstrap';
import '../assets/css/Dashboard.css';
import { useJob } from '../../../hooks/useJob';
import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import Pagination from '../../Jobs/JobList2/Pagination';
import { Sidebar } from '../layout/AdminLayout/Sidebar';
import { addHours, addDays, addWeeks, addMonths, addYears, isWithinInterval } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Myjobs = () => {
  const { getjoblist, getdeletejob, getjobApplication, applyjob, companyjobList } = useJob();
  const [jobsToDisplay, setJobsToDisplay] = useState([]);
  const [modal, setModal] = useState(false);
  const [deletingJobId, setDeletingJobId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDateFilter, setSelectedDateFilter] = useState("all");

  const navigate = useNavigate();

  useEffect(() => {
    getjoblist();
    getjobApplication();
  }, []);

  const formatDate = (dateform) => {
    const date = new Date(dateform);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const handleEdit = (jobId) => {
    window.location.href = `/jobpost?id=${jobId}`;
  };

  const handleView = (jobId) => {
    const jobApplications = applyjob.filter(application => application.jobId?._id === jobId && application.candidateId);
    if (jobApplications.length > 0) {
      navigate(`/View?id=${jobId}`);
    } else {
      toast.warning('No candidates available for this job.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const openModal = (jobId) => {
    setDeletingJobId(jobId);
    setModal(true);
  };

  const handleDelete = async (jobId) => {
    try {
      const message = await getdeletejob(jobId);
      if (message !== null) {
        setModal(false);
        setJobsToDisplay(prevJobs => prevJobs.filter(job => job._id !== jobId));
      }
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  useEffect(() => {
    setJobsToDisplay(Array.isArray(companyjobList) ? companyjobList : []);
  }, [companyjobList,]);
  const reversedJobs = [...jobsToDisplay].reverse();
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const Jobs = reversedJobs
    .filter((job) => job.jobTitle.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((job) => {
      const currentTime = new Date();
      const postedTime = new Date(job.postedAt);

      switch (selectedDateFilter) {
        case "lastHour":
          const oneHourAgo = addHours(currentTime, -1);
          return isWithinInterval(postedTime, { start: oneHourAgo, end: currentTime });

        case "1day":
          const oneDayAgo = addDays(currentTime, -1);
          return isWithinInterval(postedTime, { start: oneDayAgo, end: currentTime });

        case "1week":
          const oneWeekAgo = addWeeks(currentTime, -1);
          return isWithinInterval(postedTime, { start: oneWeekAgo, end: currentTime });

        case "1month":
          const oneMonthAgo = addMonths(currentTime, -1);
          return isWithinInterval(postedTime, { start: oneMonthAgo, end: currentTime });

        case "1year":
          const oneYearAgo = addYears(currentTime, -1);
          return isWithinInterval(postedTime, { start: oneYearAgo, end: currentTime });

        default:
          return true;
      }
    })
  const currentJobs = Jobs.slice(indexOfFirstJob, indexOfLastJob);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const countJobApplications = (jobId) => {
    return applyjob
      ? applyjob?.filter((application) => application?.jobId?._id === jobId && application?.candidateId).length || 0
      : 0;
  };

  return (
    <>
      <div className='mr-4'>
        <Sidebar />
      </div>
      <Container className='mt-5 mb-3 my-auto'>
        <Card className="side-border">
          <CardBody className='side-border'>
            <Row>

              <Col lg={6}>
                <h3 className="heading-small text-dark mb-4">
                  Job List
                </h3>
              </Col>
              <Col lg={3} className='ml-auto'>
                <div className="input-group mb-3 ">
                  <Input
                    type="search"
                    placeholder="Search by job title"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control"
                    style={{ height: '40px' }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={2} className='ml-1'>
                <div className="selection-widget mb-3">
                  <select
                    name="choices-company-page"
                    id="choices-company-page"
                    aria-label="Default select example"
                    className="form-select"
                    data-trigger
                    onChange={(e) => setSelectedDateFilter(e.target.value)}
                    value={selectedDateFilter}
                    style={{ height: '40px' }}
                  >
                    <option value="all">All</option>
                    <option value="lastHour">Last 1 hour</option>
                    <option value="1day">Last 1 day</option>
                    <option value="1week">Last 1 week</option>
                    <option value="1month">Last 1 month</option>
                    <option value="1year">Last 1 year</option>
                  </select>
                </div>
              </Col>
            </Row>
            {currentJobs?.length > 0 ? (
              <Table responsive className='table custom-table table-bordered table-hover'>
                <thead className="thead-dark">
                  <tr className='bordered text-nowrap'>
                    <th>Sr No</th>
                    <th>Job Title</th>
                    <th>Job Category</th>
                    <th>Job Type</th>
                    <th>Skills</th>
                    <th>Salary Type</th>
                    <th>Salary</th>
                    <th>Gender</th>
                    <th>Qualification</th>
                    <th>Application Deadline Date</th>
                    <th>Job Locations</th>
                    <th>Application Count</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className='text-nowrap'>
                  {currentJobs?.map((data, index) => (

                    <tr key={data._id}>

                      <td>{(currentPage - 1) * jobsPerPage + index + 1}</td>
                      <td>{data.jobTitle.charAt(0).toUpperCase() + data.jobTitle.slice(1)}</td>
                      <td style={{ maxWidth: '250px', whiteSpace: 'pre-line' }}>{data.jobCategory.map(category => category.category).join(', ')}</td>
                      <td>{data.jobType}</td>
                      <td style={{ maxWidth: '200px', whiteSpace: 'pre-line' }}>{data.skills.map(skills => skills).join(', ')}</td>
                      <td>{data.salaryType}</td>
                      <td>{data.salary}</td>
                      <td>{data.gender}</td>
                      <td>{data.qualification}</td>
                      <td>{formatDate(data.applicationDeadlineDate)}
                      </td>
                      <td>{data.jobLocations.map(location => location.location).join(', ')}</td>
                      <td style={{ textAlign: 'center' }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{flexDirection: 'row', marginBottom: '-20px', fontSize: '20px' }}>
                              {countJobApplications(data._id)}
                            </div>                        
                        </div>
                      </td>
                      <td style={{ justifyContent: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '-20px' }}>
                          <FontAwesomeIcon
                              icon={faEye}
                              id='view'
                              className=' p-2 rounded-circle shadow'
                              style={{ cursor: 'pointer', marginLeft: '15px', backgroundColor: 'rgba(42, 219, 123, 0.3)', color: '#2adb7b' }}
                              onClick={() => handleView(data._id)}
                            />
                            <FontAwesomeIcon
                              icon={faEdit}
                              id='edit'
                              className='  p-2 rounded-circle shadow'
                              style={{ cursor: 'pointer', marginLeft: '15px', backgroundColor: 'rgba(20, 86, 80, 0.15)', color: '#145650' }}
                              onClick={() => handleEdit(data._id)}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '-20px' }}>
                            <FontAwesomeIcon
                              id='delete'
                              icon={faTrash}
                              className='p-2 rounded-circle shadow'
                              style={{ cursor: 'pointer', marginLeft: '15px', backgroundColor: 'rgba(247, 43, 80, 0.15)', color: '#f72b50' }}
                              onClick={() => openModal(data._id)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="text-center mt-4 pt-2">
                <h5>No Jobs Available</h5>
              </div>
            )}
            <Pagination
              jobsPerPage={jobsPerPage}
              totalJobs={Jobs.length}
              currentPage={currentPage}
              paginate={paginate}
            />
          </CardBody>
        </Card>
      </Container>

      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered>
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete Jobs ?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal(false);
                }}
              ></button>
            </div>
            <ModalBody>
              <div>
                <h6 className="text-danger">
                  <i className="uil uil-exclamation-triangle"></i> Warning: Are
                  you sure you want to delete job Post ?
                </h6>
                <p className="text-muted">
                  {" "}
                  Your jobs post will be permenently removed and you won't be
                  able to see them again.
                </p>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModal(false);
                  setDeletingJobId(null);
                }}
                className="btn btn-primary btn-sm"
              >
                Cancel
              </button>
              <button type="button"
                onClick={() => handleDelete(deletingJobId)}
                className="btn btn-danger btn-sm">
                Yes, delete
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Myjobs;
